import React, { Component } from 'react';

import Intl from 'src/components/Intl';

import ProductPage from 'src/components/ProductPage/ProductPage';

import tea from 'src/data/ProductPage/tea';

class Tea extends Component {
  render() {
    return (
      <Intl>
        {(t, { locale }) => {
          return <ProductPage text={t.tea} assets={tea} name="tea" />;
        }}
      </Intl>
    );
  }
}

export default Tea;
