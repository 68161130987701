import React from 'react';
import styled, { keyframes } from 'styled-components';
import Intl from 'src/components/Intl';
import media from 'src/styles/media';
import { vw, vwMobile, vwTablet } from 'src/styles/utils';

const Hero = () => {
  return (
    <Intl>
      {(t, { locale }) => {
        return (
          <Root>
            <img
              src={require('src/assets/images/home/logo.svg')}
              alt="Sunzen Art Gallery"
            />
            <Slogan>
              <h1>{t.home.hero.title}</h1>
              <p>{t.home.hero.subTitle}</p>
            </Slogan>
          </Root>
        );
      }}
    </Intl>
  );
};

const animatedDrawDown = keyframes`
  0% {
    max-height: 0;
    opacity: 0;
  }
  50% {
    max-height: 61px;
    opacity:1;
  }
  100%{
    max-height: 61px;
  }
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  min-height: 568px;
  position: relative;
  @media (min-width: ${media.xsup}) {
  }
  @media (min-width: ${media.mdup}) {
    min-height: 680px;
  }
  &::before {
    content: '';
    position: absolute;
    bottom: 23px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    width: 2px;
    min-width: 2px;
    height: 61px;
    background-color: white;
    animation: ${animatedDrawDown} 2.2s ease infinite;
    animation-direction: reverse;
    opacity: 0;
    @media (min-width: ${media.xsup}) {
      height: 61px;
      width: 2px;
    }
  }
  img {
    margin-bottom: ${vwMobile(112)};
    width: ${vwMobile(226)};
    @media (min-width: ${media.xsup}) {
      margin-bottom: 0;
      width: ${vwTablet(226)};
    }
    @media (min-width: ${media.xsup}) and (max-height: 420px) {
      position: absolute;
      top: 20vh;
    }
    @media (min-width: ${media.mdup}) {
      width: auto;
      position: relative;
    }
  }
`;

const Slogan = styled.div`
  font-family: ${({ theme }) => theme.fontPrimary};
  color: white;
  text-transform: uppercase;
  width: ${vwMobile(320)};
  position: absolute;
  bottom: 90px;
  @media (min-width: ${media.xsup}) {
    width: 42.8%;
    position: absolute;
    bottom: 90px;
  }
  @media (min-width: ${media.mdup}) {
    bottom: 90px;
  }
  h1 {
    font-size: ${vwMobile(30)};
    line-height: ${vwMobile(41)};
    letter-spacing: ${vwMobile(4.29)};
    margin-bottom: ${vwMobile(10)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(30)};
      line-height: ${vwTablet(41)};
      letter-spacing: ${vwTablet(4.29)};
      margin-bottom: ${vwTablet(10)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(28)};
      line-height: ${vw(41)};
      letter-spacing: ${vw(4)};
      margin-bottom: 0;
    }
  }
  p {
    font-size: ${vwMobile(16)};
    line-height: ${vwMobile(24)};
    letter-spacing: ${vwMobile(1)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(16)};
      line-height: ${vwTablet(24)};
      letter-spacing: ${vwTablet(1)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(16)};
      line-height: ${vw(28)};
      letter-spacing: ${vw(5)};
    }
  }
`;

export default Hero;
