import imgArt from 'src/assets/images/product-page/art.svg';

import imgCeramics from 'src/assets/images/product-page/ceramics.svg';

import imgFurniture from 'src/assets/images/product-page/furniture.svg';

import imgTea from 'src/assets/images/product-page/tea.svg';

export default {
  tea: imgTea,
  art: imgArt,
  ceramics: imgCeramics,
  furniture: imgFurniture,
};
