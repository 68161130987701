import imgTeaCharacter from 'src/assets/images/tea/tea-character.svg';
import imgPuer from 'src/assets/images/tea/puer.svg';

import imgTemp from 'src/assets/images/product-page/temp-image.png';

export default {
  overview: {
    image: imgTeaCharacter,
  },
  section1: {
    video:
      'https://player.vimeo.com/external/318855508.hd.mp4?s=a38f73c421a2b979c08d4cf1783e3a4135706734&profile_id=175?autoplay=1',
  },
  section2: {
    character: {
      image: imgPuer,
      height: 136,
      width: 268,
    },
    video:
      'https://player.vimeo.com/external/318866478.hd.mp4?s=a450820e22d6da008c57c14144d343140cd0937b&profile_id=174',
  },
  featured: [
    [
      {
        image: require('src/assets/images/tea/products/clay/Tea_Clay_Teapots_1.jpg'),
      },
      {
        image: require('src/assets/images/tea/products/clay/Tea_Clay_Teapots_2.jpg'),
      },
      {
        image: require('src/assets/images/tea/products/clay/Tea_Clay_Teapots_3.jpg'),
      },
      {
        image: require('src/assets/images/tea/products/clay/Tea_Clay_Teapots_4.jpg'),
      },
      {
        image: imgTemp,
      },
      {
        image: require('src/assets/images/tea/products/clay/Tea_Clay_Teapots_5.jpg'),
      },
    ],
    [
      {
        image: require('src/assets/images/tea/products/metal/Tea_Metal_Teapots_1.jpg'),
      },
      {
        image: require('src/assets/images/tea/products/metal/Tea_Metal_Teapots_3.jpg'),
      },
      {
        image: require('src/assets/images/tea/products/metal/Tea_Metal_Teapots_2.jpg'),
      },
      {
        image: require('src/assets/images/tea/products/metal/Tea_Metal_Teapots_4.jpg'),
      },
      {
        image: require('src/assets/images/tea/products/metal/Tea_Metal_Teapots_5.jpg'),
      },
    ],
    [
      {
        image: require('src/assets/images/tea/products/tea/Sunzen-Tea-Puer.jpg'),
      },
      {
        image: require('src/assets/images/tea/products/tea/Tongqing.jpg'),
      },
      {
        image: require('src/assets/images/tea/products/tea/Tea_Tea_1.jpg'),
      },
      {
        image: require('src/assets/images/tea/products/tea/Tea_Tea_2.jpg'),
      },
      {
        image: require('src/assets/images/tea/products/tea/Tea_Tea_3.jpg'),
      },
      {
        image: require('src/assets/images/tea/products/tea/Tea_Tea_4.jpg'),
      },
      {
        image: require('src/assets/images/tea/products/tea/Tea_Tea_5.jpg'),
      },
    ],
  ],
};
