import React from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';
import theme from 'src/styles/theme';
import Intl from 'src/components/Intl';
import { vw } from 'src/styles/utils';

import BackgroundBlock from 'components/videoPage/BackgroundBlock';
import TwoColumnInnerContainer from 'components/videoPage/TwoColumnInnerContainer';
import ContentBlock from 'components/videoPage/ContentBlock';
import ImageBlock from 'components/videoPage/ImageBlock';

const About = () => {
  return (
    <Intl>
      {(t, { locale }) => {
        return (
          <StyledBackgroundBlock
            backgroundColor="white"
            backgroundImage={require('src/assets/images/home/bg-white.png')}
          >
            <TwoColumnInnerContainer
              left={
                <ContentBlock
                  label={t.home.about.title}
                  sublabel={t.home.about.subTitle}
                  content={t.home.about.content}
                  // linkText={t.home.about.linkText}
                  // linkTo="/about"
                  color={theme.colorNavy}
                />
              }
              right={
                <ImageBlock
                  imageSrc={require('src/assets/images/home/about-img.jpg')}
                  imageLabel={t.home.about.imgLabel}
                />
              }
            />
          </StyledBackgroundBlock>
        );
      }}
    </Intl>
  );
};

export default About;

const StyledBackgroundBlock = styled(BackgroundBlock)`
  padding-bottom: 0;
  @media (min-width: ${media.mdup}) {
    padding-bottom: ${vw(75)};
  }
`;
