import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from 'src/styles/media';

import { vw, vwMobile, vwTablet } from 'src/styles/utils';

const EventCard = props => {
  const { day, month, topic, linkTo, linkText, noLink, time } = props;
  return (
    <Root
      onClick={noLink ? f => f : () => props.history.push(linkTo)}
      noLink={noLink}
    >
      <LeftColumn>
        <Date>
          {day}
          <span>{month}</span>
        </Date>
      </LeftColumn>
      <RightColumn>
        <Topic>{topic}</Topic>
        {time ? <p>{time}</p> : <p>{linkText}</p>}
      </RightColumn>
    </Root>
  );
};

EventCard.propTypes = {
  day: PropTypes.string,
  month: PropTypes.string,
  topic: PropTypes.string,
  linkTo: PropTypes.string,
  linkText: PropTypes.string,
  noLink: PropTypes.bool,
  time: PropTypes.string,
};

const Root = styled.div`
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${vwMobile(17)} 0;
  position: relative;
  cursor: ${props => (props.noLink ? 'auto' : 'pointer')};
  @media (min-width: ${media.xsup}) {
    padding: ${vwTablet(17)} 0;
  }
  @media (min-width: ${media.mdup}) {
    padding: ${vw(17)} 0;
  }
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 37%;
    transform: translate3d(0, -50%, 0);
    width: ${vwMobile(2)};
    height: 80%;
    border-left: dashed ${vwMobile(2)} ${({ theme }) => theme.colorRed};
    @media (min-width: ${media.xsup}) {
      width: ${vwTablet(2)};
      border-left: dashed ${vwTablet(2)} ${({ theme }) => theme.colorRed};
    }
    @media (min-width: ${media.mdup}) {
      width: ${vw(2)};
      border-left: dashed ${vw(2)} ${({ theme }) => theme.colorRed};
    }
  }
`;

const LeftColumn = styled.div`
  width: 37%;
`;

const RightColumn = styled.div`
  width: 57.3%;
  margin-left: 5.7%;
  p {
    font-family: ${({ theme }) => theme.fontSecondary};
    font-size: ${vwMobile(11)};
    font-weight: 500;
    letter-spacing: ${vwMobile(2)};
    color: ${({ theme }) => theme.colorBackgroundBlack};
    text-transform: uppercase;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(11)};
      letter-spacing: ${vwTablet(2)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(11)};
      letter-spacing: ${vw(2)};
    }
  }
`;

const Date = styled.h5`
  font-family: ${({ theme }) => theme.fontPrimary};
  font-size: ${vwMobile(30)};
  letter-spacing: ${vwMobile(2.37)};
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colorNavy};
  @media (min-width: ${media.xsup}) {
    font-size: ${vwTablet(30)};
    letter-spacing: ${vwTablet(2.37)};
  }
  @media (min-width: ${media.mdup}) {
    font-size: ${vw(30)};
    letter-spacing: ${vw(2.37)};
  }
  span {
    font-size: ${vwMobile(16)};
    letter-spacing: ${vwMobile(1.26)};
    display: block;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(16)};
      letter-spacing: ${vwTablet(1.26)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(16)};
      letter-spacing: ${vw(1.26)};
    }
  }
`;

const Topic = styled.h4`
  font-family: ${({ theme }) => theme.fontPrimary};
  font-size: ${vwMobile(18)};
  line-height: ${vwMobile(25)};
  letter-spacing: ${vwMobile(1.8)};
  color: ${({ theme }) => theme.colorNavy};
  margin-bottom: ${vwMobile(10)};
  @media (min-width: ${media.xsup}) {
    font-size: ${vwTablet(18)};
    line-height: ${vwTablet(25)};
    letter-spacing: ${vwTablet(1.8)};
    margin-bottom: ${vwTablet(10)};
  }
  @media (min-width: ${media.mdup}) {
    font-size: ${vw(18)};
    line-height: ${vw(25)};
    letter-spacing: ${vw(1.8)};
    margin-bottom: ${vw(10)};
  }
`;

export default withRouter(EventCard);
