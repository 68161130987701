import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import $ from 'jquery';

import Intl from 'src/components/Intl';
import media from 'src/styles/media';
import { vw, vwTablet, vwMobile } from 'src/styles/utils';

const menu = [
  '/tea',
  '/art',
  '/ceramics',
  '/furniture',
  '/collection',
  '/about',
  '/events',
  '/visit-us',
];

class Footer extends Component {
  state = {
    hasSent: false,
    hasError: false,
    alreadySubscribed: false,
  };

  handleForm = e => {
    e.preventDefault();
    let emailData = $('#form').serialize();
    // for some reason fetch wouldn't work with mailchimp so i just used ajax
    $.ajax({
      type: 'post',
      url:
        'https://gmail.us20.list-manage.com/subscribe/post-json?u=1366565ac4df9d67ce5be1a8f&amp;id=3b1b327357&c=?',
      data: emailData,
      cache: false,
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      encode: true,
      error: function(err) {
        this.setState({ hasError: true });
      }.bind(this),
      success: function(data) {
        if (data.result === 'error') {
          this.setState({ alreadySubscribed: true });
        } else {
          this.setState({
            hasSent: true,
            hasError: false,
            alreadySubscribed: false,
          });
        }
      }.bind(this),
    }).done(function(data) {
      // console.log('done!');
    });
  };

  render() {
    return (
      <Intl>
        {(t, { locale }) => {
          return (
            <Root>
              <Subscribe>
                <SignupText>
                  <h3>{t.footer.subscribe}</h3>
                  <p>{t.footer.signup}</p>
                </SignupText>

                {this.state.hasSent ? (
                  <Success>
                    <p>{t.footer.success}</p>
                  </Success>
                ) : (
                  <>
                    <SignupForm id="form" onSubmit={this.handleForm}>
                      <input
                        placeholder={t.footer.yourEmail}
                        type="email"
                        name="EMAIL"
                        required
                        id="email"
                      />
                      <input type="submit" value={t.footer.subscribe} />
                      {this.state.hasError && <Error>{t.footer.error}</Error>}
                      {this.state.alreadySubscribed && (
                        <Error>{t.footer.alreadySubscribed}</Error>
                      )}
                    </SignupForm>
                  </>
                )}
              </Subscribe>

              <Bottom>
                <a
                  href="https://www.google.com/maps/place/420+Howe+St,+Vancouver,+BC+V6C+2X1/@49.2856318,-123.1173743,17z/data=!3m1!4b1!4m5!3m4!1s0x548671827ca0eabd:0x62e53cd991544374!8m2!3d49.2856318!4d-123.1151803"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="address"
                >
                  {t.footer.address}
                </a>
                <p>{t.footer.hours}</p>
                <p>
                  <a
                    href="mailto:info@sunzen.ca"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    info@sunzen.ca
                  </a>{' '}
                  | <a href="tel:604-265-6677">604-265-6677</a>
                </p>

                <Navigation>
                  <div className="wrapper">
                    {t.footer.menu.map((menuLink, index) => (
                      <Link
                        to={locale === 'en' ? menu[index] : `/zh${menu[index]}`}
                        key={index}
                      >
                        {menuLink}
                      </Link>
                    ))}
                  </div>
                </Navigation>

                <Legal>
                  <Link to={locale === 'en' ? '/privacy' : '/zh/privacy'}>
                    {t.footer.privacy}
                  </Link>
                  <Link to={locale === 'en' ? '/privacy' : '/zh/privacy'}>
                    {t.footer.terms}
                  </Link>

                  <p>{t.footer.copyright}</p>
                </Legal>
              </Bottom>
            </Root>
          );
        }}
      </Intl>
    );
  }
}

export default Footer;

const Root = styled.div`
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.07);
  background: #ffffff;
`;

const Subscribe = styled.div`
  background: rgba(216, 216, 216, 0.35);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: ${vwMobile(36)} ${vwMobile(25)} ${vwMobile(26)};
  @media (min-width: ${media.xsup}) {
    padding: ${vwTablet(36)} ${vwTablet(25)} ${vwTablet(26)};
  }
  @media (min-width: ${media.mdup}) {
    flex-direction: row;
    justify-content: space-between;
    padding: ${vw(36)} ${vw(215)} ${vw(26)} ${vw(219)};
  }
`;

const Bottom = styled.div`
  padding: ${vwMobile(57)} ${vwMobile(25)} ${vwMobile(14)};
  @media (min-width: ${media.xsup}) {
    padding: ${vwTablet(57)} ${vwTablet(25)} ${vwTablet(14)};
  }
  @media (min-width: ${media.mdup}) {
    padding: ${vw(54)} ${vw(182)} ${vw(28)};
  }
  p {
    opacity: 0.7;
    font-size: ${vwMobile(17)};
    font-weight: normal;
    line-height: 1.41;
    text-align: center;
    font-family: ${({ theme }) => theme.fontPrimary};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(17)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(17)};
    }
  }
  a {
    color: ${({ theme }) => theme.colorBlack};
  }
  .address {
    font-family: ${({ theme }) => theme.fontPrimary};
    font-size: ${vwMobile(18)};
    opacity: 0.8;
    font-weight: normal;
    line-height: 1.39;
    letter-spacing: 1.8;
    text-align: center;
    margin: 0 auto ${vwMobile(10)};
    display: block;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(18)};
      margin: 0 auto ${vwTablet(10)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(18)};
      margin: 0 auto ${vw(10)};
    }
  }
`;

const Navigation = styled.div`
  width: 100%;
  margin-top: ${vwMobile(55)};
  padding-bottom: ${vwMobile(58)};
  border-bottom: 1px solid rgba(0, 0, 0, 0.24);
  margin-bottom: ${vwMobile(21)};
  @media (min-width: ${media.xsup}) {
    margin-top: ${vwTablet(55)};
    margin-bottom: ${vwTablet(21)};
    padding-bottom: ${vwTablet(58)};
  }
  @media (min-width: ${media.mdup}) {
    margin-top: ${vw(70)};
    margin-bottom: ${vw(21)};
    padding-bottom: ${vw(15)};
    padding-left: 0;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: ${vwMobile(104)};
    width: 70%;
    margin: 0 auto;
    @media (min-width: ${media.xsup}) {
      width: 30%;
      max-height: ${vwTablet(104)};
    }
    @media (min-width: ${media.mdup}) {
      width: auto;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  a {
    display: inline-block;
    opacity: 0.4;
    font-family: ${({ theme }) => theme.fontSecondary};
    font-size: ${vwMobile(12)};
    font-weight: 500;
    line-height: ${vwMobile(26)};
    letter-spacing: 1.7px;
    color: ${({ theme }) => theme.colorBlack};
    text-transform: uppercase;
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8) {
      padding-left: ${vwMobile(20)};
    }
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(12)};
      line-height: ${vwTablet(26)};
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8) {
        padding-left: ${vwTablet(40)};
      }
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(11)};
      line-height: 2.36;
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8) {
        padding-left: 0;
      }
      &:first-child {
        padding-left: ${vw(56)};
      }
      &:last-child {
        padding-right: ${vw(56)};
      }
    }
  }
`;

const Legal = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (min-width: ${media.mdup}) {
    flex-wrap: nowrap;
  }
  p {
    text-align: center;
    margin-left: auto;
    opacity: 0.39;
    font-family: ${({ theme }) => theme.fontSecondary};
    font-size: ${vwMobile(12)};
    font-weight: normal;
    line-height: 2.17;
    letter-spacing: 0.9px;
    width: 100%;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(12)};
    }
    @media (min-width: ${media.mdup}) {
      width: auto;
      text-align: right;
      font-size: ${vw(12)};
    }
  }
  a {
    display: inline-block;
    opacity: 0.39;
    font-family: ${({ theme }) => theme.fontSecondary};
    font-size: ${vwMobile(10)};
    font-weight: normal;
    line-height: 2.6;
    letter-spacing: 0.7px;
    text-transform: uppercase;
    text-align: center;
    width: 50%;
    margin-bottom: ${vwMobile(10)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(10)};
      margin-bottom: ${vwTablet(10)};
    }
    @media (min-width: ${media.mdup}) {
      text-align: left;
      width: auto;
      font-size: ${vw(10)};
      margin-bottom: 0;
      &:first-child {
        margin-right: ${vw(55)};
      }
    }
  }
`;

const SignupText = styled.div`
  margin-bottom: ${vwMobile(40)};
  width: 100%;
  @media (min-width: ${media.xsup}) {
    margin-bottom: ${vwTablet(40)};
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(320)};
    margin-right: ${vw(101)};
    margin-bottom: 0;
  }
  h3 {
    font-family: ${({ theme }) => theme.fontPrimary};
    font-size: ${vwMobile(18)};
    font-weight: normal;
    line-height: 1.39;
    letter-spacing: 1.8px;
    color: ${({ theme }) => theme.colorNavy};
    text-transform: uppercase;
    margin-bottom: ${vwMobile(40)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(18)};
      margin-bottom: ${vwTablet(40)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(18)};
      margin-bottom: 0;
    }
  }
  p {
    font-weight: normal;
    font-size: ${vwMobile(17)};
    line-height: 1.41;
    color: ${({ theme }) => theme.colorNavy};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(17)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(17)};
    }
  }
`;

const Success = styled.div`
  flex: 1;
  width: 100%;
  @media (min-width: ${media.mdup}) {
    width: auto;
    p {
      text-align: right;
    }
  }
`;

const Error = styled.p`
  position: absolute;
  top: 100%;
  color: red;
  font-size: ${vwMobile(11)};
  padding-top: ${vwMobile(5)};
  @media (min-width: ${media.xsup}) {
    font-size: ${vwTablet(11)};
    padding-top: ${vwTablet(5)};
  }
  @media (min-width: ${media.mdup}) {
    font-size: ${vw(11)};
    padding-top: ${vw(5)};
  }
`;

const SignupForm = styled.form`
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  display: flex;
  flex: 1;
  width: 100%;
  position: relative;
  @media (min-width: ${media.mdup}) {
    width: auto;
  }

  input[type='email'] {
    font-size: ${vwMobile(18)};
    line-height: 1.33;
    letter-spacing: 1.6px;
    background: none;
    border: none;
    padding-bottom: ${vwMobile(9)};
    flex-grow: 1;
    font-family: ${({ theme }) => theme.fontPrimary};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(18)};
      padding-bottom: ${vwTablet(9)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(18)};
      padding-bottom: ${vw(9)};
    }
  }
  input[type='email']::placeholder {
    font-family: ${({ theme }) => theme.fontPrimary};
    font-style: italic;
    line-height: 1.33;
    letter-spacing: 1.6px;
    color: ${({ theme }) => theme.colorBlack};
  }
  input[type='submit'] {
    opacity: 0.9;
    font-family: ${({ theme }) => theme.fontSecondary};
    font-size: ${vwMobile(11)};
    font-weight: 500;
    line-height: 2.36;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    background: none;
    border: none;
    border-bottom: ${({ theme }) => `2px solid ${theme.colorBlack}`};
    border-radius: 0;
    transform: translateY(${vw(1.5)});
    cursor: pointer;
    outline: none;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(11)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(11)};
    }
  }
`;
