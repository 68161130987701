import React from 'react';
import styled from 'styled-components';

import media from 'src/styles/media';
import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import Intl from 'src/components/Intl';

import imgScrollUp from 'src/assets/images/icons/scroll-gallery-up.svg';
import imgScrollDown from 'src/assets/images/icons/scroll-gallery-down.svg';

const ProductCard = ({
  cardOpen,
  activeIndex,
  activeItemIndex,
  handleItem,
  handleSelection,
  handleToggleCard,
  effect,
  products,
}) => {
  return (
    <Intl>
      {(t, { locale }) => {
        return (
          <Root cardOpen={cardOpen}>
            <ImageContainer>
              <ImageStrip activeIndex={activeItemIndex}>
                {products.length !== 0 &&
                  products[activeIndex].items.map((item, index) => (
                    <Image
                      background={item.image}
                      key={index}
                      count={products.length}
                    >
                      {item.image && <img src={item.image} alt="" />}
                    </Image>
                  ))}
              </ImageStrip>
            </ImageContainer>

            <SelectionBar cardOpen={cardOpen}>
              {products.length !== 0 &&
                products.map((item, index) => {
                  return (
                    <BarItem
                      key={index}
                      isActive={activeIndex === index}
                      onClick={() => handleSelection(index)}
                    >
                      {item.title}
                    </BarItem>
                  );
                })}
              <Line
                activeIndex={activeIndex}
                count={products && products.length}
              />
            </SelectionBar>

            {products.length !== 0 && (
              <TextCard
                cardOpen={cardOpen}
                key={activeItemIndex || activeIndex}
              >
                <h1 className={effect}>
                  {locale === 'zh' &&
                  products[activeIndex].items[activeItemIndex].zh_title
                    ? products[activeIndex].items[activeItemIndex].zh_title
                    : products[activeIndex].items[activeItemIndex].title}
                </h1>
                {/* SUBTITLE follows, removed because no product seems to have one. Left in just in case they do. */}
                {/* {products[activeIndex].items[activeItemIndex]
                  .subTitle && (
                  <h3 className={effect}>
                    {
                      products.items[activeIndex].items[activeItemIndex]
                        .subTitle
                    }
                  </h3>
                )} */}

                <p className={effect} id="description">
                  {locale === 'zh' &&
                  products[activeIndex].items[activeItemIndex].zh_description
                    ? products[activeIndex].items[activeItemIndex]
                        .zh_description
                    : products[activeIndex].items[activeItemIndex].description}
                </p>
                <span onClick={handleToggleCard}>
                  {cardOpen ? t.product.close : t.product.readMore}
                </span>
              </TextCard>
            )}

            <CardNavigation
              cardOpen={cardOpen}
              count={
                products[activeIndex] && products[activeIndex].items.length
              }
            >
              <ScrollUp
                src={imgScrollUp}
                alt="Scroll Up"
                onClick={() => handleItem(activeItemIndex - 1)}
              />
              {products[activeIndex] &&
                products[activeIndex].items.map((item, index) => {
                  return (
                    <Circle key={index} isActive={activeItemIndex === index} />
                  );
                })}
              <ScrollDown
                src={imgScrollDown}
                alt="Scroll Down"
                onClick={() => handleItem(activeItemIndex + 1)}
              />
            </CardNavigation>
          </Root>
        );
      }}
    </Intl>
  );
};
export default ProductCard;

const Root = styled.div`
  background-image: ${props => `url(${props.image})`};
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: ${props => (props.cardOpen ? 'column-reverse' : 'column')};
  justify-content: space-between;
  height: ${vwMobile(679)};
  position: relative;
  @media (min-width: ${media.xsup}) {
    height: ${vwTablet(649)};
  }
  @media (min-width: ${media.mdup}) {
    height: ${vw(649)};
    flex: 1;
  }
`;

const TextCard = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colorNavy};
  padding-left: ${vwMobile(25)};
  padding-right: ${vwMobile(25)};
  background: ${({ theme }) => theme.colorWhite};
  padding-top: ${vwMobile(35)};
  height: ${props => (props.cardOpen ? vwMobile(629) : vwMobile(285))};
  transition: all 0.8s;
  position: absolute;
  bottom: ${vwMobile(25)};
  left: 50%;
  transform: translateX(-50%);
  width: ${vwMobile(325)};
  overflow: hidden;
  opacity: ${props => (props.cardOpen ? '0.8' : '1')};
  &:before {
      content: '';
      display: ${props => (props.cardOpen ? 'none' : 'block')};
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
      background-size: cover;
      background-position: center;
      height: ${vwMobile(200)};
      width: 100%;
      bottom: 0;
      left: 0;
      z-index: 99;
      position: absolute;
      @media (min-width: ${media.xsup}) {
        height: ${vwTablet(240)};
      }
      @media (min-width: ${media.mdup}) {
        height: ${vw(240)};
      }
    }
  @media (min-width: ${media.xsup}) {
    bottom: ${vwTablet(25)};
    width: ${vwTablet(515)};
    padding-left: ${vwTablet(47)};
    padding-right: ${vwTablet(47)};
    padding-top: ${vwTablet(35)};
    height: ${props => (props.cardOpen ? vwTablet(604) : vwTablet(285))};
  }
  @media (min-width: ${media.mdup}) {
    bottom: ${vw(25)};
    width: ${vw(515)};
    padding-left: ${vw(47)};
    padding-right: ${vw(47)};
    padding-top: ${vw(35)};
    height: ${props => (props.cardOpen ? vw(604) : vw(285))};
  }
  h1 {
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.fontPrimary};
    font-size: ${vwMobile(23)};
    font-weight: normal;
    line-height: 1.58;
    letter-spacing: ${vwMobile(1.64)};
    margin-bottom: ${vwMobile(2)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(26)};
      letter-spacing: ${vwTablet(2)};
      margin-bottom: ${vwTablet(2)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(26)};
      letter-spacing: ${vw(2)};
      margin-bottom: ${vw(2)};
    }
  }
  h3 {
    font-family: ${({ theme }) => theme.fontSecondary};
    font-size: ${vwMobile(11)};
    font-weight: normal;
    letter-spacing: ${vwMobile(2)};
    text-transform: uppercase;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(11)};
      letter-spacing: ${vwTablet(2)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(11)};
      letter-spacing: ${vw(2)};
    }
  }

  p {
    font-size: ${vwMobile(17)};
    font-family: ${({ theme }) => theme.fontPrimary};
    line-height: 1.41;
    margin-top: ${vwMobile(30)};
    margin-bottom: ${vwMobile(25)};
    max-height: ${props => (props.cardOpen ? vwMobile(400) : vwMobile(96))};
    overflow-y: ${props => (props.cardOpen ? 'scroll' : 'hidden')};
    text-align: left;
    position: relative;
    pointer-events: ${props => (props.cardOpen ? 'all' : 'none')};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(17)};
      margin-top: ${vwTablet(30)};
      margin-bottom: ${vwTablet(25)};
      max-height: ${props => (props.cardOpen ? vwTablet(400) : vwTablet(96))};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(17)};
      margin-top: ${vw(30)};
      margin-bottom: ${vw(25)};
      max-height: ${props => (props.cardOpen ? vw(400) : vw(96))};
    }
    ::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
    /* &:before {
      content: '';
      display: ${props => (props.cardOpen ? 'none' : 'block')};
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
      background-size: cover;
      background-position: center;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 99;
      position: absolute;
    } */
  }

  span {
    font-family: ${({ theme }) => theme.fontSecondary};
    font-size: ${vwMobile(11)};
    font-weight: 500;
    letter-spacing: ${vwMobile(1.6)};
    text-transform: uppercase;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding-bottom: ${vwMobile(27)};
    cursor: pointer;
    /* position: relative; */
    z-index: 100;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(11)};
      letter-spacing: ${vwTablet(1.6)};
      padding-bottom: ${vwTablet(27)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(11)};
      letter-spacing: ${vw(1.6)};
      padding-bottom: ${vw(27)};
    }
  }
`;

const SelectionBar = styled.div`
  background: #fefefe;
  position: absolute;
  top: ${vwMobile(25)};
  left: 50%;
  transform: translateX(-50%);
  width: ${vwMobile(325)};
  opacity: ${props => (props.cardOpen ? 0 : 1)};
  pointer-events: ${props => (props.cardOpen ? 'none' : 'all')};
  transition: 0.3s ease;
  @media (min-width: ${media.xsup}) {
    top: ${vwTablet(25)};
    width: ${vwTablet(515)};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  @media (min-width: ${media.mdup}) {
    top: ${vw(25)};
    width: ${vw(515)};
  }
`;

const BarItem = styled.h3`
  font-family: ${({ theme }) => theme.fontSecondary};
  font-size: ${vwMobile(11)};
  font-weight: 500;
  letter-spacing: ${vwMobile(1.6)};
  color: ${props =>
    props.isActive ? props.theme.colorNavy : 'rgba(26, 26, 26, 0.5)'};
  border-bottom: ${props =>
    props.isActive
      ? '2px solid rgba(194, 32, 51, 1)'
      : '2px solid rgba(194, 32, 51, 0)'};
  text-transform: uppercase;
  padding-bottom: ${vwMobile(20)};
  padding-top: ${vwMobile(20)};
  flex: 1;
  text-align: center;
  cursor: pointer;
  transition: 0.3s ease;
  @media (min-width: ${media.xsup}) {
    border-bottom: 0;
    font-size: ${vwTablet(11)};
    letter-spacing: ${vwTablet(1.6)};
    padding-bottom: ${vwTablet(20)};
    padding-top: ${vwTablet(20)};
  }
  @media (min-width: ${media.mdup}) {
    font-size: ${vw(11)};
    letter-spacing: ${vw(1.6)};
    padding-bottom: ${vw(20)};
    padding-top: ${vw(20)};
  }
`;

const Line = styled.div`
  position: absolute;
  width: ${props => 100 / props.count}%;
  height: 2px;
  background: ${({ theme }) => theme.colorRed};
  bottom: 0;
  left: ${props => `${props.activeIndex * (100 / props.count)}%`};
  transition: all 0.3s;
  display: none;
  @media (min-width: ${media.xsup}) {
    display: block;
  }
`;

const CardNavigation = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  right: ${vwMobile(25)};
  top: ${props => (props.count === 2 ? vwMobile(200) : vwMobile(235))};
  opacity: ${props => (props.cardOpen ? 0 : 1)};
  pointer-events: ${props => (props.cardOpen ? 'none' : 'all')};
  transition: 0.3s ease;
  @media (min-width: ${media.xsup}) {
    opacity: 1;
    pointer-events: all;
    right: ${vwTablet(32)};
    top: ${vwTablet(255)};
  }
  @media (min-width: ${media.mdup}) {
    right: ${vw(32)};
    top: ${vw(255)};
  }
`;

const ScrollUp = styled.img`
  height: ${vwMobile(35)};
  margin-bottom: ${vwMobile(20)};
  cursor: pointer;
  @media (min-width: ${media.xsup}) {
    margin-bottom: 0;
    height: ${vwTablet(35)};
  }
  @media (min-width: ${media.mdup}) {
    height: ${vw(27)};
  }
`;

const ScrollDown = styled.img`
  height: ${vwMobile(35)};
  cursor: pointer;
  @media (min-width: ${media.xsup}) {
    height: ${vwTablet(35)};
  }
  @media (min-width: ${media.mdup}) {
    height: ${vw(27)};
  }
`;

const Circle = styled.div`
  border: 1px solid ${({ theme }) => theme.colorNavy};
  background: ${props => props.isActive && props.theme.colorNavy};
  height: 4px;
  width: 4px;
  margin-bottom: ${vw(8)};
  border-radius: 50px;
  display: none;
  &:first-of-type {
    margin-top: ${vw(23)};
  }
  &:last-of-type {
    margin-bottom: ${vw(23)};
  }
  @media (min-width: ${media.xsup}) {
    display: block;
    margin-bottom: ${vwTablet(8)};
    &:first-of-type {
      margin-top: ${vwTablet(23)};
    }
    &:last-of-type {
      margin-bottom: ${vwTablet(23)};
    }
  }
  @media (min-width: ${media.mdup}) {
    margin-bottom: ${vw(8)};
    &:first-of-type {
      margin-top: ${vw(23)};
    }
    &:last-of-type {
      margin-bottom: ${vw(23)};
    }
  }
`;

const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const Image = styled.div`
  /* background: ${props => `url(${props.background})`};
  background-size: cover;
  background-position: center; */
  background-color: #f7f1ed;
  height: 100%;
  width: 100%;
  position: relative;
  img {
    position: absolute;
    left: 50%;
    top: ${props => (props.count === 2 ? vwMobile(135) : vwMobile(190))};
    transform: translateX(-50%);
    max-width: ${vwMobile(325)};
    height: ${props => (props.count === 2 ? vwMobile(234) : vwMobile(179))};
    object-fit: contain;
    object-position: center;
    @media (min-width: ${media.xsup}) {
      top: ${vwTablet(78)};
      max-width: ${vwTablet(515)};
      max-height: ${vwTablet(261)};
    }
    @media (min-width: ${media.mdup}) {
      top: ${vw(78)};
      max-width: ${vw(515)};
      max-height: ${vw(261)};
    }
  }
`;

const ImageStrip = styled.div`
  position: absolute;
  top: ${props => `-${props.activeIndex * 100}%`};
  left: 0;
  transition: all 0.5s;
  height: 100%;
  width: 100%;
`;
