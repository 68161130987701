import React, { Component } from 'react';

import Intl from 'src/components/Intl';

import ProductPage from 'src/components/ProductPage/ProductPage';

import ceramics from 'src/data/ProductPage/ceramics';

class Ceramics extends Component {
  render() {
    return (
      <Intl>
        {(t, { locale }) => {
          return (
            <ProductPage text={t.ceramics} assets={ceramics} name="ceramics" />
          );
        }}
      </Intl>
    );
  }
}

export default Ceramics;
