import imgFurnitureCharacter from 'src/assets/images/furniture/furniture-character.svg';

export default {
  overview: {
    image: imgFurnitureCharacter,
    opacity: 1,
    height: 483,
    top: 98,
  },
  section1: {
    video:
      'https://player.vimeo.com/external/319957544.hd.mp4?s=0270ac8777715dc40b834d640094442ea84823c5&profile_id=175',
  },
  section2: {
    character: {
      image: '',
      height: 136,
      width: 268,
    },
    video:
      'https://player.vimeo.com/external/319957518.hd.mp4?s=d425ec887dff48ddf708ff2d6a6cff9873fc3957&profile_id=174',
  },
  featured: [
    [
      {
        image: require('src/assets/images/furniture/products/seating/Furniture_Seating_1.jpg'),
      },
      {
        image: require('src/assets/images/furniture/products/seating/Furniture_Seating_2.jpg'),
      },
    ],
    [
      {
        image: require('src/assets/images/furniture/products/shelves/Furniture_Shelves_1.jpg'),
      },
      {
        image: require('src/assets/images/furniture/products/shelves/Furniture_Shelves_2.jpg'),
      },
    ],
  ],
};
