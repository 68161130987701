import React from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';
import Intl from 'src/components/Intl';

import BackgroundBlock from 'components/videoPage/BackgroundBlock';
import TwoColumnInnerContainer from 'components/videoPage/TwoColumnInnerContainer';
import ContentBlock from 'components/videoPage/ContentBlock';
import CollectionCard from '../elements/CollectionCard';

import { vw, vwMobile, vwTablet } from 'src/styles/utils';

const TheCollection = props => {
  return (
    <Intl>
      {(t, { locale }) => {
        return (
          <Root
            backgroundColor="black"
            mobileBackgroundImage={require('src/assets/images/home/marble-bg-black-mobile.png')}
            backgroundImage={require('src/assets/images/home/bg-black.png')}
          >
            <TwoColumnInnerContainer
              left={
                <img
                  src={require('src/assets/images/home/the-collection.png')}
                  alt="bowl"
                  className="image"
                />
              }
              right={
                <ContentBlock
                  label={t.home.collection.title}
                  sublabel={t.home.collection.subTitle}
                  content={t.home.collection.content}
                  color="white"
                />
              }
            />
            <CollectionCardsWrapper>
              <CollectionCard
                label={t.home.collection.items.tea.title}
                sublabel={t.home.collection.items.tea.subTitle}
                imageSrc={require('src/assets/images/home/collection-teas.jpg')}
                mobileImg={require('src/assets/images/home/collection-tea-mobile.png')}
                linkTo={locale === 'en' ? '/tea' : '/zh/tea'}
                linkText={t.product.learnMore}
              />
              <CollectionCard
                label={t.home.collection.items.art.title}
                sublabel={t.home.collection.items.art.subTitle}
                imageSrc={require('src/assets/images/home/collection-art.jpg')}
                mobileImg={require('src/assets/images/home/collection-art-mobile.png')}
                linkTo={locale === 'en' ? '/art' : '/zh/art'}
                linkText={t.product.learnMore}
              />
              <CollectionCard
                label={t.home.collection.items.ceramics.title}
                sublabel={t.home.collection.items.ceramics.subTitle}
                imageSrc={require('src/assets/images/home/collection-ceramics.jpg')}
                mobileImg={require('src/assets/images/home/collection-ceramics-mobile.png')}
                linkTo={locale === 'en' ? '/ceramics' : '/zh/ceramics'}
                linkText={t.product.learnMore}
              />
              <CollectionCard
                label={t.home.collection.items.furniture.title}
                sublabel={t.home.collection.items.furniture.subTitle}
                imageSrc={require('src/assets/images/home/collection-furniture.jpg')}
                mobileImg={require('src/assets/images/home/collection-furniture-mobile.png')}
                linkTo={locale === 'en' ? '/furniture' : '/zh/furniture'}
                linkText={t.product.learnMore}
              />
            </CollectionCardsWrapper>
          </Root>
        );
      }}
    </Intl>
  );
};

const Root = styled(BackgroundBlock)`
  padding-bottom: ${vwMobile(52)};
  @media (min-width: ${media.xsup}) {
    padding-bottom: ${vwTablet(52)};
  }
  @media (min-width: ${media.mdup}) {
    padding-bottom: 0;
  }
  .image {
    display: none;
    @media (min-width: ${media.mdup}) {
      display: block;
    }
  }
`;

const CollectionCardsWrapper = styled.div`
  @media (min-width: ${media.mdup}) {
    display: flex;
    transform: ${`translate3d(0, ${vw(73)}, 0)`};
  }
`;

export default TheCollection;
