import React, { Component } from 'react';
import Intl from 'src/components/Intl';
import styled from 'styled-components';

import Hero from './sections/Hero';
import Container from 'components/Container';
import About from './sections/About';
import FullTextBlock from 'components/videoPage/FullTextBlock';
import TheCollection from './sections/TheCollection';
import Events from './sections/Events';

import { vw } from 'src/styles/utils';

class Home extends Component {
  render() {
    return (
      <Intl>
        {(t, { locale }) => {
          return (
            <Root>
              <Hero />
              <Container>
                <About />
                <FullTextBlock text={t.home.fullTextBlock1} />
                <TheCollection />
                <FullTextBlock text={t.home.fullTextBlock2} />
                <Events />
              </Container>
            </Root>
          );
        }}
      </Intl>
    );
  }
}

const Root = styled.main`
  padding-bottom: ${vw(328)};
`;

export default Home;
