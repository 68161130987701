import React from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';
import theme from 'src/styles/theme';

import Intl from 'src/components/Intl';
import ContentBlock from 'components/videoPage/ContentBlock';

import { vw, vwMobile } from 'src/styles/utils';

const Collection = () => {
  return (
    <Intl>
      {(t, { locale }) => {
        return (
          <Root backgroundColor="white">
            <Column>
              <Container>
                <ContentBlockWrapper>
                  <ContentBlock
                    label={t.about.collection.title}
                    sublabel={t.about.collection.subTitle}
                    content={t.about.collection.content}
                    linkText={t.about.collection.linkText}
                    linkTo="/visit-us"
                    color={theme.colorNavy}
                  />
                </ContentBlockWrapper>
              </Container>
            </Column>
            <Column>
              <img
                src={require('src/assets/images/about/collection.jpg')}
                alt="collection"
              />
            </Column>
          </Root>
        );
      }}
    </Intl>
  );
};

Collection.propTypes = {};

const Root = styled.div`
  background-color: white;
  @media (min-width: ${media.mdup}) {
    display: flex;
  }
`;

const Column = styled.div`
  @media (min-width: ${media.mdup}) {
    width: 50%;
  }
  img {
    width: 100%;
    height: 100%;
    margin-bottom: ${vwMobile(-5)};
    @media (min-width: ${media.mdup}) {
      margin-bottom: 0;
    }
  }
`;

const ContentBlockWrapper = styled.div`
  padding-top: ${vw(110)};
  padding-bottom: ${vw(75)};
`;

const Container = styled.div`
  color: ${({ theme }) => theme.colorNavy};
  text-align: left;
  @media (min-width: ${media.mdup}) {
    width: 57.14%;
    margin: 0 auto;
  }
`;

export default Collection;
