import { createGlobalStyle } from 'styled-components';
import theme from './theme';
import media from 'src/styles/media';
import { vw, vwTablet } from 'src/styles/utils';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
  }

  @keyframes fadeInUp {
    0% {
      opacity: 0 ;
      transform:translate3d(0, 3rem, 0);
    }

    100% {
      opacity: 1 ;
      transform: translate3d(0, 0, 0);
    }
  }
  
  .fadeInUp {
    opacity: 1 !important;
    
    animation-fill-mode:forwards;
    animation-duration: 1.4s !important;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  @keyframes fadeUp{
    0%{
      opacity:0;
      transform: translateY(${vw(20)});
    }
    100%{
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes fadeDown{
    0%{
      opacity:0;
      transform: translateY(${vw(-20)});
    }
    100%{
      opacity: 1;
      transform: translateY(0);
    }
  }


  .fadeUp{
    opacity: 1 !important;
    animation-name:fadeUp;
    animation-fill-mode:forwards;
    animation-duration: 0.5s !important;
    animation-timing-function:linear;
  }

  .fadeDown{
    opacity: 1 !important;
    animation-name: fadeDown;
    animation-fill-mode:forwards;
    animation-duration: 0.5s!important;
    animation-timing-function:linear;

  }

  @keyframes slideIn{
    0%{
      background-position: 0 300px;
    }
    100%{
      background-position: 0 0;
    }
  }

  .slideIn{
    animation-name: slideIn;
    animation-fill-mode:forwards;
    animation-duration: 2s!important;
    animation-timing-function:linear;
  }

  @keyframes widthExpansion{
    0%{
      width: 0;
    }
    100%{
      width: ${vw(462)};
    }
  }

  @keyframes widthExpansionTablet{
    0%{
      width: 0;
    }
    100%{
      width: ${vwTablet(400)};
    }
  }

  .widthExpansion{
    animation-name: widthExpansionTablet;
    animation-fill-mode:forwards;
    animation-duration: 0.2s!important;
    animation-timing-function: ease-in-out;
    @media (min-width: ${media.mdup}) {
      animation-name: widthExpansion;
    }
  }

  @keyframes fadeY{
    0%{
      opacity:0;
      transform:rotate(-90deg) translateX(${vw(150)}) translateY(${vw(-50)});
    }
    100%{
      opacity:1;
      transform:rotate(-90deg) translateX(${vw(120)}) translateY(${vw(-50)});

    }
  }

  .fadeY{
    opacity: 1 !important;
    animation-name: fadeY;
    animation-fill-mode:forwards;
    animation-duration: 0.65s!important;
    animation-timing-function: ease-in-out;
  }

  @keyframes slideY{
    0%{
      transform:translateY(-100%);

    }
    1%{

    }
    100%{
      transform: translateY(0);

    }
  }

  .slideY{
    animation-name: slideY;
    animation-fill-mode:forwards;
    animation-duration: 0.8s !important;
    animation-timing-function: ease-out;
    flex:1;
    z-index: 99;
    position:relative;

  }

  html,
  body,
  #app {
    width: 100%;
    height: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-stroke: 0;
  }

  body {
    font-family: 'Gotham';
  }

  a,
  button {
    outline: none;
    border: none;
    background: transparent;
    text-decoration: none;
    color: ${theme.colorPrimary};
  }

  main {
    text-align: center;
    /* position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; */
  }

  p {
    font-family:${({ theme }) => theme.fontPrimary};
  }

  @font-face {
    font-family: 'Gotham';
    src: url('/assets/fonts/Gotham-MediumItalic.woff2') format('woff2'),
        url('/assets/fonts/Gotham-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: 'Gotham';
    src: url('/assets/fonts/Gotham-Medium.woff2') format('woff2'),
        url('/assets/fonts/Gotham-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gotham';
    src: url('/assets/fonts/Gotham-BoldItalic.woff2') format('woff2'),
        url('/assets/fonts/Gotham-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
  }

  @font-face {
    font-family: 'Gotham';
    src: url('/assets/fonts/Gotham-Bold.woff2') format('woff2'),
        url('/assets/fonts/Gotham-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gotham';
    src: url('/assets/fonts/Gotham-LightItalic.woff2') format('woff2'),
        url('/assets/fonts/Gotham-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: 'Gotham Book';
    src: url('/assets/fonts/Gotham-Book.woff2') format('woff2'),
        url('/assets/fonts/Gotham-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gotham';
    src: url('/assets/fonts/Gotham-Light.woff2') format('woff2'),
        url('/assets/fonts/Gotham-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gotham Book';
    src: url('/assets/fonts/Gotham-BookItalic.woff2') format('woff2'),
        url('/assets/fonts/Gotham-BookItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
  }
`;
