import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from 'src/styles/media';

import { vw, vwMobile, vwTablet } from 'src/styles/utils';

const ImageBlock = ({ imageSrc, imageLabel }) => {
  return (
    <Root>
      <img src={imageSrc} alt={imageLabel} />
      <ImageLabel>{imageLabel}</ImageLabel>
    </Root>
  );
};

ImageBlock.propTypes = {
  imageSrc: PropTypes.string,
  imageLabel: PropTypes.string,
};

const Root = styled.div`
  height: ${vwMobile(375)};
  @media (min-width: ${media.xsup}) {
    height: ${vwTablet(475)};
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(436)};
    height: auto;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    @media (min-width: ${media.mdup}) {
      width: ${vw(436)};
      height: 100%;
      object-fit: cover;
    }
  }
`;

const ImageLabel = styled.p`
  font-family: ${({ theme }) => theme.fontPrimary};
  font-size: ${vw(17)};
  font-style: italic;
  line-height: ${vw(24)};
  letter-spacing: ${vw(0.5)};
  text-align: center;
  display: none;
  @media (min-width: ${media.mdup}) {
    display: block;
    margin-top: ${vw(15)};
  }
`;

export default ImageBlock;
