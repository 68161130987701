import React, { Component } from 'react';
import styled from 'styled-components';
import Intl from 'src/components/Intl';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getEvents } from 'src/redux/events';

import media from 'src/styles/media';
import { vw, vwTablet, vwMobile } from 'src/styles/utils';

import BackgroundBlock from 'components/videoPage/BackgroundBlock';
import TwoColumnInnerContainer from 'components/videoPage/TwoColumnInnerContainer';
import ContentBlock from 'components/videoPage/ContentBlock';
import EventImageCard from 'components/videoPage/EventImageCard';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

class Events extends Component {
  state = {
    featuredEvent: null,
  };

  componentDidMount() {
    if (!this.props.events.length) {
      this.props.getEvents();
    }
    if (!this.state.featuredEvent) {
      const featured = this.props.events.filter(item => item.acf.featured);
      this.setState({ featuredEvent: featured[0] });
    }
    
  }

  componentDidUpdate() {
    if (!this.state.featuredEvent && this.props.events.length !== 0) {
      const featured = this.props.events.filter(item => item.acf.featured);
      this.setState({ featuredEvent: featured[0] });
    }
  }

  render() {
    return (
      <Intl>
        {(t, { locale }) => {
          return (
            <BackgroundBlock
              backgroundColor="black"
              backgroundImage={require('src/assets/images/home/bg-black.png')}
            >
              <TwoColumnInnerContainer
                left={
                  <ContentBlock
                    label={t.home.events.title}
                    sublabel={t.home.events.subTitle}
                    content={t.home.events.content}
                    linkTo={locale === 'en' ? '/events' : '/zh/events'}
                    linkText={t.home.events.viewEvents}
                    color="white"
                  />
                }
                right={
                  <>
                    <Upcoming>{t.home.events.upcoming}:</Upcoming>
                    {this.state.featuredEvent && (
                      <EventImageCard
                        imageSrc={this.state.featuredEvent.image}
                        day={this.state.featuredEvent.acf.date.split('/')[0]}
                        month={
                          months[
                            this.state.featuredEvent.acf.date
                              .split('/')[1]
                              .replace('0', '') - 1
                          ]
                        }
                        topic={this.state.featuredEvent.post_title}
                        linkTo={
                          locale === 'en'
                            ? `/events/${this.state.featuredEvent.post_name}`
                            : `/zh/events/${this.state.featuredEvent.post_name}`
                        }
                        linkText={t.product.learnMore}
                      />
                    )}
                  </>
                }
              />
            </BackgroundBlock>
          );
        }}
      </Intl>
    );
  }
}

const mapStateToProps = state => ({
  events: state.events.posts,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getEvents,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Events);

const Upcoming = styled.p`
  font-family: ${({ theme }) => theme.fontSecondary};
  font-size: ${vwMobile(11)};
  letter-spacing: ${vwMobile(1)};
  font-weight: 500;
  text-transform: uppercase;
  color: white;
  margin-bottom: ${vwMobile(11)};
  margin-left: ${vwMobile(25)};
  @media (min-width: ${media.xsup}) {
    font-size: ${vwTablet(11)};
    letter-spacing: ${vwTablet(1)};
    margin-bottom: ${vwTablet(11)};
    margin-left: ${vwTablet(25)};
  }
  @media (min-width: ${media.mdup}) {
    font-size: ${vw(11)};
    letter-spacing: ${vw(2)};
    margin-bottom: ${vw(11)};
    margin-left: 0;
  }
`;
