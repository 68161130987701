import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Intl from 'src/components/Intl';
import media from 'src/styles/media';
import { vw, vwTablet, vwMobile } from 'src/styles/utils';

import ScrollAnimation from 'src/components/ScrollAnimation';

import imgHistory from 'src/assets/images/product-page/history.svg';
import imgOverlay from 'src/assets/images/product-page/video-max-width-overlay.svg';

const Section1 = ({ text, assets }) => {
  return (
    <Intl>
      {(t, { locale }) => {
        return (
          <Root>
            <Video>
              <Overlay overlay={imgOverlay} />
              <video playsInline muted autoPlay loop height="105%" width="105%">
                <source src={assets.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </Video>

            <Text name="section1">
              <Title history={imgHistory}>
                <ScrollAnimation>
                  <h1>{text.title}</h1>
                </ScrollAnimation>
              </Title>
              <Body>
                <ScrollAnimation delay={100}>
                  <p>{text.description1A}</p>
                </ScrollAnimation>

                <ScrollAnimation delay={300}>
                  <p>{text.description1B}</p>
                </ScrollAnimation>
              </Body>
            </Text>
          </Root>
        );
      }}
    </Intl>
  );
};

Section1.propTypes = {
  assets: PropTypes.object,
  text: PropTypes.object,
};

export default Section1;

const Root = styled.div`
  color: ${({ theme }) => theme.colorWhite};
  @media (min-width: ${media.xsup}) {
    margin-bottom: ${vw(238)};
  }
`;

const Title = styled.div`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: ${vwMobile(12)};
    left: ${vwMobile(-25)};
    width: ${vwMobile(10)};
    height: ${vwMobile(10)};
    background: ${({ theme }) => theme.colorRed};
    margin-left: ${vwMobile(20)};
    @media (min-width: ${media.xsup}) {
      top: ${vwTablet(12)};
      left: ${vwTablet(-25)};
      width: ${vwTablet(10)};
      height: ${vwTablet(10)};
      margin-left: 0;
    }
    @media (min-width: ${media.mdup}) {
      top: ${vw(12)};
      left: ${vw(-25)};
      width: ${vw(10)};
      height: ${vw(10)};
    }
  }

  &::after {
    content: '';
    background: ${props => `url(${props.history})`};
    background-size: contain;
    background-repeat: no-repeat;

    opacity: 1;
    height: ${vwMobile(150)};
    width: ${vwMobile(205)};
    position: absolute;
    left: 0;
    transform: translate(-50%, -50%);
    top: 0;
    @media (min-width: ${media.xsup}) {
      height: ${vwTablet(150)};
      width: ${vwTablet(205)};
    }
    @media (min-width: ${media.mdup}) {
      height: ${vw(150)};
      width: ${vw(205)};
    }
  }
  h1 {
    font-family: ${({ theme }) => theme.fontPrimary};
    font-size: ${vwMobile(23)};
    font-weight: normal;
    letter-spacing: ${vwMobile(1.64)};
    text-transform: uppercase;
    margin-bottom: ${vwMobile(30)};
    margin-left: ${vwMobile(20)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(28)};
      letter-spacing: ${vwTablet(5)};
      margin-bottom: ${vwTablet(30)};
      margin-left: 0;
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(28)};
      letter-spacing: ${vw(5)};
      margin-bottom: ${vw(30)};
    }
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${media.mdup}) {
    flex-direction: row;
  }
  p {
    font-size: ${vwMobile(17)};
    font-weight: normal;
    line-height: 1.41;
    &:first-of-type {
      margin-bottom: ${vwMobile(20)};
      margin-right: ${vw(67)};
    }
    @media (min-width: ${media.xsup}) {
      width: ${vwTablet(535)};
      font-size: ${vwTablet(17)};
      &:first-of-type {
        margin-bottom: ${vwTablet(20)};
        margin-right: ${vwTablet(67)};
      }
    }
    @media (min-width: ${media.mdup}) {
      width: ${vw(300)};
      font-size: ${vw(17)};
      &:first-of-type {
        margin-right: ${vw(67)};
      }
    }
  }
`;

const Text = styled.div`
  z-index: 99;
  position: relative;
  padding: 0 ${vwMobile(25)};
  @media (min-width: ${media.xsup}) {
    padding: 0;
    margin-left: ${vwTablet(120)};
  }
  @media (min-width: ${media.mdup}) {
    margin-left: ${vw(309)};
  }
`;

const Video = styled.div`
  position: relative;
  height: ${vw(792)};
  width: 100%;
  overflow: hidden;
  margin-bottom: ${vw(-100)};
`;

const Overlay = styled.div`
  background-image: ${props => `url(${props.overlay})`};
  background-size: cover;
  height: ${vw(794)};
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;
