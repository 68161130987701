import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Intl from 'src/components/Intl';
import media from 'src/styles/media';
import { vw, vwTablet, vwMobile } from 'src/styles/utils';

import menu from 'src/data/menu/menu';

const Collection = ({ handleMenu, menuOpen }) => {
  return (
    <Intl>
      {(t, { locale }) => {
        return (
          <Root menuOpen={menuOpen}>
            <Background />

            <h1>{t.menu.collection.title}</h1>
            <Products>
              {t.menu.collection.links.map((link, index) => (
                <Link
                  key={index}
                  to={
                    locale === 'en'
                      ? menu.collection[index].link
                      : `/zh${menu.collection[index].link}`
                  }
                  onClick={() => handleMenu(false)}
                >
                  <Container>
                    <Image src={menu.collection[index].image} />
                    <h3>{link}</h3>
                  </Container>
                </Link>
              ))}
            </Products>

            <Bottom menuOpen={menuOpen}>
              {t.menu.bottom.map((link, index) => (
                <Link
                  to={
                    locale === 'en'
                      ? menu.bottom[index]
                      : `/zh${menu.bottom[index]}`
                  }
                  key={index}
                  onClick={() => handleMenu(false)}
                >
                  {link}
                </Link>
              ))}
            </Bottom>
          </Root>
        );
      }}
    </Intl>
  );
};

export default Collection;

const Root = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 99;
  position: relative;
  flex: 1;
  overflow: hidden;
  height: 100%;
  background: ${({ theme }) => theme.colorWhite};

  opacity: ${props => (props.menuOpen ? 1 : 0)};
  transform: ${props =>
    props.menuOpen ? 'translate3d(0,0,0)' : 'translate3d(0, 4rem, 0)'};
  /* transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275); */
  transition: all 0.5s;
  transition-delay: ${props => (props.menuOpen ? '1.1s' : '0s')};
  @media (min-width: ${media.xsup}) and (max-height: 450px) {
    justify-content: flex-start;
  }
  h1 {
    margin-bottom: ${vwMobile(65)};
    @media (min-width: ${media.xsup}) {
      margin-bottom: ${vw(79)};
    }
    @media (min-width: ${media.xsup}) and (max-height: 450px) {
      margin-top: 35px;
    }
  }
  > div:nth-child(2) {
    height: ${vw(189)};
  }
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(216, 216, 216, 0.35);
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding-bottom: ${vwMobile(15)};
  @media (min-width: ${media.xsup}) {
    padding-bottom: ${vwTablet(35)};
  }
  @media (min-width: ${media.xsup}) and (max-height: 450px) {
    padding-bottom: 5px;
  }
  @media (min-width: ${media.mdup}) {
    padding-bottom: ${vw(15)};
  }
  a {
    opacity: 0.4;
    white-space: nowrap;
  }
  a:first-child {
    margin-right: 55px;
  }
`;

const Products = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-right: ${vwMobile(25)};
  padding-left: ${vwMobile(25)};
  justify-content: space-between;
  @media (min-width: ${media.xsup}) {
    padding-right: ${vw(295)};
    padding-left: ${vw(296)};
    flex-wrap: nowrap;
  }
  a {
    text-align: center;
    background-image: ${props => `url(${props.background})`};
    background-size: cover;
    width: 50%;
    height: ${vwMobile(70)};
    margin-bottom: ${vwMobile(25)};
    @media (min-width: ${media.xsup}) {
      width: auto;
      height: auto;
      margin-bottom: 0;
    }
  }
  h3 {
    opacity: 0.4;
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  @media (min-width: ${media.xsup}) {
    width: auto;
  }
`;

const Image = styled.img`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  height: ${vwMobile(70)};
  width: auto;
  opacity: 1;
  top: 50%;
  @media (min-width: ${media.xsup}) {
    height: ${vw(104)};
  }
`;
