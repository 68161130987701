import React from 'react';
import styled from 'styled-components';

import ScrollAnimation from 'components/ScrollAnimation';

import media from 'src/styles/media';
import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import Intl from 'src/components/Intl';

const LessonCard = ({
  text,
  activeIndex,
  toggleActiveStep,
  activeMobileIndex,
  toggleMobileActiveStep,
}) => {
  return (
    <Intl>
      {(t, { locale }) => {
        return (
          <Root>
            <Title>
              <h3>{text.subtitle}</h3>
              <h1>{text.title}</h1>
            </Title>

            <Content>
              <StepSelection>
                <h3>{t.product.select}</h3>

                <Steps>
                  <Container id="container">
                    <Line activeStep={activeIndex} />
                    {text.steps.map((step, index) => (
                      <StepItem
                        key={index}
                        isActive={activeIndex === index}
                        onClick={e => toggleActiveStep(e, index)}
                        id={index}
                      >
                        {step.title}{' '}
                      </StepItem>
                    ))}
                  </Container>
                </Steps>
              </StepSelection>

              <Description key={activeIndex}>
                <ScrollAnimation effect="fadeUp" delay={200}>
                  <h2>{text.steps[activeIndex].title}</h2>
                  <p>{text.steps[activeIndex].description}</p>
                </ScrollAnimation>

                <DividerContainer>
                  <Divider className="widthExpansion" />
                </DividerContainer>

                {text.steps[activeIndex].subsection && (
                  <ScrollAnimation effect="fadeDown" delay={200}>
                    <h3>{text.steps[activeIndex].subsection.title}</h3>
                    {text.steps[activeIndex].subsection.listItems.map(
                      (item, index) => (
                        <ListItem key={index}>{item}</ListItem>
                      )
                    )}
                  </ScrollAnimation>
                )}
              </Description>
            </Content>
            <MobileContent>
              <h3>{t.product.select}</h3>
              {text.steps.map((step, index) => (
                <MobileStep
                  key={index}
                  active={activeMobileIndex.includes(index)}
                >
                  <h4 onClick={() => toggleMobileActiveStep(index)}>
                    {step.title} <div />
                  </h4>
                  <p>{step.description}</p>
                </MobileStep>
              ))}
            </MobileContent>
          </Root>
        );
      }}
    </Intl>
  );
};

export default LessonCard;

const Root = styled.div`
  padding: ${vwMobile(40)} ${vwMobile(25)};
  position: relative;
  min-height: ${vw(800)};
  @media (min-width: ${media.xsup}) {
    padding: ${vwTablet(40)} ${vwTablet(25)};
  }
  @media (min-width: ${media.mdup}) {
    padding: ${vw(120)} ${vw(103)} ${vw(216.5)} ${vw(140)};
  }
`;

const StepSelection = styled.div`
  h3 {
    font-family: ${({ theme }) => theme.fontSecondary};
    font-size: ${vw(11)};
    font-weight: 500;
    letter-spacing: ${vw(2)};
    color: ${({ theme }) => theme.colorWhite};
    text-transform: uppercase;
    margin-bottom: ${vw(36)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(11)};
      letter-spacing: ${vwTablet(2)};
      margin-bottom: ${vwTablet(36)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(11)};
      letter-spacing: ${vw(2)};
      margin-bottom: ${vw(36)};
    }
  }
`;

const Steps = styled.div`
  padding: ${vw(16)} 0;
  position: relative;
  @media (min-width: ${media.xsup}) {
    padding: ${vwTablet(16)} 0;
  }
  @media (min-width: ${media.mdup}) {
    padding: ${vw(16)} 0;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: ${vw(45)};
    width: 100%;
    background-image: linear-gradient(to bottom, #0c0c0c, rgba(0, 0, 0, 0));
    pointer-events: none;
    @media (min-width: ${media.xsup}) {
      height: ${vwTablet(45)};
    }
    @media (min-width: ${media.mdup}) {
      height: ${vw(45)};
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: ${vw(45)};
    width: 100%;
    background-image: linear-gradient(to top, #0c0c0c, rgba(0, 0, 0, 0));
    pointer-events: none;
    @media (min-width: ${media.xsup}) {
      height: ${vwTablet(45)};
    }
    @media (min-width: ${media.mdup}) {
      height: ${vw(45)};
    }
  }
`;

const StepItem = styled.h1`
  opacity: ${props => (props.isActive ? '1' : '0.51')};
  font-family: ${({ theme }) => theme.fontPrimary};
  font-size: ${vw(14)};
  font-weight: normal;
  line-height: 1.79;
  letter-spacing: ${vw(1.4)};
  color: ${({ theme }) => theme.colorWhite};
  text-transform: uppercase;
  padding-left: ${props => (props.isActive ? vw(73) : vw(25))};
  padding-bottom: ${vw(8)};
  padding-top: ${vw(8)};
  width: 100%;
  transition: padding-left 0.8s;
  cursor: pointer;
  @media (min-width: ${media.xsup}) {
    font-size: ${vwTablet(14)};
    letter-spacing: ${vwTablet(1.4)};
    padding-left: ${props => (props.isActive ? vwTablet(50) : vwTablet(25))};
    padding-bottom: ${vwTablet(8)};
    padding-top: ${vwTablet(8)};
  }
  @media (min-width: ${media.mdup}) {
    font-size: ${vw(14)};
    letter-spacing: ${vw(1.4)};
    padding-left: ${props => (props.isActive ? vw(73) : vw(25))};
    padding-bottom: ${vw(8)};
    padding-top: ${vw(8)};
  }
`;

const Line = styled.div`
  height: ${vw(41)};
  width: ${vw(3)};
  opacity: 0.86;
  background-color: ${({ theme }) => theme.colorWhite};
  position: absolute;
  top: ${props => `${props.activeStep * ((41 / 1440) * 100)}vw`};
  left: 0;
  transition: all 0.5s;
  transform: translateY(50%);
  @media (min-width: ${media.xsup}) {
    height: ${vwTablet(41)};
    width: ${vwTablet(3)};
    top: ${props => `${props.activeStep * ((41 / 768) * 100)}vw`};
  }
  @media (min-width: ${media.mdup}) {
    height: ${vw(41)};
    width: ${vw(3)};
    top: ${props => `${props.activeStep * ((41 / 1440) * 100)}vw`};
  }
`;

const Title = styled.div`
  margin-bottom: ${vwMobile(40)};
  position: relative;
  @media (min-width: ${media.xsup}) {
    margin-bottom: ${vw(80)};
  }
  &::before {
    content: '';
    width: ${vwMobile(10)};
    height: ${vwMobile(10)};
    background-color: ${({ theme }) => theme.colorRed};
    position: absolute;
    left: ${vwMobile(-30)};
    top: ${vwMobile(12)};
    margin-left: ${vwMobile(20)};
    @media (min-width: ${media.xsup}) {
      width: ${vwTablet(10)};
      height: ${vwTablet(10)};
      left: ${vwTablet(-30)};
      top: ${vwTablet(12)};
      margin-left: ${vwTablet(20)};
    }
    @media (min-width: ${media.mdup}) {
      width: ${vw(10)};
      height: ${vw(10)};
      left: ${vw(-30)};
      top: ${vw(12)};
      margin-left: 0;
    }
  }
  h1 {
    font-family: ${({ theme }) => theme.fontPrimary};
    font-size: ${vwMobile(23)};
    font-weight: normal;
    line-height: 1.46;
    letter-spacing: ${vwMobile(1.64)};
    color: ${({ theme }) => theme.colorWhite};
    text-transform: uppercase;
    margin-bottom: ${vwMobile(80)};
    margin-left: ${vwMobile(20)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(28)};
      letter-spacing: ${vwTablet(4)};
      margin-bottom: ${vwTablet(80)};
      margin-left: ${vwTablet(20)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(28)};
      letter-spacing: ${vw(4)};
      margin-bottom: ${vw(80)};
      margin-left: 0;
    }
  }
  h3 {
    margin-bottom: 0px;
    font-family: ${({ theme }) => theme.fontSecondary};
    font-size: ${vwMobile(11)};
    font-weight: 500;
    letter-spacing: ${vwMobile(2)};
    color: ${({ theme }) => theme.colorWhite};
    text-transform: uppercase;
    margin-left: ${vwMobile(20)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(11)};
      letter-spacing: ${vwTablet(2)};
      margin-left: ${vwTablet(20)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(11)};
      letter-spacing: ${vw(2)};
      margin-left: 0;
    }
  }
`;

const Description = styled.div`
  color: ${({ theme }) => theme.colorWhite};
  width: ${vw(300)};
  @media (min-width: ${media.xsup}) {
    width: ${vwTablet(300)};
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(300)};
  }
  h2 {
    font-family: ${({ theme }) => theme.fontPrimary};
    font-size: ${vw(18)};
    font-weight: normal;
    line-height: 1.39;
    letter-spacing: ${vw(1.8)};
    text-transform: uppercase;
    margin-bottom: ${vw(16)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(18)};
      letter-spacing: ${vwTablet(1.8)};
      margin-bottom: ${vwTablet(16)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(18)};
      letter-spacing: ${vw(1.8)};
      margin-bottom: ${vw(16)};
    }
  }
  p {
    font-size: ${vw(17)};
    font-weight: normal;
    line-height: 1.41;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(17)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(17)};
    }
  }
  h3 {
    font-family: ${({ theme }) => theme.fontSecondary};
    font-size: ${vw(11)};
    font-weight: 500;
    letter-spacing: ${vw(2)};
    color: ${({ theme }) => theme.colorWhite};
    text-transform: uppercase;
    margin-bottom: ${vw(16)};
    margin-top: ${vw(33)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(11)};
      letter-spacing: ${vwTablet(2)};
      margin-bottom: ${vwTablet(16)};
      margin-top: ${vwTablet(33)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(11)};
      letter-spacing: ${vw(2)};
      margin-bottom: ${vw(16)};
      margin-top: ${vw(33)};
    }
  }
`;

const Content = styled.div`
  display: none;
  @media (min-width: ${media.xsup}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const ListItem = styled.p`
  font-size: ${vw(18)};
  font-style: italic;
  letter-spacing: ${vw(1.8)};
  margin-bottom: ${vw(16)};
  @media (min-width: ${media.xsup}) {
    font-size: ${vwTablet(18)};
    letter-spacing: ${vwTablet(1.8)};
    margin-bottom: ${vwTablet(16)};
  }
  @media (min-width: ${media.mdup}) {
    font-size: ${vw(18)};
    letter-spacing: ${vw(1.8)};
    margin-bottom: ${vw(16)};
  }
`;

const Divider = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.colorWhite};
  float: right;
  margin-right: ${vw(27)};
  @media (min-width: ${media.xsup}) {
    margin-right: ${vwTablet(27)};
  }
  @media (min-width: ${media.mdup}) {
    margin-right: ${vw(27)};
  }
`;

const DividerContainer = styled.div`
  margin-top: ${vw(12)};
  @media (min-width: ${media.xsup}) {
    margin-top: ${vwTablet(12)};
  }
  @media (min-width: ${media.mdup}) {
    margin-top: ${vw(12)};
  }
`;

const Container = styled.div`
  max-height: ${vw(248)};
  overflow-y: scroll;
  border-left: 1px solid rgba(255, 255, 255, 0.33);
  padding-top: ${vw(20)};
  padding-bottom: ${vw(20)};
  position: relative;
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  @media (min-width: ${media.xsup}) {
    max-height: ${vwTablet(248)};
    padding-top: ${vwTablet(20)};
    padding-bottom: ${vwTablet(20)};
  }
  @media (min-width: ${media.mdup}) {
    max-height: ${vw(248)};
    padding-top: ${vw(20)};
    padding-bottom: ${vw(20)};
  }
`;

const MobileContent = styled.div`
  color: white;
  @media (min-width: ${media.xsup}) {
    display: none;
  }
  h3 {
    font-family: ${({ theme }) => theme.fontSecondary};
    font-size: ${vwMobile(11)};
    font-weight: 500;
    letter-spacing: ${vwMobile(2)};
    color: ${({ theme }) => theme.colorWhite};
    text-transform: uppercase;
    margin-bottom: ${vwMobile(20)};
  }
  h4 {
    font-family: ${({ theme }) => theme.fontPrimary};
    font-size: ${vwMobile(16)};
    font-weight: normal;
    line-height: 1.79;
    letter-spacing: ${vwMobile(1.4)};
    text-transform: uppercase;
    padding: ${vwMobile(10)} 0;
  }
`;

const MobileStep = styled.div`
  overflow: hidden;
  p {
    max-height: ${props => (props.active ? '800px' : 0)};
    transition: 0.3s ease;
  }
  h4 {
    display: flex;
    align-items: center;
  }
  div {
    width: ${vwMobile(10)};
    height: ${vwMobile(10)};
    margin-left: ${vwMobile(10)};
    position: relative;
    &::before {
      content: '';
      width: ${vwMobile(10)};
      height: 1px;
      background-color: white;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      opacity: ${props => (props.active ? 0 : 1)};
      transition: 0.3s ease;
    }
    &::after {
      content: '';
      height: ${vwMobile(10)};
      width: 1px;
      background-color: white;
      position: absolute;
      left: 48%;
      top: 0;
      transform: ${props => (props.active ? 'rotate(90deg)' : 'rotate(0)')};
      transition: 0.3s ease;
    }
  }
`;
