import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-scroll';

import Intl from 'src/components/Intl';
import media from 'src/styles/media';
import { vw, vwTablet, vwMobile } from 'src/styles/utils';

import Line from 'src/components/Line';
import ScrollAnimation from 'src/components/ScrollAnimation';

const Overview = ({ text, assets }) => {
  return (
    <Intl>
      {(t, { locale }) => {
        return (
          <Root>
            <Image
              src={assets.image}
              opacity={assets.opacity}
              height={assets.height}
              top={assets.top}
            />
            <Text>
              <ScrollAnimation>
                <h1>{text.title}</h1>
                <Line />
              </ScrollAnimation>

              <ScrollAnimation delay={100}>
                <p>{text.description}</p>
              </ScrollAnimation>

              <ScrollAnimation delay={300}>
                <LearnMore to="section1" smooth offset={-100}>
                  {t.product.learnMore}
                </LearnMore>
              </ScrollAnimation>
            </Text>

            <Scroll>
              <div />
              {/* <ScrollAnimation effect="fadeY" delay={1000}> */}
              <p>Scroll</p>
              {/* </ScrollAnimation> */}
            </Scroll>
          </Root>
        );
      }}
    </Intl>
  );
};

Overview.propTypes = {
  assets: PropTypes.object,
  text: PropTypes.object,
};

export default Overview;

const Root = styled.div`
  color: ${({ theme }) => theme.colorWhite};
  display: flex;
  align-items: center;
  position: relative;
  padding: ${vwMobile(100)} ${vwMobile(25)} 0;
  @media (min-width: ${media.xsup}) {
    height: ${vwTablet(784)};
    padding-left: ${vwTablet(25)};
    padding-right: 0;
    padding-top: 0;
  }
  @media (min-width: ${media.mdup}) {
    height: ${vw(784)};
    padding-left: ${vw(210)};
  }
`;

const Image = styled.img`
  position: absolute;
  opacity: ${props => (props.opacity ? props.oapcity : 0.07)};
  right: 0;
  top: ${props => (props.top ? vw(props.top) : 0)};
  height: ${props => (props.height ? vw(props.height) : vw(798))};
`;

const Text = styled.div`
  h1 {
    font-family: ${({ theme }) => theme.fontPrimary};
    font-size: ${vwMobile(23)};
    font-weight: normal;
    line-height: 1.46;
    letter-spacing: ${vwMobile(1.64)};
    text-transform: uppercase;
    margin-bottom: ${vwMobile(7)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(28)};
      letter-spacing: ${vwTablet(2)};
      margin-bottom: ${vwTablet(7)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(28)};
      letter-spacing: ${vw(2)};
      margin-bottom: ${vw(7)};
    }
  }
  p {
    margin-top: ${vw(14)};
    margin-bottom: ${vwMobile(60)};
    font-family: ${({ theme }) => theme.fontPrimary};
    font-size: ${vwMobile(17)};
    font-weight: normal;
    line-height: 1.65;
    opacity: 0;
    @media (min-width: ${media.xsup}) {
      margin-top: ${vwTablet(14)};
      margin-bottom: ${vwTablet(60)};
      font-size: ${vwTablet(17)};
      width: ${vwTablet(535)};
    }
    @media (min-width: ${media.mdup}) {
      margin-top: ${vw(14)};
      margin-bottom: ${vw(60)};
      font-size: ${vw(17)};
      width: ${vw(535)};
    }
  }
`;

const LearnMore = styled(Link)`
  color: white;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fontSecondary};
  font-size: ${vwMobile(11)};
  font-weight: 500;
  letter-spacing: ${vwMobile(1.6)};
  display: block;
  cursor: pointer;
  @media (min-width: ${media.xsup}) {
    font-size: ${vwTablet(11)};
    letter-spacing: ${vwTablet(1.6)};
  }
  @media (min-width: ${media.mdup}) {
    font-size: ${vw(11)};
    letter-spacing: ${vw(1.6)};
  }
`;

const Scroll = styled.div`
  /* transform: rotate(-90deg) translateX(${vw(120)}) translateY(${vw(-35)}); */
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: ${vwMobile(580)};
  right: ${vwMobile(-60)};
  z-index:99;
  @media (min-width: ${media.xsup}) {
    top: ${vwTablet(784)};
    right: ${vwTablet(-40)};
  }
  @media (min-width: ${media.mdup}) {
    top: ${vw(784)};
    right: 0;
  }

  p {
    transform:rotate(-90deg) translateX(${vwMobile(120)}) translateY(${vwMobile(
  -50
)});
    font-family: ${({ theme }) => theme.fontSecondaryLight};
    font-size: ${vwMobile(11)};
    letter-spacing: ${vwMobile(4)};
    text-transform: uppercase;
    margin-left: ${vwMobile(5)};
    font-weight: normal;
    /* opacity: 0; */
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(11)};
      letter-spacing: ${vwTablet(4)};
      margin-left: ${vwTablet(5)};
      transform:rotate(-90deg) translateX(${vwTablet(
        120
      )}) translateY(${vwTablet(-50)});
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(11)};
      letter-spacing: ${vw(4)};
      margin-left: ${vw(5)};
      transform:rotate(-90deg) translateX(${vw(120)}) translateY(${vw(-50)});
    }
  }
  div {
    height: ${vwMobile(57)};
    width: ${vwMobile(2)};
    background: ${({ theme }) => theme.colorWhite};
    position: absolute;
    top: ${vwMobile(-70)};
    left: ${vwMobile(-10)};
    @media (min-width: ${media.xsup}) {
      height: ${vwTablet(57)};
      width: ${vwTablet(2)};
      top: ${vwTablet(-70)};
      left: ${vwTablet(-10)};
    }
    @media (min-width: ${media.mdup}) {
      height: ${vw(57)};
      width: ${vw(2)};
      top: ${vw(-70)};
      left: ${vw(-10)};
    }
  }
`;
