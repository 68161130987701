import React from 'react';
import styled from 'styled-components';

import { vw } from 'src/styles/utils';

const Square = () => {
  return <Root />;
};

export default Square;

const Root = styled.div`
  background: ${({ theme }) => theme.colorRed};
  width: ${vw(10)};
  height: ${vw(10)};
`;
