import React, { Component } from 'react';
import styled from 'styled-components';

import Intl from 'src/components/Intl';
import Hero from 'components/videoPage/Hero';
import Container from 'components/Container';
import Story from './sections/Story';
import FullTextBlock from 'components/videoPage/FullTextBlock';
import Community from './sections/Community';
import Collection from './sections/Collection';
import ExploreCollection from 'components/ExploreCollection/ExploreCollection';

class About extends Component {
  constructor(props) {
    super(props);
    this.$timeline = React.createRef();
  }

  state = {
    disabledLeft: true,
    disabledRight: false,
  };

  handleClick = direction => {
    // This jumps the timeline from the left to furtest right point on click of buttons
    direction === 'left'
      ? (this.$timeline.current.scrollLeft = 0)
      : (this.$timeline.current.scrollLeft = this.$timeline.current.scrollWidth);

    // Keeping the code below because if they ever add more points this allows for the timeline to scroll in smaller increments
    // direction === 'left'
    //   ? (this.$timeline.current.scrollLeft -= 50)
    //   : (this.$timeline.current.scrollLeft += 50);

    if (this.$timeline.current.scrollLeft === 0) {
      this.setState({ disabledLeft: true });
    } else if (
      this.$timeline.current.scrollLeft + this.$timeline.current.offsetWidth >=
      this.$timeline.current.scrollWidth
    ) {
      this.setState({ disabledRight: true });
    } else this.setState({ disabledLeft: false, disabledRight: false });
  };

  handleScrolling = () => {
    if (this.$timeline.current.scrollLeft === 0) {
      this.setState({ disabledLeft: true, disabledRight: false });
    } else if (
      this.$timeline.current.scrollLeft + this.$timeline.current.offsetWidth >=
      this.$timeline.current.scrollWidth
    ) {
      this.setState({ disabledRight: true, disabledLeft: false });
    } else this.setState({ disabledLeft: false, disabledRight: false });
  };
  render() {
    return (
      <Intl>
        {(t, { locale }) => {
          return (
            <Root>
              <Hero
                title={t.about.hero.title}
                content={t.about.hero.subTitle}
              />
              <Container>
                <Story
                  timelineRef={this.$timeline}
                  handleClick={this.handleClick}
                  disabledLeft={this.state.disabledLeft}
                  disabledRight={this.state.disabledRight}
                  handleScrolling={this.handleScrolling}
                />
                <FullTextBlock text={t.about.fullTextBlock1} />
                <Community
                  title={t.about.community.title}
                  subTitle={t.about.community.subTitle}
                  content={t.about.community.content}
                />
                <FullTextBlock text={t.about.fullTextBlock2} />
                <Collection />
                <ExploreCollection />
              </Container>
            </Root>
          );
        }}
      </Intl>
    );
  }
}

const Root = styled.main`
  max-width: 100vw;
`;

export default About;
