import React, { Component } from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';
import { vw, vwMobile, vwTablet } from 'src/styles/utils';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getEvents } from 'src/redux/events';

import Intl from 'src/components/Intl';
import Container from 'components/Container';
import Hero from 'components/videoPage/Hero';
import ExploreCollection from 'components/ExploreCollection/ExploreCollection';
import BackgroundBlock from 'components/videoPage/BackgroundBlock';
import EventImageCard from 'components/videoPage/EventImageCard';
import ScrollAnimation from 'components/ScrollAnimation';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

class Events extends Component {
  componentDidMount() {
    if (!this.props.events.length) {
      this.props.getEvents();
    }
  }

  render() {
    const { events } = this.props;
    return (
      <Intl>
        {(t, { locale }) => {
          return (
            <Root>
              <Hero
                title={t.events.hero.title}
                content={t.events.hero.content}
              />
              <Container>
                <StyledBackgroundBlock
                  color="black"
                  backgroundImage={require('src/assets/images/events/marble-bg.png')}
                >
                  <h1>{t.events.content.upcoming}</h1>
                  {/* <h2>{t.events.content.description}</h2> */}
                  <Wrapper>
                    {events.map((item, index) => (
                      <ScrollAnimation key={index}>
                        <Card key={index}>
                          <EventImageCard
                            imageSrc={item.image}
                            day={item.acf.date.split('/')[0]}
                            month={
                              months[
                                item.acf.date.split('/')[1].replace('0', '') - 1
                              ]
                            }
                            topic={
                              locale === 'zh' && item.acf.zh_title
                                ? item.acf.zh_title
                                : item.post_title
                            }
                            linkTo={
                              locale === 'zh'
                                ? `/zh/events/${item.post_name}`
                                : `/events/${item.post_name}`
                            }
                            linkText={t.product.learnMore}
                          />
                        </Card>
                      </ScrollAnimation>
                    ))}
                  </Wrapper>
                </StyledBackgroundBlock>
                <ExploreCollection />
              </Container>
            </Root>
          );
        }}
      </Intl>
    );
  }
}

const mapStateToProps = state => ({
  events: state.events.posts,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getEvents,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Events);

const Root = styled.div``;

const StyledBackgroundBlock = styled(BackgroundBlock)`
  color: white;
  @media (min-width: ${media.mdup}) {
    padding: ${vw(100)} 0 ${vw(120)};
  }
  h1 {
    font-family: ${({ theme }) => theme.fontPrimary};
    font-size: ${vwMobile(23)};
    letter-spacing: ${vwMobile(1.64)};
    text-transform: uppercase;
    margin-bottom: ${vwMobile(10)};
    margin-left: ${vwMobile(25)};
    position: relative;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(23)};
      letter-spacing: ${vwTablet(1.64)};
      margin-bottom: ${vwTablet(20)};
      margin-left: ${vwTablet(25)};
    }
    @media (min-width: ${media.mdup}) {
      margin-left: ${vw(80)};
      font-size: ${vw(28)};
      letter-spacing: ${vw(4)};
      margin-bottom: ${vw(20)};
    }
    &::before {
      content: '';
      position: absolute;
      transform: translate3d(0, -50%, 0);
      top: 50%;
      left: ${vwMobile(-15)};
      width: ${vwMobile(7)};
      height: ${vwMobile(7)};
      background-color: ${({ theme }) => theme.colorRed};
      @media (min-width: ${media.xsup}) {
        width: ${vw(10)};
        height: ${vw(10)};
        left: ${vw(-20)};
      }
    }
  }
  h2 {
    font-family: ${({ theme }) => theme.fontSecondaryLight};
    font-size: ${vwMobile(16)};
    line-height: ${vwMobile(25)};
    letter-spacing: ${vwMobile(2)};
    text-transform: uppercase;
    margin-bottom: ${vwMobile(60)};
    margin-left: ${vwMobile(25)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(16)};
      line-height: ${vwTablet(25)};
      letter-spacing: ${vwTablet(2)};
      margin-left: ${vwTablet(25)};
      margin-bottom: ${vwTablet(60)};
    }
    @media (min-width: ${media.mdup}) {
      margin-left: ${vw(80)};
      font-size: ${vw(16)};
      line-height: ${vw(25)};
      letter-spacing: ${vw(2)};
      margin-bottom: ${vw(60)};
    }
  }
`;

const Wrapper = styled.div`
  @media (min-width: ${media.mdup}) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 ${vw(80)};
  }
`;

const Card = styled.div`
  margin-bottom: ${vwMobile(20)};
  @media (min-width: ${media.mdup}) {
    width: ${vw(386)};
    margin-bottom: ${vw(60)};
  }
`;
