import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import media from 'src/styles/media';

const TwoColumnInnerContainer = ({ left, right }) => {
  return (
    <Root>
      <Column>
        <Container>{left}</Container>
      </Column>
      <Column>
        <Container>{right}</Container>
      </Column>
    </Root>
  );
};

TwoColumnInnerContainer.propTypes = {
  left: PropTypes.node,
  right: PropTypes.node,
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${media.mdup}) {
    flex-direction: row;
  }
  img {
    width: 100%;
    height: auto;
  }
`;

const Column = styled.div`
  @media (min-width: ${media.mdup}) {
    width: 50%;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  color: ${({ theme }) => theme.colorNavy};
  text-align: left;
  @media (min-width: ${media.mdup}) {
    width: 57.14%;
  }
`;

export default TwoColumnInnerContainer;
