module.exports = {
  defaultLocale: 'en',
  locales: ['en', 'zh'],
  prerender: true,
  stage: {
    mobile: 320,
    tablet: 768,
    desktop: 1440,
  },
  mediaQuery: {
    tablet: 576,
    desktop: 992,
  },
  wordpressAPIUrl: 'sunzen.ca/@cms/wp-json/my_endpoint/v1',
  wordpressProductAPIUrl: 'sunzen.ca/@cms/wp-json/my_endpoint_products/v1',
  wordpressBasicAPI: 'sunzen.ca/@cms/wp-json/wp/v2',
};
