import React from 'react';
import styled from 'styled-components';
import Intl from 'src/components/Intl';

import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

const Privacy = () => {
  return (
    <Intl>
      {(t, { locale }) => {
        return (
          <Root>
            <h1>{t.privacy.title}</h1>
            <p>{t.privacy.description}</p>
            {t.privacy.sections.map((section, index) => (
              <Section key={index}>
                <h2>{section.title}</h2>
                <p>{section.description}</p>
                {section.list && (
                  <ul>
                    {section.list.map((item, listIndex) => (
                      <li key={listIndex}>{item}</li>
                    ))}
                  </ul>
                )}
                {section.description2 && <p>{section.description2}</p>}
              </Section>
            ))}
            <h3>{t.privacy.personalData.title}</h3>
            {t.privacy.personalData.content.map((item, index) => (
              <p key={index}>{item}</p>
            ))}
            <h3>{t.privacy.personalData.addressTitle}</h3>
            <p className="address">420 Howe St., Vancouver BC</p>
            <a href="tel:604-265-6677">604-265-6677</a>
            <a href="mailto:info@sunzen.ca">info@sunzen.ca</a>
          </Root>
        );
      }}
    </Intl>
  );
};

export default Privacy;

const Root = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  padding: ${vwMobile(100)} ${vwMobile(25)};
  color: white;
  @media (min-width: ${media.xsup}) {
    padding: ${vw(220)} ${vw(300)};
  }
  h1 {
    text-transform: uppercase;
    font-size: ${vwMobile(23)};
    font-family: ${({ theme }) => theme.fontPrimary};
    letter-spacing: ${vwMobile(1.64)};
    margin-bottom: ${vwMobile(13)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(28)};
      letter-spacing: ${vwTablet(4)};
      margin-bottom: ${vwTablet(13)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(28)};
      letter-spacing: ${vw(4)};
      margin-bottom: ${vw(13)};
    }
  }
  h2,
  h3 {
    font-family: ${({ theme }) => theme.fontSecondary};
    font-size: ${vwMobile(14)};
    font-weight: normal;
    line-height: 1.56;
    letter-spacing: ${vwMobile(2)};
    text-transform: uppercase;
    margin-bottom: ${vwMobile(30)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(16)};
      letter-spacing: ${vwTablet(2)};
      margin-bottom: ${vwTablet(20)};
      margin-top: ${vwTablet(10)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(16)};
      letter-spacing: ${vw(2)};
      margin-bottom: ${vw(20)};
      margin-top: ${vw(10)};
    }
  }
  p,
  li {
    margin-top: ${vwMobile(10)};
    margin-bottom: ${vwMobile(30)};
    font-size: ${vwMobile(17)};
    font-family: ${({ theme }) => theme.fontPrimary};
    font-weight: normal;
    line-height: 1.41;
    @media (min-width: ${media.xsup}) {
      margin-top: 0;
      margin-bottom: ${vwTablet(20)};
      font-size: ${vwTablet(17)};
    }
    @media (min-width: ${media.mdup}) {
      margin-bottom: ${vw(20)};
      font-size: ${vw(17)};
    }
    &.address {
      margin: 0;
    }
  }
  li {
    list-style-type: none;
    display: flex;
    align-items: center;
    &::before {
      content: '∙';
      color: white;
      margin-right: 5px;
    }
  }
  a {
    font-size: ${vwMobile(17)};
    font-family: ${({ theme }) => theme.fontPrimary};
    font-weight: normal;
    line-height: 1.41;
    color: white;
    display: block;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(17)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(17)};
    }
  }
`;

const Section = styled.div``;
