import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from 'src/styles/media';

import { vw, vwMobile, vwTablet } from 'src/styles/utils';

const FullTextBlock = ({ text }) => {
  return <Root>{text}</Root>;
};

FullTextBlock.propTypes = {
  text: PropTypes.string,
};

const Root = styled.div`
  padding-top: ${vwMobile(92)};
  padding-bottom: ${vwMobile(116)};
  font-family: ${({ theme }) => theme.fontPrimary};
  font-size: ${vwMobile(16)};
  line-height: ${vwMobile(28)};
  letter-spacing: ${vwMobile(1)};
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  width: 86.4%;
  margin: 0 auto;
  @media (min-width: ${media.xsup}) {
    font-size: ${vwTablet(16)};
    line-height: ${vwTablet(28)};
    letter-spacing: ${vwTablet(5)};
    width: 42.85%;
    padding-top: ${vwTablet(92)};
    padding-bottom: ${vwTablet(116)};
  }
  @media (min-width: ${media.mdup}) {
    font-size: ${vw(16)};
    line-height: ${vw(28)};
    letter-spacing: ${vw(5)};
    padding-top: ${vw(280)};
    padding-bottom: ${vw(310)};
  }
`;

export default FullTextBlock;
