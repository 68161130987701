import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import media from 'src/styles/media';

import { vw, vwMobile, vwTablet } from 'src/styles/utils';

const CollectionCard = ({
  label,
  sublabel,
  imageSrc,
  linkTo,
  linkText,
  mobileImg,
}) => {
  return (
    <Root to={linkTo} mobileimg={mobileImg}>
      <LabelWrapper>
        <h3>{label}</h3>
        <h4>{sublabel}</h4>
      </LabelWrapper>
      <img src={imageSrc} alt={label} />
      {linkTo && <p>{linkText}</p>}
    </Root>
  );
};

CollectionCard.propTypes = {
  label: PropTypes.string,
  sublabel: PropTypes.string,
  imageSrc: PropTypes.string,
  linkTo: PropTypes.string,
  linkText: PropTypes.string,
  mobileImg: PropTypes.string,
};

const Root = styled(Link)`
  padding-top: ${vwMobile(12)};
  padding-bottom: ${vwMobile(18)};
  background-color: #f5f5f5;
  background-image: url(${props => props.mobileimg});
  background-size: 100%;
  color: ${({ theme }) => theme.colorNavy};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-transform: uppercase;
  margin: 0 auto ${vwMobile(5)};
  width: 86.67%;
  height: ${vwMobile(150)};
  @media (min-width: ${media.xsup}) {
    width: 93.4%;
    margin: 0 auto ${vwTablet(5)};
    padding-top: ${vwTablet(12)};
    padding-bottom: ${vwTablet(18)};
  }
  @media (min-width: ${media.mdup}) {
    width: auto;
    height: auto;
    margin-bottom: 0;
    padding-top: ${vw(24)};
    padding-bottom: ${vw(43)};
    background-color: white;
    background-image: none;
    &:not(:first-child) {
      margin-left: ${vw(15)};
    }
  }
  img {
    padding-top: ${vw(47)};
    padding-bottom: ${vw(68)};
    width: 100%;
    height: auto;
    display: none;
    @media (min-width: ${media.mdup}) {
      display: block;
    }
  }
  p {
    font-family: ${({ theme }) => theme.fontSecondary};
    font-size: ${vwMobile(11)};
    font-weight: 500;
    letter-spacing: ${vwMobile(1.57)};
    color: ${({ theme }) => theme.colorNavy};
    text-align: left;
    margin-left: ${vwMobile(12)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(11)};
      letter-spacing: ${vwTablet(1.57)};
      margin-left: ${vwTablet(26)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(11)};
      letter-spacing: ${vw(1.57)};
      text-align: center;
      margin-left: 0;
    }
  }
`;

const LabelWrapper = styled.div`
  text-align: left;
  margin-left: ${vwMobile(12)};
  @media (min-width: ${media.xsup}) {
    margin-left: ${vwTablet(26)};
  }
  @media (min-width: ${media.mdup}) {
    margin-left: ${vw(26)};
  }
  h3 {
    font-family: ${({ theme }) => theme.fontPrimary};
    font-size: ${vwMobile(26)};
    line-height: ${vwMobile(41)};
    letter-spacing: ${vwMobile(2)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(26)};
      line-height: ${vwTablet(41)};
      letter-spacing: ${vwTablet(2)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(26)};
      line-height: ${vw(41)};
      letter-spacing: ${vw(2)};
    }
  }
  h4 {
    font-family: ${({ theme }) => theme.fontSecondary};
    font-size: ${vwMobile(11)};
    font-weight: 500;
    letter-spacing: ${vwMobile(1)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(11)};
      letter-spacing: ${vwTablet(2)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(11)};
      letter-spacing: ${vw(2)};
    }
  }
`;

export default CollectionCard;
