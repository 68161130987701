import imgCeramicsCharacter from 'src/assets/images/ceramics/ceramics-character.svg';

import imgTemp from 'src/assets/images/product-page/temp-image.png';

export default {
  overview: {
    image: imgCeramicsCharacter,
    opacity: 1,
    height: 519,
    top: 104,
  },
  section1: {
    video:
      'https://player.vimeo.com/external/319957491.hd.mp4?s=7e2b624cd1e8d8d0b39214058bb80634ed3ddead&profile_id=175',
  },
  section2: {
    character: {
      image: '',
      height: 136,
      width: 268,
    },
    video:
      'https://player.vimeo.com/external/319957471.hd.mp4?s=dd4987f5ab5c46c9845d2ee72609109ef4aecb3f&profile_id=174',
  },
  featured: [
    [
      {
        image: require('src/assets/images/ceramics/products/ceramics1/Ceramics_Ceramics_1_1.jpg'),
      },
      {
        image: require('src/assets/images/ceramics/products/ceramics1/Ceramics_Ceramics_1_2.jpg'),
      },
      {
        image: require('src/assets/images/ceramics/products/ceramics1/Ceramics_Ceramics_1_3.jpg'),
      },
      {
        image: require('src/assets/images/ceramics/products/ceramics1/Ceramics_Ceramics_1_4.jpg'),
      },
      {
        image: require('src/assets/images/ceramics/products/ceramics1/Ceramics_Ceramics_1_5.jpg'),
      },
      {
        image: require('src/assets/images/ceramics/products/ceramics1/Ceramics_Ceramics_1_6.jpg'),
      },
      {
        image: require('src/assets/images/ceramics/products/ceramics1/Ceramics_Ceramics_1_7.jpg'),
      },
      {
        image: require('src/assets/images/ceramics/products/ceramics1/Ceramics_Ceramics_1_8.jpg'),
      },
      {
        image: imgTemp,
      },
      {
        image: require('src/assets/images/ceramics/products/ceramics1/Ceramics_Ceramics_1_9.jpg'),
      },
    ],
    [
      {
        image: require('src/assets/images/ceramics/products/ceramics2/Ceramics_Ceramics_2_1.jpg'),
      },
      {
        image: require('src/assets/images/ceramics/products/ceramics2/Ceramics_Ceramics_2_2.jpg'),
      },
      {
        image: require('src/assets/images/ceramics/products/ceramics2/Ceramics_Ceramics_2_3.jpg'),
      },
      {
        image: require('src/assets/images/ceramics/products/ceramics2/Ceramics_Ceramics_2_4.jpg'),
      },
      {
        image: require('src/assets/images/ceramics/products/ceramics2/Ceramics_Ceramics_2_5.jpg'),
      },
      {
        image: require('src/assets/images/ceramics/products/ceramics2/Ceramics_Ceramics_2_6.jpg'),
      },
      {
        image: require('src/assets/images/ceramics/products/ceramics2/Ceramics_Ceramics_2_7.jpg'),
      },
      {
        image: require('src/assets/images/ceramics/products/ceramics2/Ceramics_Ceramics_2_8.jpg'),
      },
      {
        image: require('src/assets/images/ceramics/products/ceramics2/Ceramics_Ceramics_2_9.jpg'),
      },
      {
        image: require('src/assets/images/ceramics/products/ceramics2/Ceramics_Ceramics_2_10.jpg'),
      },
      {
        image: require('src/assets/images/ceramics/products/ceramics2/Ceramics_Ceramics_2_11.jpg'),
      },
    ],
  ],
};
