import React, { Component } from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

import ExploreCollection from 'components/ExploreCollection/ExploreCollection';

class Collection extends Component {
  render() {
    return (
      <Root>
        <StyledExploreCollection noImage fullPage />
      </Root>
    );
  }
}

export default Collection;

const Root = styled.div``;

const StyledExploreCollection = styled(ExploreCollection)`
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (min-width: ${media.mdup}) {
    height: 80vh;
    min-height: 768px;
  }
`;
