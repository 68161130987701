import React, { Component } from 'react';
import styled from 'styled-components';

import media from 'src/styles/media';
import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import Gallery from './sections/Gallery';
import Collection from './sections/Collection';

class Menu extends Component {
  render() {
    return (
      <Root menuOpen={this.props.menuOpen}>
        <TopContainer menuOpen={this.props.menuOpen}>
          <Gallery
            menuOpen={this.props.menuOpen}
            handleMenu={this.props.handleMenu}
          />
        </TopContainer>
        <BottomContainer menuOpen={this.props.menuOpen}>
          <Collection
            menuOpen={this.props.menuOpen}
            handleMenu={this.props.handleMenu}
          />
        </BottomContainer>
      </Root>
    );
  }
}

export default Menu;

const Root = styled.div`
  position: fixed;
  height: ${props => (props.menuOpen ? '100vh' : '0')};
  width: 100vw;
  overflow: hidden;
  z-index: ${props => (props.menuOpen ? '9999' : '-1')};
  transition: ${props => (props.menuOpen ? 'all 1.6s' : 'all 0.8s')};

  h1 {
    font-family: ${({ theme }) => theme.fontPrimary};
    font-size: ${vwMobile(18)};
    font-weight: normal;
    line-height: 1.39;
    letter-spacing: ${vwMobile(1.8)};
    color: ${({ theme }) => theme.colorNavy};
    text-transform: uppercase;
    text-align: center;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(18)};
      letter-spacing: ${vwTablet(1.8)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(18)};
      letter-spacing: ${vw(1.8)};
    }
  }

  a,
  h3 {
    font-family: ${({ theme }) => theme.fontSecondary};
    font-size: ${vwMobile(11)};
    font-weight: 500;
    line-height: 2.36;
    letter-spacing: ${vwMobile(1.7)};
    color: #000000;
    text-transform: uppercase;
    display: block;
    text-align: center;
    z-index: 999;
    position: relative;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(11)};
      letter-spacing: ${vwTablet(1.7)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(11)};
      letter-spacing: ${vw(1.7)};
    }
  }
`;

const TopContainer = styled.div`
  background: white;
  width: 100%;
  max-height: ${props => (props.menuOpen ? '40vh' : '0vh')};
  transition: all 0.8s;
  transition-delay: ${props => (!props.menuOpen ? '30s' : '0s')};

  height: 40vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: ${media.xsup}) {
    height: 50vh;
    max-height: ${props => (props.menuOpen ? '50vh' : '0vh')};
  }
`;

const BottomContainer = styled.div`
  max-height: ${props => (props.menuOpen ? '60vh' : '0vh')};
  transition: all 0.8s;
  transition-delay: ${props => (props.menuOpen ? '0.7s' : '0')};
  height: 60vh;
  background: linear-gradient(
      rgba(216, 216, 216, 0.35),
      rgba(216, 216, 216, 0.35)
    ),
    linear-gradient(white, white);
  overflow: hidden;
  @media (min-width: ${media.xsup}) {
    height: 50vh;
    max-height: ${props => (props.menuOpen ? '50vh' : '0vh')};
  }
`;
