import React from 'react';

export default {
  switchLang: 'Eng',
  footer: {
    address: '加拿大卑诗省温哥华 Howe 街 420 号',
    hours:
      '开放时间：周一至周五早上 10 时半至晚上 6 时半｜周六：早上 11 时至晚上 6 时',
    menu: ['茶', '艺', '雕像', '茶具', '典藏', '关于', '活动', '造访'],
    commercial: '企业专用',
    privacy: '隐私政策',
    terms: '使用条款',
    copyright: 'Copyright © 2019 Sunzen',
    subscribe: '订阅电子报',
    signup: '欢迎订阅电子报获知有关我们的活动、展览和新到典藏品的最新信息。',
    yourEmail: '您的电邮',
    success: '感谢您的订阅',
    error: '订阅发生错误，请再尝试一次',
    alreadySubscribed: '错误：此电子邮件位址已订阅我们的电子报',
  },
  menu: {
    gallery: {
      title: '三生缘文化艺术中心',
      links: ['典藏', '关于', '活动', '造访'],
    },
    collection: {
      title: '关于',
      links: ['茶', '艺', '陶瓷', '家具'],
    },
    bottom: ['隐私政策', '使用条款'],
  },
  product: {
    discover: '探索我们其他的特殊收藏',
    title: '探索典藏',
    menu: ['茶', '艺', '陶瓷', '家具'],
    learnMore: '了解更多',
    featured: {
      discover: '欢迎莅临三生缘文化艺术中心探索我们的珍藏',
      visit: '造訪我們',
    },
    readMore: '了解更多',
    close: '关闭',
    select: '请选择',
  },
  tea: {
    overview: {
      title: '茶的起源',
      description:
        '历史记载，茶叶是在 3000 多年前由神农氏所发现。相传他在饮水之前，都会先将水煮开，以确保健康。某次，他在一个荒凉的户外煮水时，一片落叶意外地掉在他正烹煮的水当中，神农氏饮用后，发现有提神之效。在《神农本草经》当中，也记述了他发现茶的过程：“神农尝百草，日遇七十二毒，得荼而解之。”文中所提到的茶，也就是今天我们熟悉的茶饮。',
    },
    section1: {
      title: '中国的茶艺文化',
      description1A:
        '发源于中国，在历史上仅提供给皇帝的茶饮，已是现代人日常生活中不可或缺的一部分。传统上，茶叶的种类有白茶、绿茶、乌龙茶、黄茶、红茶和发酵茶等六种，并且依照加工方式被方做紧压茶和散茶两大类。此外，还有被添加不同香料的风味茶，例如菊花茶等，因为菊花也有许多不同品种，所以这类风味茶的总量超过 1000 多种。',
      description1B: '',
    },
    section2: {
      title: `普洱茶：中国珍宝之一`,
      description2A: `普洱茶是以云南省一定区域内的云南大叶种晒青毛茶为原料，经过后发酵加工成的散茶和紧压茶，也被称作中国的珍宝之一。与其他茶叶不同，普洱茶散发出一股独特味道，并在近年来越受欢迎，许多爱茶人士相信，普洱茶所散发出的“茶气”，对于改善能量有一定程度的帮助。"`,
      description2B: '',
    },
    featured: {
      title: (
        <h1>
          三生缘
          <br />
          茶叶收藏
        </h1>
      ),
      description:
        '除了金属和陶瓷茶具之外，我们也收藏了一系列最佳品质的普洱老茶。在研究茶叶方面，三生缘拥有丰富的经验和悠久的历史，可以协助您找到世界上最珍贵的普洱茶砖。',
      items: [
        {
          title: '紫砂壶',
          items: [
            {
              itemTitle: '葛陶中制仿古壶',

              itemDescription:
                '一说清代邵大亨初创,原意是壶体仿照鼓型，后人仿制做这种壶形就成了仿古代壶型的意思了。另说最早见于近代赵松亭按吴大徴授意所作。此壶身扁、腹鼓、颈高、盖板平滑，壶盖与口沿子母线吻合严密，合成圆线饱满，二弯流胥出自然；圆圈把匀势而起，把圆下有垂，富于灵动，整体骨肉亭匀，以一捺底制法，收展有度。',
            },
            {
              itemTitle: '葛陶中制砖方壶',
              itemDescription:
                '一把能让人赏心悦目的紫砂壶,总能展现出绝美的线条艺术,而线条的流畅、自然、恰当也是欣赏紫砂壶的重要因素。紫砂壶的造型丰富、变化无穷,按其不同的形式及特点,可将它的造型分为圆器、方器、筋囊器等三种，方器因其造型的特殊性而制作难度及烧制难度更大，业内也常有“一方抵十圆”之说。此壶有方之坚挺之势，在一些细节的处理上附有圆的因素，方圆相合、刚柔并济。',
            },
            {
              itemTitle: '钵盂提梁壶',
              itemDescription:
                '提梁壶是以佛教的钵盂为基型，配以壶嘴与提梁，是清末民国初年的紫砂名家陈光明所创，后人多有仿制。此壶提梁挺拔，桥钮圆润，适手性强，壶身圆浑精致，形制饱满，光滑如玉',
            },
            {
              itemTitle: '顾景舟制三足乳鼎壶',
              itemDescription:
                '这件“三足乳鼎壶”入藏三生缘，当属幸事，余秋雨评价“虽然壶的体量不大，但即便在展览里随手放在旁边，还是一眼就能看出来，它的圆润、它的不张扬、它的完美性，可以说至高无上，很难复制——高贵的东西往往是这样，具有一种能够控制全场的气度。"补充：此壶是中国近代紫砂界泰斗顾景舟先生晚年的一款作品，制于上世纪八十年代。',
            },
            {
              itemTitle: '',
              itemDescription:
                'With primitive simplicity and purity, and free from gaudy colors and vulgarity, the violet sand-fired potteries share similar temperaments with scholars, thus beloved by them; the scholars took the green body of these potteries as a kind of paper, inscribed either introduction words for the pot, or personal name, or flower patterns, or seals on it to express their ideas; many of these designs were very ingenious. In the Ming and Qing Dynasties, some scholars loved to make poems and paintings, and inscribed their poems on a pot for the commemoration of a certain event; they also left a message in the inscriptions to present such pots as gifts. At that time, Chen Mansheng and Ziye were the most talented ones, and they promoted the development of violet sand-fired pottery engraving art, and popularized the saying that "the calligraphy circulates with the pot, while the pot is increased in value with the calligraphy."',
            },
            {
              itemTitle: '任备安朱泥梨式壶',
              itemDescription:
                '此壶做工严谨，丰腴盈满，窑火得宜，一经茶汤润养，则更显可人，加之曲线优美，为实用性极佳的茶器。',
            },
          ],
        },
        {
          title: '铁壶银壶',
          items: [
            {
              itemTitle: '龙文堂造铜包银嵌金松镝钮银壶',
              itemSubtitle: '明朝',
              itemDescription: `三生缘收藏的安之介作品“铜包银嵌金松镝钮壶”，集合了雕塑、绘画、书法、镶嵌工艺，以金为钮，银为胆，铜为身，铁、锡为提梁，壶胆内有刻花，并精心刻绘了苍劲虬松与飘逸书款，融金银铜铁锡五种材质、精湛绝伦工艺、清雅文人气息与百余年历史传承于一身，是铁壶中的极品。`,
            },
            {
              itemTitle: '霰纹金壶',
              itemSubtitle: '明朝',
              itemDescription: `霰纹是日本传统金属壶中最为重要，同时也是最复杂的饰纹，霰型的技法，可以追溯到日本江户时期的手工制作工艺，这种技艺由师徒之间身口相传，敲击的力度要靠身体的摆动幅度来记忆，霰点由口至壶底从小变大，敲打时全凭手感，需要极其深厚的功力。
              `,
            },
            {
              itemTitle: '山田宗光铜包银壶',
              itemSubtitle: '明朝',
              itemDescription:
                '山田家族是幕府末期至大正时期有名的金工世家，山田宗光天宝2年出生，是其第九代传人。山田宗光从父亲那继承了镶嵌技法，学习江户锻金技术，擅长以铜银铁为材质的金工工艺，能自然展现单一金属物质的美感，又能将两种以上的金属互相结合，展现出另一种质感及气韵，本品即为此风格之经典之作。',
            },
            {
              itemTitle: '二代藏六造藤柄兽口玉钮银壶',
              itemSubtitle: '明朝',
              itemDescription:
                '本品壶身材质纯净，造型厚重而不失优雅，尽现“蜡型”铸工的精湛。原配木箱，长篇记述了该银壶的创作灵感，以及对中国古代工艺文化的传承与景仰。壶盖部分配有镂雕白玉钮，并别具匠心地仿汉代瓦当制式，铸上“长乐万岁”四个篆字，以兽形为口，古色古香，置身壶前，仿佛有一股浓郁的“中国气韵”扑面而来。',
            },
            {
              itemTitle: '龟文堂铁壶',
              itemSubtitle: '明朝',
              itemDescription:
                '波多野正平在龙文堂学习了较高级别的蜡铸法，所以目前所见龟文堂作品中，蜡铸法成为主要制作方式，本品即为蜡铸法代表之作。除蜡铸这一特点外，龟文堂的作品壶身多雕饰，并因改良 S 型可拆式手把而广受好评。',
            },
          ],
        },
        {
          title: '茶叶',
          items: [
            {
              itemTitle: '三生缘纪念茶 2013 年款',
              itemSubtitle: '明朝',
              itemDescription:
                '三生缘纪念茶，选用 2011 年云南凤庆茶山优质无公害鲜叶作原料，于 2013 年压制成饼，韵味多变，茶气较强，茶汤清香，水甜微涩，饮后回甘，精选之茶菁辅以上佳之制作工艺，本款生茶茶饼可称为愈放愈好的茶品。',
            },
            {
              itemTitle: '同庆号普洱圆茶, 1910 年代',
              itemSubtitle: '明朝',
              itemDescription: `同庆号茶庄于 1736 年设立了毛茶工厂，历史悠久，代表同庆老号的“龙马商标”内票、内飞于1920年更改为“双狮旗图”，普洱爱好者都把这两期的普洱视为珍宝。该茶条索扁长，光泽油亮，有上好的野樟香，水性圆厚滑顺，润喉微甜，茶气颇强，已臻达舌底鸣泉境界，称得上普洱茶中极品。`,
            },
            {
              itemTitle: '70 年代文革砖',
              itemSubtitle: '明朝',
              itemDescription:
                '制成于上世纪 60 至 70 年代，冲泡后茶汤明艳通透，红色基调中泛着琥珀的金黄，非常动人。口味上内涵丰富，香气厚重，水性沙滑。同时因文革砖具有特殊的文化背景和历史价值，所以一直以来都被众多喜茶之人争先购藏。可惜的是，由于文革砖存世的数量十分有限，所以现今有似凤毛麟角一般的稀有，弥足珍贵。',
            },
            {
              itemTitle: '1950 年代无纸红印',
              itemSubtitle: '明朝',
              itemDescription:
                '印级圆茶历史悠久，整体品质优异，尤以红印，无纸红印等为佳。这些圆茶的原料，主要采自于西双版纳地区的易武，班章，巴达，布朗诸山的大叶种茶菁，且已经历了五六十年的陈化，故茶质厚重，茶韵深沉。制于二十世纪五十年代的无纸红印圆茶存世量较少，已渐渐从流通市场中消失了，故一茶难求 ',
            },
            {
              itemTitle: 'Yiwuzheng Mountain',
              itemSubtitle: '明朝',
              itemDescription: `二十世纪五十年代之红印圆茶，所用茶菁采自六大茶山之一的易武正山。饼身厚实，饼面光泽油润，条索扁长饱满，茶汤红亮，滋味醇厚，水性厚滑，叶底富有弹性，有淡淡樟香，茶气强，是印级普洱茶之典！`,
            },
            {
              itemTitle: '1950 年代蓝印',
              itemSubtitle: '明朝',
              itemDescription: `在生产印级茶的年代，中茶牌蓝印圆茶中有称为“蓝印甲级”和“蓝印乙级”的茶品，它们因其包装纸上印有甲级或乙级两字而得名。蓝印圆茶制于二十世纪五十年代，所用茶菁由于选料精细，芽叶完整清晰，经过长期的自然陈化，饼面油润，汤色明亮，香气纯正，滋味醇和，可以称得上是印级圆茶中的精品。`,
            },
            {
              itemTitle: '蓝印铁饼',
              itemSubtitle: '明朝',
              itemDescription: `二十世纪五十年代初，中茶公司为了扩大产量，采用全新的金属压模来代替传统布袋压制工艺，所压成的茶饼紧实坚硬，其包装风格与蓝印圆茶相近，故称蓝印铁饼圆茶。蓝印铁饼圆茶选料精细，经过数十年的自然陈化，其品质非一般茶品可相比拟。 `,
            },
          ],
        },
      ],
    },
    lessons: {
      title: '功夫茶八大步骤',
      subtitle: '概论',
      steps: [
        {
          title: '治器',
          description:
            '所谓治器，就是将冲茶的茶器清洗工净。传统的茶具配有一个茶壶与多个茶杯。“治器”的目的就在于祛除茶具里不好的气味，避免影响冲沏出来后的茶的口感。',
        },
        {
          title: '纳茶',
          description:
            '由于茶叶吸味性强，因此需使用茶叶用茶勺盛起倒入茶壶里。避免茶叶吸入手中异味。茶叶盛入茶壶时，茶碎需放在茶壶内里，颗粒状的茶叶放在茶壶上面和出水口处，冲茶的时候可以避免茶碎随茶水流出。',
        },
        {
          title: '候汤',
          description:
            '只有合适的水温，才能泡出茶叶的最佳味道。水滚时也可分为几个阶段，只有当水泡连串冲上来，水面浮珠的时候才是泡茶的最佳时机。',
        },
        {
          title: '冲茶',
          description:
            '冲茶的技巧是将烧开的水沿着茶壶的边缘冲入，必须避免对准壶心，以免茶的香气挥发得太快，',
        },
        {
          title: '刮沫',
          description:
            '冲茶必定会倒出一些泡沫，“刮沫”这一步骤就是为了将影响茶汤的泡沫和杂质刮除，',
        },
        {
          title: '淋罐',
          description:
            '淋罐的目的在于追加茶壶的温度，使茶香迅速挥发出来。当茶壶外的水干时，便可斟茶。',
        },
        {
          title: '烫杯',
          description:
            '烫杯的目的除了消毒茶杯之外，也让茶杯本身有一定的高温，尚有余热的杯子可以为茶汤保温。',
        },
        {
          title: '斟茶',
          description:
            '斟茶前要先将茶杯摆成品字形，在斟茶的过程中需要沿着三个茶杯一圈圈、循环的斟沏，以三到四圈为宜',
        },
      ],
    },
  },
  art: {
    overview: {
      title: '中国艺术',
      description: `中国艺术拥有悠久历史，自西元前 200 年的汉朝开始即有字画出现。现今的中国国画主要被分坐两种类型，包括工笔画和水墨画。工笔画讲究技法的工整细致，水墨画则类似于西方的水彩画。`,
    },
    section1: {
      title: '国画与字画',
      description1A: `历史上，中国国画字画是息息相关的，因为两者使用相似的手法及工具。正如同彩绘出优美的自然景观一般，字画也是艺术家透过字体和字样来展现技术的一种抒发方式。`,
      description1B: ``,
    },
    section2: {
      title: `三生缘和艺术推广`,
      description2A: `三生缘收藏的字画，出自于多位中国大师的手笔。三生缘持续与世界各地的艺术家紧密合作，最主要的目标之一，就是将中国艺术推向国际，创造更多令人赞叹的艺术钜作。`,
      description2B: '',
    },
    featured: {
      title: (
        <h1>
          三生缘
          <br />
          艺术收藏
        </h1>
      ),
      description:
        '三生缘经常性地展出许多传统国画，也收藏了不少20世纪的字画、雕像和许多以不同形式所创作的艺术品。这些珍藏充分地展现了中国艺术的传统和多元化',
      items: [
        {
          title: '国画',
          items: [
            {
              itemTitle: '丹竹',
              itemSubtitle: '明朝',
              itemDescription: `启功先生是中国著名的书法家，书画鉴定家，以书法名满天下，其绘画作品虽不太多见，但也功力深厚、风格明显，在他的绘画中，引起书画界关注的“红竹”一直成为话题，他笔下之红竹，热烈奔放，也是启功先生艺术创新精神在其绘画创作中的体现。`,
            },
            {
              itemTitle: '锦鸡梅花',
              itemSubtitle: '黄杨木',
              itemDescription:
                '王雪涛先生是现代中国卓有成就的花鸟画大师，以小写意花卉草虫著称画坛。其笔下的作品，笔墨洒逸、极富情趣。此幅作品鲜活多姿，生动可爱，幻化出艺术之美和盎然之生气。',
            },
            {
              itemTitle: '张大千，山水',
              itemSubtitle: '明朝',
              itemDescription:
                '张大千的本幅作品用笔古朴豪放，意境空灵隽永，架构自然忘形，泼墨淳厚飘逸。以墨色呈现远山的层次，中间留白得当使画面纯净虚空，近景植入山石，高士远眺江帆，诗情画意跃然纸上。',
            },
            {
              itemTitle: '徐悲鸿',
              itemSubtitle: '明朝',
              itemDescription:
                '喜鹊是中国画的传统题材之一，徐悲鸿多次以喜鹊图赠友，暗含“喜上眉梢”、“捷报频传”等美好心愿。世人皆知徐悲鸿一生致力于以西画的写实精神改良中国画，创作出许多精品，尤以奔马享名于世。但许多人不知道的是，徐悲鸿擅长画喜鹊。此幅作品之喜鹊在徐悲鸿的笔下，亦是惟妙惟肖，栩栩如生，令人回味无穷。 ',
            },
            {
              itemTitle: '白描老子出关图及对联',
              itemSubtitle: '明朝',
              itemDescription:
                '"老子出关是范曾先生的代表题材，本套作品采用白描手法，人物形态生动，仙风道骨。线条刚劲有力，极具功力，人物眼神空灵，与道教之义相和。两边配以对联，嵌老子经典著作与人物归宿。',
            },
            {
              itemTitle: '岭上颂寿',
              itemSubtitle: '明朝',
              itemDescription:
                '范曾先生以人物画见长，其中童子更为经典，此作品中孩童憨态可掬，灵猴摘桃之势生动逼真，整个画面构画精妙，笔法无可挑剔！ ',
            },
          ],
        },
        {
          title: '工艺品',
          items: [
            {
              itemTitle: '透雕子孙太平笔筒',
              itemSubtitle: '明朝',
              itemDescription:
                '小叶紫檀透雕子孙太平笔筒，精选小叶紫檀好料，以透雕技艺全手工制成，所有部位都是在整木基础之上挖作而出，筒身以藤蔓环绕，瓜瓞绵绵，寓意子孙昌盛、平安吉祥。',
            },
            {
              itemTitle: '黄杨木雕，四大美女',
              itemSubtitle: '黄杨木',
              itemDescription:
                '本品由佘国平老师以黄杨木雕刻而成，黄杨木生长期长，有千年黄杨之称，其木材坚韧细洁，非常适合人物的雕刻。本套作品制作细腻入微，仪态、发饰、服饰等细节都洽到好处地体现了时代背景下的人物的特点 ',
            },
            {
              itemTitle: 'Wood carving #3',
              itemSubtitle: 'Ming Dynasty',
              itemDescription:
                '丝翎檀雕，是结合传统木雕工艺，将工笔画经过再创造并以浅浮雕的形式立体呈现在檀木上的一种新型高端工艺美术品，所谓“丝翎”，源于翎毛，因翎毛画是工笔国画的一种门类技法，丝翎檀雕通过取神得形，以线立形，以形达意，获取神态与形体的完美统一，在保留中国工笔画精致细腻、形神兼备特点的同时，通过木质载体立体地呈现，彻底改变了国画二维空间展现的局限性，是木雕行业的一大创新。本品即采用此种工艺，以小叶紫檀为载体，将翎鸟刻画得惟妙惟肖，枝叶藤蔓生动逼真。',
            },
            {
              itemTitle: '印度东加檀香水月观音',
              itemSubtitle: '明朝',
              itemDescription:
                '本品通体由东加檀香木制成，东加檀香木产自汤加共和国，产量极少，木材香气柔和，略带甜味，作品主体部分由整木雕成，观音呈半跏趺坐自在像，法相庄严，十分殊胜，以海水莲花为座，线条流畅，造型大气，特别是在衣饰、发饰等细节上的精细处理，彰显工艺师非凡的技艺。',
            },
          ],
        },
        {
          title: '雕像',
          items: [
            {
              itemTitle: '佛像',
              itemSubtitle: '明朝',
              itemDescription:
                '极彩手绘佛像，是以高纯度铜料脱蜡精密铸造成型，再打磨修饰表面，让整体佛像的柔软度再次精致表露，表面以工笔绘画极彩，增添真实感，帖金箔以纳米漆保护永不褪色，保养时可用棉布沾水拧干轻轻擦拭，永保庄严！',
            },
          ],
        },
      ],
    },
    lessons: {
      title: '三生缘艺术大师',
      subtitle: '了解更多',
      steps: [
        {
          title: '启功',
          description: `启功先生是中国著名的书法家，书画鉴定家，以书法名满天下，其绘画作品虽不太多见，但也功力深厚、风格明显，在他的绘画中，引起书画界关注的“红竹”一直成为话题，他笔下之红竹，热烈奔放，也是启功先生艺术创新精神在其绘画创作中的体现。`,
        },
        {
          title: '张大千',
          description:
            '张大千先生是中国著名画家，书法家，特别在山水画方面卓有成就。画风工写结合，重彩、水墨融为一体，尤其是泼墨与泼彩，开创了新的艺术风格。',
          subsection: {
            title: '更多信息',
            listItems: [
              '本幅作品用笔古朴豪放，意境空灵隽永，架构自然忘形，泼墨淳厚飘逸。以墨色呈现远山的层次，中间留白得当使画面纯净虚空，近景植入山石，高士远眺江帆，诗情画意跃然纸上。',
            ],
          },
        },
        {
          title: '范曾',
          description: `在 20 世纪 70 年代后期至 80 年代初期的中国人物画变革发展进程中，范曾是一位开风气之先的人物。当时，范曾的画靡被画坛，影响波及东瀛，拥有大批追随者。`,
          subsection: {
            title: '更多信息',
            listItems: [
              '范曾的人物画以其迥异于20世纪五、六十年代模式化、政治化的人物造型形象，以古代先贤和文化名人为题材，以传统文人画的笔墨为表现手段，率先冲破了笼罩中国画三十余年的意识形态化倾向，为中国人物画的审美转换提供了契机。而他的以线赋形，强调骨法用笔和笔墨表现的创作观念和实践更是打破了写实主义对现代中国人物画创作的绝对笼罩，将现代人物画由写实向写意推进了一大步。',
            ],
          },
        },
        {
          title: '徐悲鸿',
          description: `徐悲鸿先生擅长人物、走兽、花鸟，主张现实主义，对当时中国画坛影响甚大。所作国画彩墨浑成，尤以奔马享名于世。`,
          subsection: {
            title: '更多信息',
            listItems: [
              '喜鹊是中国画的传统题材之一，徐悲鸿多次以喜鹊图赠友，暗含“喜上眉梢”、“捷报频传”等美好心愿。世人皆知徐悲鸿一生致力于以西画的写实精神改良中国画，创作出许多精品，尤以奔马享名于世。但许多人不知道的是，徐悲鸿擅长画喜鹊。此幅作品之喜鹊在徐悲鸿的笔下，亦是惟妙惟肖，栩栩如生，令人回味无穷。',
            ],
          },
        },

        {
          title: '王雪涛',
          description:
            '王雪涛先生是现代中国卓有成就的花鸟画大师，以小写意花卉草虫著称画坛。',
          subsection: {
            title: '更多信息',
            listItems: [
              '其笔下的作品，笔墨洒逸、极富情趣。此幅作品鲜活多姿，生动可爱，幻化出艺术之美和盎然之生气。',
            ],
          },
        },
      ],
    },
  },
  ceramics: {
    overview: {
      title: '中国陶瓷',
      description: `陶器出现在中国境内的历史，最早可追溯至远古时代。至今，陶艺品也是中国发展最完善的艺术之一。从最早的基本陶器，演变至现代钜细弥遗的陶艺品，中国在陶瓷艺术方面的专才可说是举世皆知，许多以陶瓷制成的产品，在西方世界仍被称作“China”，等同于“中国”之英文名称。`,
    },

    section1: {
      title: '中国陶瓷茶具',
      description1A: `跟茶叶一样，泡茶所使用的茶具也拥有悠久的历史。大约2000年前，在茶叶被发现的同一时期，茶具也开始被爱茶人士使用。一般的茶具都是以陶瓷制成，风格上可大致被分为南方与北方两种。两者主要的差别在于颜色，主因两个地区的地质有天壤之变，导致陶土拥有不同的特性。`,
      description1B: `北方陶瓷茶具以白色为主，南方的则带有些许浅蓝色。除了这些差别之外，传统茶具通常也没有茶壶，因为古代人习惯直接将茶叶、香料和花朵泡在水中，并饮用所产生的茶汤。`,
    },
    section2: {
      title: `春风祥玉窑`,
      description2A: `景德镇的春风祥玉窑世界上是最知名的精品陶瓷制造地之一。`,
      description2B: `那里所制造的瓷器以工法和无与伦比的颜色和花样著称，近年来备受收藏家的喜爱，价值因此持续成`,
    },
    featured: {
      title: (
        <h1>
          三生缘
          <br />
          陶瓷珍藏
        </h1>
      ),
      description:
        '除了一系列的陶瓷与紫砂茶具外，三生缘也典藏了许多足以代表各个朝代的传统瓷盆和花瓶，以及众多由春风祥玉窑所生产的瓷器。',
      items: [
        {
          title: '瓷器',
          items: [
            {
              itemTitle: '兽耳扁瓶',
              itemSubtitle: '明朝',
              itemDescription:
                '乾隆御窑的青花瓷既与清幽的康熙青花有别，又与淡雅的雍正青花不同。本品器型扁圆，腹部下垂，椭圆形圈足外撇。口沿绘缠枝花，头部饰如意云、海水、缠枝莲纹，腹部两面以缠枝莲为地，各有四个椭圆开光，内绘折枝花果，足部绘缠枝莲纹和海水纹。胎体厚重、造型古朴，器型恢宏俊伟，釉面明亮温润，青花苍翠欲滴，画法规整，构图严谨，一丝不苟，尽显皇家气象。 ',
            },
            {
              itemTitle: '象耳扁瓶',
              itemSubtitle: '黄杨木',
              itemDescription:
                '本品小口圆唇、细直颈、扁腹、椭圆形式宽圈足，颈肩部堆塑两个象头作耳，器形高大规整。前后腹绘制青花缠枝莲纹，腹中心绘有寿桃纹，画工精湛。器型浑圆大气，有皇家风范，寓意美好。原件现收藏于上海博物馆。 ',
            },
            {
              itemTitle: '鸡缸杯',
              itemSubtitle: '明朝',
              itemDescription:
                '鸡缸杯以新颖的造型、清新的装饰、精致的工艺而历受赞赏，堪称明成化斗彩器之经典。其胎质洁白细腻，柔和莹润，画面设色釉运用了点彩、染彩等技法，施彩于浓淡之间，素雅鲜丽兼而有之。',
            },
            {
              itemTitle: '青花矾红梅瓶',
              itemSubtitle: '明朝',
              itemDescription:
                '该梅瓶形体高大，端庄秀雅，侈口、矮颈、丰肩、腹部弧收。头部饰蕉叶纹，肩垂如意云头，腹部以翻涌的青花海水为地，之上红彩画九条神彩各异的龙腾跃于浪涛之间。青花细条纤细流畅，龙体活灵活现，颜色艳丽夺目，寓风调雨顺，安乐太平。',
            },
            {
              itemTitle: '山水折腰杯',
              itemSubtitle: '明朝',
              itemDescription: `高度大小适宜，聚香聚味，贴合手部，以折腰为线，色彩、工艺稍作区别又相互交融，更加饱满丰富！折腰二字应取晋陶潜“不为五斗米折腰”之典故`,
            },
            {
              itemTitle: '青花玲珑壶',
              itemSubtitle: '明朝',
              itemDescription: `花鸟仿著名老艺人王步之风格，清丽脱俗，图案纹制丝丝入扣，形神兼具。壶形采用传统美人肩，圆润饱满！`,
            },
            {
              itemTitle: '兽耳山水茶叶罐',
              itemSubtitle: '明朝',
              itemDescription: `兽耳山水茶叶罐：盖与身贴合度强，口沿与颈部纹饰呼应，壶身绘制山水，构图精巧，画意极富韵味，两边以瑞兽为耳，更添一份大气。`,
            },
            {
              itemTitle: '鹿首尊',
              itemSubtitle: '明朝',
              itemDescription:
                '鹿首尊，瓷器中的传统器型，造型圆融大器，青花、粉彩均有可考之精品，可谓堂上重器！',
            },
            {
              itemTitle: '居和堂',
              itemSubtitle: '明朝',
              itemDescription:
                '此件出自居和堂，万花锦绣中双面开窗，山水其中，古典雅致，层次分明，色彩艳而不俗！',
            },
            {
              itemTitle: 'Hsiao Fang Kiln',
              itemSubtitle: '明朝',
              itemDescription:
                'Hsiao Fang Kiln is a well-known porcelain making kiln in Taiwan; among the various antique-imitation porcelains produced by it, the most characteristic one should be those modelled after the Song Dynasty Ru Kiln porcelains. This kind of porcelains once flourishing in the history give full expression to the particular aesthetic appreciation of the royal family of the Northern Song Dynasty in its pursue of the color similar to "that where the clouds disperse after a rain stops and the sun comes out". Irregular ice cracks loom in the jade-like gentle glaze, presenting miraculous artistic effects, and thus making people loves it upon seeing and reluctant to put it down. ',
            },
          ],
        },
        {
          title: '瓷器',
          items: [
            {
              itemTitle: '十八学士品杯',
              itemSubtitle: '明朝',
              itemDescription:
                '以唐太宗长安城文学馆之十八学士为绘制原形，将人物各异的形情姿态表现得十分生动，几可从画中走出。内外口沿及圈足皆饰以双线，使画片整体呼应极具协调感。',
            },
            {
              itemTitle: '牡丹纹文房',
              itemSubtitle: '黄杨木',
              itemDescription:
                '牡丹寓富贵之意，是各种艺术品中最常见的传统纹饰之一，此套文房用具通体以青花绘制，清雅文气，牡丹亦艳而不俗，蔓藤缠枝给画面增加了灵动的视觉感受，其中体形最大的笔筒口沿的如意云头与底足的莲瓣造型相互呼应。',
            },
            {
              itemTitle: '百子高足杯',
              itemSubtitle: '明朝',
              itemDescription:
                '在各传统杯型中，高足杯在制作工艺上较度相对较高。高足杯又名马上杯，因杯身下有高足，故名。杯口微撇，近底处丰满，下承高足，高足有圆柱形、四方形、竹节形等。高足杯盛于元，似与蒙人善饮有关，可曾想，昔日金戈铁马，气吞万里，今日曼妙婉转，一杯香茗。本品在方寸之中绘制百子之童，且形态各具，十分精巧',
            },
            {
              itemTitle: '青花缠枝莲玉壶春瓶式香炉',
              itemSubtitle: '明朝',
              itemDescription:
                '玉壶春瓶又称玉壶赏瓶，是中国瓷器造型中的一种典型器形。流行地区广，沿用时间长，它的造型是由唐代寺院里的净水瓶演变而来。基本形制为撇口、细颈、垂腹、圈足。它是一种以变化柔和的弧线为轮廓线的瓶类。其造型上的独特之处是:颈较细，颈部中央微微收束，颈部向下逐渐加宽过渡为杏圆状下垂腹，曲线变化圆缓，圈足相对较大，或内敛或外撇。此作品将经典器型改制成香炉，并在表面饰以缠枝莲图案，有清廉寓意，更突出文人高洁的品性与气质。',
            },
            {
              itemTitle: '婴戏图盖碗',
              itemSubtitle: '明朝',
              itemDescription: `此件婴戏图盖碗，采用传统的童子纹样，辅以山石花卉，画面生动自然，童子憨态可人，一片生机。釉料色泽艳丽却不落俗套，足以体现艺人的技艺！`,
            },
            {
              itemTitle: '粉彩万花',
              itemSubtitle: '明朝',
              itemDescription:
                '本品的巧妙之处在于在器型表面以万花纹饰突出局部，在繁花中的留白表达出极高的审美情趣。',
            },
            {
              itemTitle: '青花釉里红缠枝莲盖碗',
              itemSubtitle: '明朝',
              itemDescription: `盖碗是一种上有盖、下有托，中有碗的茶具，又称“三才碗”、“三才杯”，盖为天、托为地、碗为人，暗含天地人和之意。此作品采用缠枝莲纹饰，并以釉里红绘制莲花，达到主题突出有层次感的视觉效果。盖沿、杯沿及底托沿均饰以回纹，简单大气，杯沿外撇，适水感强，泡茶时不会烫手，杯腹略有弧起，增加器型圆润之感，更能让茶叶有更好的舒展空间。`,
            },
            {
              itemTitle: '青花釉里红缠枝莲纹莲子缸',
              itemSubtitle: '明朝',
              itemDescription:
                '莲子缸因形似莲子而得名，此作品鼓腹，圆润可爱，画面清秀，实用性强。',
            },
            {
              itemTitle: '青花釉里红松竹梅公道杯',
              itemSubtitle: '明朝',
              itemDescription:
                '松竹梅被寓为岁寒三友，常寓文人的高洁品性，作品以釉里红突出主题，釉里红发色深沉，是不可多见的佳品。',
            },
            {
              itemTitle: '青花釉里红茶壶套组',
              itemSubtitle: '明朝',
              itemDescription:
                '此作品由一壶四杯组成，壶为传统梨式壶，线条自然秀美，腹略鼓，流、把对称，造型和谐自然。茶杯造型与壶呼应，敛口鼓腹，圈足、口沿以双线呼应。',
            },
            {
              itemTitle: '青花釉里红鱼藻纹高足杯',
              itemSubtitle: '明朝',
              itemDescription:
                '鱼藻纹是古代陶瓷纹饰中一种常见的表现形式，鱼藻一词始于《诗经》，鱼与“余”谐单，寓意吉祥。本品画面构图精匚，布局疏密有致，绘画采用写意技法，运笔流畅，游鱼和水草、莲花相互配合，相得益彰，充满大自然的意趣！',
            },
          ],
        },
      ],
    },
  },
  furniture: {
    overview: {
      title: '中式古典家具的历史',
      description: `现今我们所见到的中式家具，源自于约西元前1000年，结构上包含外框、板面、木轭和支架，以及许多以竹子为材料所建筑的结构。中式家具的演进独立于西式家具，不过呈现的成品功能相似，例如桌子、凳子、椅子、橱柜和床座。`,
    },

    section1: {
      title: '中国美学',
      description1A: `从历史角度来看，所谓的“中国美学”，出现在西元前1500至 1000年间。这个时期的画作时常描画出架高地板，搭配矮桌和椅子，不过同时期的中国家具，也已经发展得相当健全，设计上常见错综复杂的雕刻、装饰，以及一些上漆的物品。`,
      description1B: `若就现代中式家具而言，其特色在发展初期便清晰可见，主要原因与直到明清时期才废除的买卖限制有关。`,
    },
    section2: {
      title: `三生缘家具`,
      description2A: `三生缘自2010年开始成立红木工厂，以清朝家具为蓝本，自主生产古典家具。我们也珍藏了许多体现“中西合璧”风格的中式古典家具。`,
      description2B: ``,
    },
    featured: {
      title: (
        <h1>
          三生缘
          <br />
          家具收藏
        </h1>
      ),
      description:
        '三生缘文化艺术中心收藏许多各个时期的桌椅，呈现出中式家具最地道正统的工艺技术。',
      items: [
        {
          title: '椅子',
          items: [
            {
              itemTitle: '大叶紫檀及卷书式太师椅',
              itemSubtitle: '仿清家具',
              itemDescription:
                '本品是清式家具之经典，体型大，用料粗，线条流畅且不乏力度。搭脑以卷书形式呈现，角度适宜，靠背处浮雕福庆有余纹，寓意吉祥，椅子与茶几的牙板浮雕卷草纹，靠背、扶手攒雕花框，腿部内翻马蹄，更显大气庄重。',
            },
            {
              itemTitle: '小叶紫檀中堂太师椅三件',
              itemSubtitle: '明朝',
              itemDescription:
                '在传统家居的布局中，厅堂布局是最为讲究及严格的，常以体型较大、工艺最优的家具呈现。此套家具通体由小叶紫檀制成，体形端庄典雅，雕饰繁而不乱，靠背仿青铜器风格，雕以传统饕餮纹样，背板两侧以拐子龙纹样使背板与扶手相连，工艺细腻，木材处理方中有圆，座板另起平面阳刻，极尽工艺，四足承托泥，尽显大气，桌椅风格和谐一致，整套家具浑然一体。',
            },
          ],
        },
        {
          title: '橱柜',
          items: [
            {
              itemTitle: '小叶紫檀及香几',
              itemSubtitle: '仿清家具',
              itemDescription:
                '本品通体由小叶紫檀制成的，体量虽小，用量却十分讲究，五腿呈 S 形，足底镂空雕花，有球形连接托泥，束腰处起阳线，牙板雕西番莲，线条优美，工艺精美。',
            },
            {
              itemTitle: '非小叶紫檀四拼博古架',
              itemSubtitle: '明朝',
              itemDescription:
                '非洲小叶紫檀俗称血檀，是产自非洲的一种硬木，以赞比亚产为最优。此种木材与印度小叶紫檀性极相似，色泽端庄典雅，木质细腻。此品通体从外观上分四个区域，实为一体，四个区域分部合理，尤其是主体部分，分隔科学，兼美学与实用，每层之间的板面以暗枨工艺制作，更加美观，束腰内洼，整体更加秀美而富有变化，是家居中的极佳选择。 ',
            },
          ],
        },
      ],
    },
  },
  visit: {
    hero: {
      title: '造访我们',
      content:
        '诚挚邀情您至三生缘文化艺术中心探索历史悠久的中国艺术文物，以及浏览我们的茶叶、瓷器、艺术和家具收藏。',
    },
    location: {
      title: '地点',
      address: '加拿大卑诗省温哥华 Howe 街 420 号',
      openingHours: '开放时间',
      hours: '周一至周五早上 10 时半至晚上 6 时半',
      hours2: '周六早上11时至晚上6时',
      contact: '联系方式',
      phone: '电话',
      email: '电邮',
      imageCaption: 'Location Map',
    },
  },
  about: {
    timeline: {
      title: '地图',
      subtitle: '过去 18 年，三生缘持续致力於推广中国文化和艺术。.',
      points: {
        2002: '三生缘创立于中国山东省青岛市',
        2003: `三生缘开始收藏独门国画和字画`,
        2010: '三生缘开始成立红木工厂，自主生产古典家具',
        2018: '三生缘在加拿大温哥华开设第一间国际文化艺术中心',
      },
    },
    hero: {
      title: '关于三生缘',
      subTitle:
        '自 2002 年以来，三生缘致力于收藏和展销传统中国家具、字画和其他各种艺术作品。',
    },
    story: {
      title: '三生缘的故事',
      subTitle: (
        <>
          推广中国传统
          <br />
          文化和艺术
        </>
      ),
      content:
        'S在中国沿海地区创立的三生缘，自2002年以来便致力将中国文化和艺术推展到世界各地。三生缘坚守“行三生诚信，做百年老店”的宗旨，期盼创建推广中华文化之风潮，吸引更多高人雅士共同投入，让中国传统艺术在世界各地继续传承。',
      imgLabel: '位在中国山东省青岛的第一个三生缘艺术中心a',
    },
    fullTextBlock1: '温哥华最超凡的中国文化艺术中心 —	三生缘',
    fullTextBlock2:
      '对于能与温哥华的艺术爱好者，分享我们对中国艺术的热爱，以及进一步的介绍博大精深的中国文化，令我们备感荣耀。',
    community: {
      title: (
        <>
          培育
          <br />
          艺术家的摇篮
        </>
      ),
      subTitle: (
        <>
          欢迎热爱艺术的
          <br /> 有志之士
        </>
      ),
      content:
        '不论是何种型态的艺术展览，由三生缘主办的活动，皆以促进国际合作为宗旨。来自世界各地的高人雅士，经常性地汇集于中国三生缘总部，共同分享他们对艺术的热诚。',
    },
    collection: {
      title: '加国首批典藏',
      subTitle: '三生缘首个高端赏藏平台已在温哥华隆重登场',
      content: `为了近一步推广中国艺术和文化，温哥华三生缘将典藏一批品味独特的中国传统家具、字画和正统茶具组，未来也将有现代艺术品的收藏计划。`,
      linkText: '造访三生缘文化艺术中心',
    },
  },
  home: {
    hero: {
      title: '生活即艺术',
      subTitle: '中华文化的归属',
    },
    about: {
      title: '关于我们',
      subTitle: '在温哥华正式开幕',
      content: `为宣扬博大精深的中华文化，三生缘选择温哥华作为推广基地，期盼以古典家具、茶品珍茗、名家书画、雅瓷、紫砂等艺术品，为本地艺坛掀开崭新章节，同时也为温哥华呈现一个高端杰出的艺术赏藏平台。`,
      linkText: '了解更多',
      imgLabel: '新开幕的温哥华艺术中心',
    },
    collection: {
      title: '三生缘典藏',
      subTitle: '传统中国文化艺品',
      content:
        '三生缘典藏的艺品包含足以代表中国文化的经典之作。例如传统的中式家具、罕见的普洱茶、金属和陶瓷茶具、景德镇名窑春风祥玉所生产的瓷器、以及出自名师之手的国画和字画等各种风格的典雅艺品，都在此齐聚一堂。',
      items: {
        tea: {
          title: '茶',
          subTitle: '普洱茶, 茶具',
        },
        art: {
          title: '艺',
          subTitle: '雕像, 国画和字画',
        },
        ceramics: {
          title: '陶瓷',
          subTitle: '主要瓷器来自景德镇的春风祥玉和御窑元华堂制瓷',
        },
        furniture: {
          title: '家具',
          subTitle: '传统和现代中式家具',
        },
      },
    },
    events: {
      title: 'Sunzen Events',
      subTitle: 'Exhibitions & Collaboration',
      content:
        'Along with an extensive collection of artwork and cultural pieces, SUNZEN Art Limited is also home to numerous exhibitions and events to foster the development of a community between artists, collectors and professionals.',
      viewEvents: 'View Events',
      upcoming: 'Upcoming Event',
      month: 'June',
      cardTopic:
        'Cocktail reception for the 8th Yishu Awards for Critical Writing on Contemporary Chinese Art',
    },
    fullTextBlock1:
      'A renowned collection of art and culture that naturally reflects the rich traditions of china.',
    fullTextBlock2: 'Discover a new world of traditions',
  },
  events: {
    hero: {
      title: 'Sunzen Events',
      content:
        'Along with an extensive collection of artwork and cultural pieces, SUNZEN Art Limited is also home to numerous exhibitions and events to foster the development of a community between artists, collectors and professionals.',
    },
    content: {
      upcoming: 'All Upcoming Events',
      description: 'Lorem Ipsum Dolor Sit Amet',
      events: [
        {
          day: '1',
          month: 'June',
          topic:
            'Cocktail reception for the 8th Yishu Awards for Critical Writing on Contemporary Chinese Art',
        },
      ],
    },
  },
  privacy: {
    title: 'Privacy Policy/Terms of Use',
    description:
      'SUNZEN Art Limited and each of our affiliates and partnerships is committed to maintaining the accuracy, confidentiality and security of your personal information. This Privacy Policy describes the personal information that SUNZEN collects from or about you, how we use that information, and to whom we disclose that information.',
    sections: [
      {
        title: 'Collecting Personal Information',
        description:
          'SUNZEN collects personal information to better serve your needs, whether purchasing products and services from us or obtaining service or warranty work. In order to provide you with our wide variety of services, products and promotions, we may collect the following information:',
        list: [
          'Contact and mailing information such as name, address, telephone numbers, fax numbers and email addresses.',
          'The plans, specifications and other particulars about the home that is being built for you.',
          'Municipal and legal descriptions for you home.',
          'Any other relevant information necessary to provide you with our products and services.',
          'We may need to collect other personal information depending on the circumstance, but we will ask you directly and ensure that you agree.',
        ],
      },
      {
        title: 'Personal Information Security',
        description:
          'Like you, SUNZEN values our personal information. Part of valuing your personal information is making sure that it is protected and kept confidential. We achieve this by:',
        list: [
          'Having our employees sign a confidentiality agreement.',
          'Having up to date computer security such as passwords, encryption and firewalls.',
          'Where third parties are hired to assist us with providing products and services to you for your home, we have them sign a privacy and confidentiality agreement to ensure that your personal information is protected by them.',
        ],
      },
      {
        title: 'Personal Information Updating',
        description:
          'SUNZEN wants to ensure that your personal information is always correct up to date. If your personal information changes or is incorrect, updates can be made by contacting your representative with the change and any other information you feel is necessary.',
        description2:
          'We will keep your information as long as is necessary to continue to meet your customer needs and any legal requirements we may have. However, once your personal information no longer needed, we will destroy it.',
      },
    ],
    personalData: {
      title: 'Personal Data',
      content: [
        'We only collect Personal Data from you, such as first and last name, phone number, e-mail, when you register to receive information. We collect data about your activities that does not personally or directly identify you when you visit our website, or the websites and online services where we display advertisements. This information may include the content you view, the date and time that you view this content, the products you purchase, or your location information associated with your IP address. We use the information we collect to serve you more relevant advertisements (referred to as "Retargeting"). We collect information about where you saw the ads we serve you and what ads you clicked on.',
        'As you browse this website, online ad networks we work with may place anonymous cookies on your computer, and use similar technologies, in order to understand your interests based on your (anonymous) online activities, and thus to tailor more relevant ads to you. If you do not wish to receive such tailored advertising, you can visit this page to opt out of most companies that engage in such advertising. ( This will not prevent you from seeing ads; the ads simply will not be delivered through these targeting methods. )',
        'We do not target ads to you based on sensitive personal data, such as information related to race or ethnic origin, political opinions, religious beliefs or other beliefs of a similar nature, trade union membership, physical or mental health or condition or sexual life. We may target ads based on data provided by Advertisers or partners alone or in combination with the data we collect ourselves. Any data used to serve targeted advertisements is de-identified and is not used to personally or directly identify you. In order to allow us to reach the best inventory online, we work with third party advertising companies (our "Advertising Partners") to help us recognize you and serve relevant advertisements to you when you visit a website or online service in their network. We also work with Advertising Partners who help us recognize you across different devices in order to show you relevant advertisements. Our Advertising Partners may collect information about your activities on our website, and other websites or online services in their networks. We also work with third party companies to assist us with website analytics such as evaluating the use and operation of our website so that we can continue to enhance the website and our services.',
        `We and our third-party partners use session cookies and persistent cookies to make it easier for you to navigate and enhance the experience of our site, and to monitor and evaluate our website's operation and use.`,
        'To modify or delete your personal information, or to opt out of future communications, please contact us at:',
      ],
      addressTitle: 'Sunzen Art Limited',
    },
  },
};
