import React, { Component } from 'react';
import styled from 'styled-components';
import Intl from 'src/components/Intl';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getEvents } from 'src/redux/events';

import events from 'src/data/events';

import media from 'src/styles/media';
import { vw, vwTablet, vwMobile } from 'src/styles/utils';

import EventImageCard from 'components/videoPage/EventImageCard';
import BackgroundBlock from 'components/videoPage/BackgroundBlock';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

class Single extends Component {
  state = {
    event: null,
    indexOfEvent: null,
  };

  componentDidMount() {
    if (this.props.events.length === 0) {
      this.props.getEvents();
    }
    const { match } = this.props;
    const { id } = match.params;

    const event = this.props.events.find(event => event.post_name === id);
    const indexOfEvent = events.indexOf(event);
    this.setState({ event, indexOfEvent });
  }

  componentDidUpdate() {
    if (this.props.events.length !== 0 && !this.state.event) {
      const { match } = this.props;
      const { id } = match.params;

      const event = this.props.events.find(event => event.post_name === id);
      const indexOfEvent = events.indexOf(event);
      this.setState({ event, indexOfEvent });
    }
  }

  render() {
    // console.log(this.state.event);
    return (
      <Intl>
        {(t, { locale }) => {
          return (
            <Root>
              {this.state.event && (
                <BackgroundBlock
                  backgroundImage={require('src/assets/images/events/marble-bg.png')}
                >
                  <Container>
                    <h1 className="mobile-title">
                      {locale === 'zh' && this.state.event.acf.zh_title
                        ? this.state.event.acf.zh_title
                        : this.state.event.post_title}
                    </h1>
                    <div className="content">
                      <h1 className="desktop-title">
                        {locale === 'zh' && this.state.event.acf.zh_title
                          ? this.state.event.acf.zh_title
                          : this.state.event.post_title}
                      </h1>
                      {this.state.event.acf.ticket_link && (
                        <a
                          href={this.state.event.acf.ticket_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Get Tickets
                        </a>
                      )}
                      {locale === 'zh' &&
                      this.state.event.acf.zh_description ? (
                        <p className="description">
                          {this.state.event.acf.zh_description}
                        </p>
                      ) : (
                        this.state.event.acf.description
                          .split('<br />')
                          .map((item, index) => (
                            <p key={index} className="description">
                              {item}
                            </p>
                          ))
                      )}
                    </div>
                    <div className="card">
                      <EventImageCard
                        imageSrc={this.state.event.image}
                        day={this.state.event.acf.date.split('/')[0]}
                        month={
                          months[
                            this.state.event.acf.date
                              .split('/')[1]
                              .replace('0', '') - 1
                          ]
                        }
                        topic={this.state.event.acf.location}
                        linkTo={`/events/${this.state.event.post_name}`}
                        linkText={t.product.learnMore}
                        noLink
                        time={`${this.state.event.acf.start_time} - ${
                          this.state.event.acf.end_time
                        }`}
                      />
                    </div>
                  </Container>
                </BackgroundBlock>
              )}
            </Root>
          );
        }}
      </Intl>
    );
  }
}

const mapStateToProps = state => ({
  events: state.events.posts,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getEvents,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Single);

const Root = styled.div`
  color: white;
  font-family: ${({ theme }) => theme.fontPrimary};
`;

const Container = styled.div`
  margin-top: ${vwMobile(104)};
  padding: 0 ${vwMobile(20)};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: ${vwMobile(100)};
  @media (min-width: ${media.xsup}) {
    margin-bottom: ${vwTablet(25)};
    margin-top: ${vwTablet(160)};
    padding: 0 ${vwTablet(25)};
    flex-direction: row;
  }
  @media (min-width: ${media.mdup}) {
    margin-bottom: ${vw(25)};
    margin-top: ${vw(220)};
    padding: 0 ${vw(210)};
  }
  .content {
    order: 2;
    @media (min-width: ${media.xsup}) {
      width: ${vwTablet(357)};
      order: 1;
    }
    @media (min-width: ${media.mdup}) {
      width: ${vw(500)};
    }
  }
  .card {
    order: 1;
    > div {
      width: 100%;
    }
    @media (min-width: ${media.xsup}) {
      width: ${vwTablet(300)};
      order: 2;
      > div {
        width: 100%;
      }
      img {
        max-height: ${vwTablet(250)};
      }
    }
    @media (min-width: ${media.mdup}) {
      width: ${vw(386)};
      img {
        max-height: ${vw(350)};
      }
    }
  }
  a {
    font-family: ${({ theme }) => theme.fontSecondary};
    font-weight: 500;
    font-size: ${vwMobile(11)};
    letter-spacing: ${vwMobile(1.6)};
    text-transform: uppercase;
    color: white;
    margin-top: ${vwMobile(44)};
    display: block;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(11)};
      letter-spacing: ${vwTablet(1.6)};
      margin-top: 0;
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(11)};
      letter-spacing: ${vw(1.6)};
    }
  }
  h1 {
    color: white;
    position: relative;
    text-transform: uppercase;
    font-size: ${vwMobile(20)};
    line-height: 1.4;
    letter-spacing: ${vwMobile(1.4)};
    margin-bottom: ${vwMobile(32)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(28)};
      line-height: 1.46;
      letter-spacing: ${vwTablet(2)};
      margin-bottom: ${vwTablet(44)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(28)};
      line-height: 1.46;
      letter-spacing: ${vw(2)};
      margin-bottom: ${vw(44)};
    }
    &::after {
      content: '';
      width: ${vwMobile(30)};
      height: ${vwMobile(2)};
      background-color: ${({ theme }) => theme.colorRed};
      position: absolute;
      bottom: ${vwMobile(-5)};
      left: 0;
      @media (min-width: ${media.xsup}) {
        width: ${vwTablet(30)};
        height: ${vwTablet(2)};
        bottom: ${vwTablet(-5)};
      }
      @media (min-width: ${media.mdup}) {
        width: ${vw(30)};
        height: ${vw(2)};
        bottom: ${vw(-5)};
      }
    }
  }
  p.description {
    color: white;
    font-size: ${vwMobile(14)};
    line-height: 1.71;
    margin-top: ${vwMobile(32)};
    font-family: ${({ theme }) => theme.fontSecondary};
    font-weight: 300;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(15)};
      line-height: 1.65;
      margin-top: ${vwTablet(32)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(15)};
      line-height: 1.65;
      margin-top: ${vw(32)};
    }
  }
  .mobile-title {
    @media (min-width: ${media.xsup}) {
      display: none;
    }
  }
  .desktop-title {
    display: none;
    @media (min-width: ${media.xsup}) {
      display: block;
    }
  }
`;
