import React from 'react';

export default {
  switchLang: '中文',
  footer: {
    address: '420 Howe St, Vancouver, BC',
    hours: 'Monday – Friday: 10:30 AM – 6:30 PM | Saturday: 11:00 AM – 6:00 PM',
    menu: [
      'Tea',
      'Art',
      'Ceramics',
      'Furniture',
      'Collection',
      'About',
      'Events',
      'Visit',
    ],
    commercial: 'For Commercial Cooperation',
    privacy: 'Privacy Policy',
    terms: 'Terms of Use',
    copyright: 'Copyright © 2019 Sunzen',
    subscribe: 'Subscribe',
    signup:
      'Sign up to our newsletter to be notified of upcoming events, exhibitions and new additions to our collation.',
    yourEmail: 'Your Email',
    success: 'Thank you for subscribing.',
    error: 'There was an error in signing up, please try again.',
    alreadySubscribed: 'Error: This email is already subscribed.',
  },
  menu: {
    gallery: {
      title: 'Sunzen Art Gallery',
      links: ['Collection', 'About', 'Events', 'Visit'],
    },
    collection: {
      title: 'The Collection',
      links: ['Tea', 'Art', 'Ceramics', 'Furniture'],
    },
    bottom: ['Privacy Policy', 'Terms of Use'],
  },
  product: {
    discover: 'Discover Our Other Specialties',
    title: 'Explore The Collection',
    menu: ['Tea', 'Art', 'Ceramics', 'Furniture'],
    learnMore: 'Learn More',
    featured: {
      discover: 'Discover our full collection at the Sunzen Gallery',
      visit: 'Visit Us',
    },
    readMore: 'Read More',
    close: 'Close',
    select: 'Select One',
  },
  tea: {
    overview: {
      title: 'The Origins of Tea',
      description:
        'It is said that tea we know today was first discovered almost 3000 years ago by the Chinese ancestor Shennon. The legend of discovery tells us that he preferred drinking boiled to ensure it wouldn’t make him ill, and while on a trip to a distant region, a dead leaf from a wild tea bush fell into his boiling drinking water, Shennon drank it and found it very refreshing, and so cha (tea) came into being.',
    },
    section1: {
      title: 'Tea in China',
      description1A:
        'Originating in China, today tea has spread around the world, and what began as a preferred drink for a single emperor has become one of the most popular beverages for millions. Traditional Chinese tea is generally classified into six categories: white, green, oolong, yellow, black and post- fermented, with additional categories for scented and compressed teas, but due to the variations of the Camellia Sinensis plant, researchers estimate there are over a 1000 variations of these categories.',
      description1B: '',
    },
    section2: {
      title: `Pu’Er tea: One of China’s great treasures`,
      description2A: `Grown and produced in the Yunnan province, Pu'Er or Pu-Erh tea is also often called vintage or aged tea and is one of China's great treasures. While other teas have become widely known, Pu'Er tea has only risen to prominence in recent years. Unlike other teas, high-quality Pu’Erh not only offers distinctive flavours, many avid drinkers say it provides an overall sense of well-being that they call “Qi”, which means “the flow of energy."`,
      description2B: '',
    },
    featured: {
      title: (
        <h1>
          The Sunzen
          <br />
          Tea Collection
        </h1>
      ),
      description:
        'Along with metallic and clay teapots, our collection of tea related pieces includes some of the finest vintage Pu’Er teas. With an extensive history of discovering, experiencing, and collecting teas, we can help you find some of the most highly sought-after Pu’Er teas in the world.',
      items: [
        {
          title: 'Clay Teapots',
          items: [
            {
              itemTitle: 'Chinese Drum Tea Pot',
              itemDescription:
                'One view holds that it was originally created by Shao Daheng in Qing Dynasty; it originally means that the pot body is modelled after the shape of a drum; people in later generations modelled after the shape of this pot, thus its name meaning imitating the shape of antique pot. Another view holds that it was created by Zhao Songting under the instruction of Wu Dazheng. This pot has a flat body, bulged abdomen, tall neck and smooth lid plate; its lid matches tightly with the mouth along the standard line; they constitute a full round line; the flowing at the second bending is natural and unblocked. The circular handle is created in balance, and the lobe under it increases the vividness of this work; the whole pot is in an even and harmonious structure; the self-indented bottom highlights the wholeness of this pot.',
            },
            {
              itemTitle: 'Brick-square Tea pot',
              itemDescription:
                'A piece of violet sand-fired pot pleasing to the eye can show great line art, while the smooth, natural and proper lines function as important factors in appreciation of violet sand-fired pots. The violet sand-fired pots enjoy rich and varied shapes; on the basis of different forms and features, they can be divided into three categories of round ware, square ware and veined ware. Due to the particular shape of the square ware, the difficulty in its production and burning is great; in the industry, it is often said that "one square ware is worthy of ten round wares". This pot integrates the straightness and firmness of square wares and some factors of round wares in the processing of details, thus a perfect integration of the square and round wares, as well as hardness with softness.',
            },
            {
              itemTitle: 'bowl-shaped hoop-handled pot',
              itemDescription:
                'The hoop-handled pot are based on the shape of alms bowl used in Buddhism and configured with a spout and hoop handle, and was created by the famous master of violet sand art Chen Guangming in the late Qing Dynasty and early Republic of China; it was imitated by many people in later generations. This pot has a tall and straight hoop handle, and a round and smooth bridging button, which provide a good hand feeling; the pot body is round, full and delicate, in well-developed structure, and as smooth as jade.',
            },
            {
              itemTitle: 'Three-foot Ding-shaped Pot',
              itemDescription:
                'It is lucky for Sanshengyuan to collect this "Three-foot Ding-shaped Pot". Yu Qiuyu commented that "although the pot is not large, it is eye catching if it is put among others for exhibition; its roundness, reservation and perfection are supreme and hard to be copied --- just like all other supreme things, it possesses an air that everything is in its control."',
            },
            {
              itemTitle: '',
              itemDescription:
                'With primitive simplicity and purity, and free from gaudy colors and vulgarity, the violet sand-fired potteries share similar temperaments with scholars, thus beloved by them; the scholars took the green body of these potteries as a kind of paper, inscribed either introduction words for the pot, or personal name, or flower patterns, or seals on it to express their ideas; many of these designs were very ingenious. In the Ming and Qing Dynasties, some scholars loved to make poems and paintings, and inscribed their poems on a pot for the commemoration of a certain event; they also left a message in the inscriptions to present such pots as gifts. At that time, Chen Mansheng and Ziye were the most talented ones, and they promoted the development of violet sand-fired pottery engraving art, and popularized the saying that "the calligraphy circulates with the pot, while the pot is increased in value with the calligraphy."',
            },
            {
              itemTitle: 'Pear-shaped Tea Pot',
              itemDescription:
                'This pot, well-developed and full, is made with strict craftsmanship in proper kiln temperatures; upon its usage with tea water, it can become more likeable; with beautiful curves, it is a tea ware of great practicability.',
            },
          ],
        },
        {
          title: 'Metal Teapots',
          items: [
            {
              itemTitle:
                'gold-inlaid pine patterned button pot made with copper wrapped in silver',
              itemDescription: `An Zhijie's work "gold-inlaid pine patterned button pot made with copper wrapped in silver" is now collected by Sanshengyuan; with button in gold, inner container in silver, body in copper, and hoop handle in iron and tin, this work involves crafts on engraving, painting, calligraphy, and inlaying; on the inner container, there are engraved designs, and exquisitely carved pines and elegant inscriptions; it integrates the five materials of gold, silver, copper, iron and tin, unparalleled and exquisite crafts, elegant air of scholars, and century-old historical inheritance into a unity, and can be regarded a consummate iron pot.`,
            },
            {
              itemTitle: 'Graupel Pattern golden metal pot',
              itemDescription: `Graupel pattern is the most important decoration and also the most complicated decoration for Japan's traditional metal pots; the craft of creating graupel pattern can be traced back to the artisan craftsmanship during Edo period in Japan; such a craft was taught by a master to his apprentice via demonstrating and oral teaching; it is necessary to remember the hammering strength through the swing amplitude of one's body; the graupel points gradually increase in size from the mouth to the bottom of the pot; during the hammering, the operator depends only on his hand feeling to control, which demands very sophisticated skills.`,
            },
            {
              itemTitle: 'Yamada Family',
              itemDescription:
                'Yamada Family was a well-known family in metalsmith from the late Shogunate period to the Taisho period; Yamada Munemitsu, born in the 2nd year of Tempo period, was its successor in the ninth generation. Yamada Munemitsu inherited the inlaying technique of his father, learned forging techniques in Edo, and was good at metal work with copper, silver and iron as the material; in his works, he could give natural expression to the beauty of a single metal and also could perfectly integrate two or above metals for the presentation of another texture and charm; this work is classic in such a style.',
            },
            {
              itemTitle: 'Silver Pot with Beast mouth and jade ring',
              itemDescription:
                'The body of this pot is made with pure material, in a stately and elegant shape, and in full expression to the exquisiteness of the lost wax process. In the original wooden box, it was recorded the inspiration to create this silver pot, and his inheritance of and admiration to ancient Chinese crafts culture. The lid is configured with a white jade button in hollow engraving; moreover, this button is modelled ingeniously after the eaves tile of Han Dynasty, and inscribed the four characters in seal script as "长乐万岁" (longevity with a contented mind); the design of mouth in the shape of a beast increases the antique flavor of this pot; standing in front of the pot, you will feel a strong sense of "Chinese charm".',
            },
            {
              itemTitle: 'Iron Tea Pot with emboss',
              itemDescription:
                'Hatano Shohei learned high-level wax casting method at Tatsufumido; therefore, in the current works of Kamebundo, the wax casting method functions as the main production method, and this ware is a representative work of wax casting method. In addition to the application of wax casting, works created by Kamebundo also share the feature that the pot body possesses multiple carving ornaments; its works were widely praised  for the improvement of S-shaped dismountable handle.',
            },
          ],
        },
        {
          title: 'Tea',
          items: [
            {
              itemTitle: 'SUNZEN Puer’ Tea, made in 2013.',
              itemDescription:
                'With high-quality pollution-free fresh leaves collected on the tea mountain of Fengqing, Yunnan in 2011 as the raw material, Sanshengyuan Commemorative Tea was pressed into tea cakes in 2013; it is characterized by varied appeals, strong tea vigor, tea water with clean aroma, and sweet taste mixed with little bitterness; after drinking the tea, its flavor turns from bitterness to sweetness; since it is made with selected fresh tea leaves and processed with superior craftmanship, the quality of this raw tea cakes can increase as time goes.',
            },
            {
              itemTitle: 'Tongqing Puer’ Tea, made in 1910’s',
              itemDescription: `Tongqing Tea House had established the primary tea factory in 1736; in 1920, the Neipiao and Neifei (two kinds of small inserts put in the package of tea cakes to indicate the information of the tea cakes) printed with the "dragon-horse trademark" of Tongqing Tea House were changed into those marked with "double-lion-and-flag pattern"; the Pu'er tea produced during the two stages is quite cherished by the lovers of Pu'er tea. Its tea strip is flat and long, has glossy luster, and smells the fragrance of superior wild camphor; its tea water is round, thick and smooth, tastes a little bit sweet, and can nourish the throat; it also can produce strong tea vigor, and promote the secretion of saliva from the tongue base, thus can be regarded as the highest grade in Pu'er tea.`,
            },
            {
              itemTitle: '“Cultural Revolution” Puer’ Teas',
              itemDescription:
                'Cultural Revolution Brick Teas: they were manufactured in the 1960s and 1970s, and the tea water made thereof is bright and transparent, and takes on the primary color of red mixed with amber gold, which is very vivid. It possesses a full flavor, smells a thick fragrance, and tastes mellow and smooth. At the same time, due to the special cultural background and historical value of Cultural Revolution Bricks, they have always been a hot cake for many tea lovers. It is a pity that few Cultural Revolution Brick Teas survive in current society, and thus they are cherished now like a rarity of the rarities.',
            },
            {
              itemTitle:
                'Red Print-grade Puer tea made in 1950s (without wrapper)',
              itemDescription:
                'Print-grade Round-cake Teas possess a long development history and share an overall high quality; among them, those with red-print in complete package and red-print without package enjoy particularly a high quality. The raw materials for these round-cake teas were mainly collected from the big fresh leaves on Yiwu Mountain, Banzhang Mountain, Bada Mountain and Bulang Mountain in Xishuangbanna; moreover, these teas have been stored for fifty or sixty years, and thus feature full and thick quality, and deep appeal. The round-cake teas with red-print and without packages made during the 1950s exist now in a small amount, and have gradually disappeared in the circulation of market; thus it is hard to find this tea now. ',
            },
            {
              itemTitle: 'Red Print-grade Puer tea made in 1950s',
              itemDescription: `The fresh leaves used for the production of red-print round-cake teas in the 1950s were collected from Yiwuzheng Mountain, one of the top six tea mountains. Its tea cake features thickness and glossy surface; the tea strip is flat and long; the tea water is bright red, and tastes mellow and smooth; the tea-leaf is rich in elasticity and smells a light fragrance of camphor; it also can produce strong tea vigor, and thus, ranks as the classic among print-grade Pu'er teas.`,
            },
            {
              itemTitle: 'Blue-Print-grade Puer tea made in 1950s',
              itemDescription: `In the era of print-grade tea production, there were "blue-print grade A" and "blue-print grade B" tea products among the blue-print round-cake teas under the China Tea brand; their names were derived from the grade A or grade B printed on their own package. The blue-print round-cake teas were manufactured in the 1950s; since they were made with strictly and finely selected as well as clearly intact fresh leaves and have been stored in an environment with proper humidity and temperature for a long time, their cakes take on a glossy look, and the tea water made thereof feature bright color, pure fragrance, and mellow taste, and therefore, they can be reputed as the quality goods among print-grade round-cake teas.`,
            },
            {
              itemTitle: 'Blue-Print-grade Puer tea made in 1950s',
              itemDescription: `In the early 1950s, in order to expand production, China Tea Co., Ltd. adopted the new metal molding process to replace the traditional cloth bag molding process, thus producing compact and firm tea cakes; the package of these cakes was similar to that of blue-print round-cake teas, thus being called as blue-print iron-mold round-cake teas. The blue-print iron-mold round-cake teas were made with finely selected materials and have been stored for decades of years in an environment with proper humidity and temperature, thus possessing a superior quality. `,
            },
          ],
        },
      ],
    },
    lessons: {
      title: 'Gongfu Tea Ceremony',
      subtitle: 'Overview of a',
      steps: [
        {
          title: 'Clean the set',
          description:
            'The traditional tea set contains 1 serving cup, multiple tea cups and 1 tea pot. The tea sets need to be thoroughly cleaned, so the scent from the previous batch will not carry over.',
        },
        {
          title: 'Put in the Tea Leaves',
          description:
            'Tea Leaves could easily absorb the scent from anything they are in contact with, therefore the leaves need to be put into the tea cups with a tea spoon. The finer tea leaves should be put at the bottom of the tea pot for easy pouring.',
        },
        {
          title: 'Boil Water',
          description:
            'For the best result, the tea master will need to bring the water to a precise temperature. There are also three stages when it coming to water boiling; only using the hot water at the right stage can bring out the best taste in the tea leaves.',
        },
        {
          title: 'Pour Hot Water',
          description:
            'When the hot water is finally ready, it needs to be poured into the pot. This process needs to be done carefully. The hot water needs to be poured into the pot not directly on to the tea leaves but along the edge of the pot.',
        },
        {
          title: 'Scrape off',
          description:
            'When pouring the water into the pot, bubbles and some small tea leave chips will make their ways to the surface of the water. They will affect the taste of the tea, and the tea master will often use a special utensil to scrape them off.',
        },
        {
          title: 'Tea Pot Showering',
          description:
            'After closing the lid, the tea master will pour more hot water on top to the entire tea pot. The purpose of this step is to bring up the temperature again, allowing the tea leaves to release the scent to the extent.',
        },
        {
          title: 'Cup Warming',
          description:
            'The cups need to be at the right temperature before the tea gets pour into them, this is very important as the tea needs to be serve at the best condition. Tea master will use the reminder of the hot water to give tea cups a good rinse.',
        },
        {
          title: 'Tea Pouring',
          description:
            'Finally, the perfectly made tea will be ready for pouring and to be enjoyed. The technique here is to “pour in circles”. Tea master will put the cups together and go around them in motions of circles when pouring, typically 7 to 8 rounds. Filling up a cup in one-time pour is generally not allowed.',
        },
      ],
    },
  },
  art: {
    overview: {
      title: 'Chinese Art',
      description: `As a practised form of artistic expression, China’s art has the oldest continuous tradition in the world with the earliest paintings dating to the Han Dynasty in 200 BC. Today, traditional Chinese painting follows two main techniques. Gongbi, which means "meticulous", uses finely detailed strokes, is highly colourized and usually depicts figural or narrative subjects. Ink and wash painting, shuǐ-mò, which means "water and ink" is similar to western watercolours.`,
    },
    section1: {
      title: 'Painting & Calligraphy in China',
      description1A: `Calligraphy and painting in China are historically related due to the techniques and tools used in their creation. Like paintings of landscapes and figures, calligraphy is regarded as an art form where the expression of symbols is highly dependent on the skill and intent of the artist.`,
      description1B: ``,
    },
    section2: {
      title: `Art at Sunzen`,
      description2A: `The SUNZEN collection features the works of some of China’s most prominent and renowned artists. Working closely with artists and collectors around the world, one of the founding principles of the gallery is to collectively further the creation of an international Chinese art community.`,
      description2B: '',
    },
    featured: {
      title: (
        <h1>
          The Sunzen
          <br />
          Art Collection
        </h1>
      ),
      description:
        'Exhibiting a wide range of traditional Chinese paintings, and an extensive collection of calligraphy and sculptures from the 20th century, the art works are presented in a variety of media and styles, and the collection encompasses the rich diversity and traditions of Chinese art.',
      items: [
        {
          title: 'Paintings',
          items: [
            {
              itemTitle: 'Red Bamboo, by Gong Qi',
              itemDescription: `Mr. Qi Gong was a famous calligrapher and connoisseur of calligraphy and painting in China, and gained a world reputation with his calligraphic works. Although he didn't create a large quantity of paintings, the existing ones can fully tell his sophisticated skills and unique styles. The "red bamboo" in his paintings has been a topic widely talked in the circle of calligraphy and painting; the "red bamboo" he painted is vigorous and unrestrained, a reflection of Mr. Qi Gong's innovation spirit in paintings.`,
            },
            {
              itemTitle: 'Two chock rest under a plum, by Mr. Xuetao Wang',
              itemDescription:
                'Mr. Wang Xuetao was a master with great accomplishments in bird-and-flower painting in China, and was well-known in the circle of painting for his small freehand brushwork flower-grass-insect paintings. His paintings feature unrestrained and unusual ink-and-brushwork, and abound in interest. This painting is fresh, varied, vivid, and lovely, and highlights the artistic beauty and exuberant vigor.',
            },
            {
              itemTitle: 'Landscape figure, by Mr. Daqian Zhang',
              itemDescription:
                'This painting features simple and unrestrained brush wielding, ethereal and meaningful artistic conception, natural configuration, pure and graceful splashing of ink. The tint of ink is applied to present the layers of remote mountains; in the middle part, the white space highlights the purity and emptiness of the scene; in the nearby view, it includes mountains and rocks, and a profound scholar overlooking the sails on the river; the idyllic ideas of the painter stand vividly revealed on the paper.',
            },
            {
              itemTitle: 'magpie, by Mr. Beihong Xu',
              itemDescription:
                'Magpie functions as a traditional painting object in Chinese paintings. Xu Beihong had created multiple magpie paintings and presented them to his friends, indicating good wishes such as "happiness appears on the eyebrows" and "triumphant news keeps pouring in". It is well-known that Xu Beihong devoted his whole life to improving Chinese paintings with the realistic spirit of western painting, and created many excellent works, especially those involved with galloping horses. However, many people are ignorant of Xu Beihong good at painting magpies. In this painting of Xu Beihong, the magpies painted are vivid, true to life and can lead the viewers to meaningful thoughts. ',
            },
            {
              itemTitle: '"Laozi Leaving from Hangu Pass"，by Mr. Fan Zeng',
              itemDescription:
                '"Laozi Leaving from Hangu Pass" is a representative subject in the paintings of Mr. Fan Zeng; the adoption of line drawing in traditional ink and brush style increases the vividness of the figure and describes well his sage-like features. The drawing lines are strong and powerful, a reflection of the sophisticate skills of the painter; the eyes of the figure are clear and profound, consistent with the teachings of Taoism. The two sides are painted with a couplet, which indicates the classical works of Laozi and his final destiny.',
            },
            {
              itemTitle:
                'Longevity Painting with a Mountain Scene, by Mr. Fan Zeng',
              itemDescription:
                'Longevity Painting with a Mountain Scene: Mr. Fan Zeng is good at figure painting, and especially those with children; in this painting, the child is charmingly naive, and the action of monkey plucking peaches is vivid and true to life; the composition of the whole picture is exquisite and delicate, moreover, the brushwork is impeccable! ',
            },
          ],
        },
        {
          title: 'Wood-carving',
          items: [
            {
              itemTitle:
                'Pierced-carving peace-for-progeny lobular red sandalwood brush pot',
              itemDescription:
                'Pierced-carving peace-for-progeny lobular red sandalwood brush pot is completely hand-made with high-quality lobular red sandalwood under the pieced carving technique; all constituent parts are carved from the overall wood block; the body of the brush pot is entwined with vines of melons, symbolizing prosperity, safety and fortune for progenies.',
            },
            {
              itemTitle: 'the four great beauties',
              itemDescription:
                'This article was carved by Master She Guoping with the boxwood; the boxwood has a long growing period and thus the title of millennial boxwood; its timber is firm, delicate and in order, and very proper for the carving of characters. This set features meticulous craftsmanship; the bearing, hair ornaments, clothing, and other details just right project the characteristics under the background of the times. ',
            },
            {
              itemTitle: 'Wood carving #3',
              itemDescription:
                'Silk-plume sandalwood carving is a kind of new high-end artistic handicraft, in which a fine brushwork painting is recreated and presented in the form of bas-relief on a piece of sandalwood in combination with traditional wood carving craftsmanship; the so-called "silk-plume" derives from the feather for the feather painting involves a category of techniques among the Chinese elaborate-style paintings; in the silk-plume sandalwood carving, the carver obtains its shape by external observation, creates its shape with lines, and uses its shape to deliver ideas, realizing the perfect unity of form and spirit; while it retains the characteristics of Chinese elaborate-style paintings such as fineness and meticulousness, and unity of form and spirit, it has radically broken through the limit of two-dimensional space of traditional Chinese paintings via the stereoscopic representation with the wood carrier, and is a great innovation in the wood carving industry. This article is produced under such a craftsmanship with the lobular red sandalwood as the carrier; the birds, branches and leaves, and creepers are all carved true to life.',
            },
            {
              itemTitle: 'Wood carving #4',
              itemDescription:
                'This article is completely made with the Tongan sandalwood; the Tongan sandalwood comes from the Kingdom of Tonga and is produced in a very small of quantity; its fragrance is mild and mixed with a slight sense of sweetness. The main part of this article is carved with a whole wood block; the Avalokitesvara in a semi-lotus position has the solemn dharma-laksana and is seated on the seawater and lotus; it is quite unique and outstanding; with smooth lines, grand modeling, and especially the fine processing in details, such as in the clothing and hair ornaments, it manifests the extraordinary skills of the craftsman.',
            },
          ],
        },
        {
          title: 'Statue',
          items: [
            {
              itemTitle: 'Buddha Statue',
              itemDescription:
                'The colored hand-painted Buddha statue is precisely casted into shape under the dewaxing method with the high-purity cooper material; and then we polish and embellish its surface to increase the compliance of the overall Buddha statue; on the surface, the colors are painted in fine brushwork to increase the sense of reality, and the gilding is protected by nano paint to make the color never fade; during maintenance, one can dip a piece of cotton cloth in the water, wring the water out of the cloth, and wipe the statue gently with the cloth so as to permanently keep the solemnity of the Buddha statue!',
            },
          ],
        },
      ],
    },
    lessons: {
      title: 'Artists at Sunzen',
      subtitle: 'Learn More',
      steps: [
        {
          title: 'Qi Gong',
          description: `Mr. Qi Gong was a famous calligrapher and connoisseur of calligraphy and painting in China, and gained a world reputation with his calligraphic works. Although he didn't create a large quantity of paintings, the existing ones can fully tell his sophisticated skills and unique styles. The "red bamboo" in his paintings has been a topic widely talked in the circle of calligraphy and painting; the "red bamboo" he painted is vigorous and unrestrained, a reflection of Mr. Qi Gong's innovation spirit in paintings.`,
        },
        {
          title: 'Zhang Daqian',
          description:
            'Mr. Zhang Daqian was a famous painter and calligrapher in China, and achieved exceptional attainments in mountain-and-water painting. In his paintings, he combined fine brushwork and freehand brushwork, integrated strong colors with ink, and created new artistic styles in splash-ink and splash-color.',
          subsection: {
            title: 'More Info',
            listItems: [
              'This painting features simple and unrestrained brush wielding, ethereal and meaningful artistic conception, natural configuration, pure and graceful splashing of ink. The tint of ink is applied to present the layers of remote mountains; in the middle part, the white space highlights the purity and emptiness of the scene; in the nearby view, it includes mountains and rocks, and a profound scholar overlooking the sails on the river; the idyllic ideas of the painter stand vividly revealed on the paper.',
            ],
          },
        },
        {
          title: 'Fan Zeng',
          description: `In the reform and development progress of Chinese figure painting during the late 1970s and early 1980s, Fan Zeng was a character who set the fashion. At that time, the painting works of Fan Zeng successfully conquered the whole circle of paintings, and his influence was even extended to Japan, thus he winning a large number of followers.`,
          subsection: {
            title: 'More Info',
            listItems: [
              'In the figure paintings, Fan Zeng got away from the fixed and politicized figure images in the 1950s and 1960s, selected ancient sages and cultural celebrities as his painting objects, adopted the ink-and-brush techniques used in traditional literati paintings, and firstly broke the ideological inclination shrouding Chinese paintings for above thirty years, and thus provided an opportunity for the aesthetic transformation in Chinese figure painting.',
            ],
          },
        },
        {
          title: 'Xu Beihong',
          description: `Mr. Xu Beihong was good at the painting of figures, animals, and flower-and-bird, advocated realism in painting, and greatly influenced the circle of painting at that time. In traditional Chinese paintings, he integrated colors with ink, especially exceptional in his painting of galloping horses.`,
          subsection: {
            title: 'More info',
            listItems: [
              'Magpie functions as a traditional painting object in Chinese paintings. Xu Beihong had created multiple magpie paintings and presented them to his friends, indicating good wishes such as "happiness appears on the eyebrows" and "triumphant news keeps pouring in". It is well-known that Xu Beihong devoted his whole life to improving Chinese paintings with the realistic spirit of western painting, and created many excellent works, especially those involved with galloping horses.',
            ],
          },
        },

        {
          title: 'Wang Xuetao',
          description:
            'Mr. Wang Xuetao was a master with great accomplishments in bird-and-flower painting in China, and was well-known in the circle of painting for his small freehand brushwork flower-grass-insect paintings.',
          subsection: {
            title: 'More Info',
            listItems: [
              'His paintings feature unrestrained and unusual ink-and-brushwork, and abound in interest. This painting is fresh, varied, vivid, and lovely, and highlights the artistic beauty and exuberant vigor.',
            ],
          },
        },
      ],
    },
  },
  ceramics: {
    overview: {
      title: 'Chinese Ceramics',
      description: `The history of earthenware in China can be traced from as early as the Palaeolithic era, and are today regarded as one of the most significant forms of Chinese art. As they developed over their long history from basic pottery to stoneware and finally the fine ceramics we see today, the artform has become so highly regarded around the world the ‘porcelain’ used in its manufacture is still referred to as "china" as a common descriptor.`,
    },

    section1: {
      title: 'Ceramics and Tea Sets in China',
      description1A: `The prominence of porcelain tea sets shares its history with tea itself. When the discovery of tea as we know it today was made during Han Dynasty 2000 years ago, it was accompanied by early vessels from which it was poured and sipped. Made from porcelain, the tea sets can be generally divided into northern and southern styles, with subtle colour variations originating from the contrasting geology of the two regions.`,
      description1B: `This distinction gave northern porcelain a distinctive white colour, and those originating in the southern regions a subtle shade of light blue. Along with these distinctions, in traditional tea sets there are no tea pots as the Chinese tea drinkers drank only the leaves, herbs, and flowers steeped in water.`,
    },
    section2: {
      title: `The chun feng xiang yu kiln`,
      description2A: `The renowned Chun Feng Xiang Yu workshop in Jingdezhen is regarded as one of the finest producers of porcelain in the world today.`,
      description2B: `Due to the fine craftsmanship of its wares, unmatched pigmentation, colouring, and artfully painted patterns, its collection of porcelain is sought-after by collectors and the value of have continuously risen in recent years.`,
    },
    featured: {
      title: (
        <h1>
          The Sunzen
          <br />
          Ceramic Collection
        </h1>
      ),
      description:
        'Along with a collection of ceramics and stoneware in the form of classical Chinese tea sets, traditional bowls and vases that reflect various styles and dynasties, SUNZEN also features a wide range of wares from the renowned Chun Feng Xiang Yu Workshop.',
      items: [
        {
          title: 'Ceramics 1',
          items: [
            {
              itemTitle: 'Beast-eared flat bottle',
              itemSubtitle: 'Ming Dynasty',
              itemDescription:
                'Beast-eared flat bottle: The blue-and-white porcelains made by imperial kilns in Qianlong period are different from the quiet and beautiful blue-and-whites in Kangxi period, and also distinguished from the elegant blue-and-white in Yongzheng period. This ware is in an oblate shape, with drooped abdomen and outward extended oval foot. Its mouth edge is painted with the interlock branch pattern, and its head is decorated with as-you-wish-shaped cloud, sea water and interlock branch patterns; on the two sides of the abdomen, with the interlock branch lotus pattern as the background, there are four spaces in oval, inside of which twisted-branch and flower-and-fruit pattern is painted; the foot is decorated with the interlock branch lotus pattern and sea water pattern. With a thick and dense body, simple style, grand ware shape, bright and mild glaze, vivid blue-and-white, ruled painting technique, strict and meticulous composition, this bottle gives full expression to the style favored by royal families.',
            },
            {
              itemTitle: 'Elephant-eared flat bottle',
              itemSubtitle: 'Tonga Sandalwood',
              itemDescription:
                'Elephant-eared flat bottle: This ware features a small and round mouth, fine and straight neck, flat abdomen, and oval-shaped foot; on the shoulder part, there are two elephant-head-shaped ears; its overall shape is tall and regular. The front and back sides of the abdomen are decorated with blue-and-white interlock branch lotus pattern; the middle of the abdomen is decorated with the longevity peach pattern; all these patterns are painted exquisitely. The ware shape is perfectly round and grand, a reflection of the royal family style and implication of happiness. The original ware is collected in the Shanghai Museum. ',
            },
            {
              itemTitle: 'Chicken-patterned cup',
              itemSubtitle: 'Ming Dynasty',
              itemDescription:
                'Chicken-patterned cup: The chicken-patterned cup has been praised highly for its novel style, fresh decoration and exquisite craftsmanship, and can be called as the classic among the clashing color wares of Chenghua period in Ming Dynasty. The texture of its body is pure white, delicate, soft, bright and smooth; stippling and dyeing techniques, etc. are applied in the coloring glaze; the colors are used in proper intensity, and allow the patterns to combine the simplicity and elegance with floridness.',
            },
            {
              itemTitle: 'Blue-and-white iron-red plum vase',
              itemSubtitle: 'Ming Dynasty',
              itemDescription:
                'Blue-and-white iron-red plum vase: This plum vase is in a tall shape and takes on the air of dignity and elegance; it has a wide flared mouth, short neck, full shoulders, and arced abdomen. Its head is decorated with banana leaf pattern, and its shoulders decorated with cloud pattern; in the abdomen, nine iron-red dragons flying in different postures are painted among the rolling blue-and-white sea water. With slender and smooth blue-and-white lines and vividly designed dragon patterns in dazzling colors, this ware implies good weather for crops and peaceful world for people.',
            },
            {
              itemTitle: 'Mountain-and-water patterned bowing cup',
              itemSubtitle: 'Ming Dynasty',
              itemDescription: `Mountain-and-water patterned bowing cup: it is in proper height and size, can gather fragrance and flavor, and is fit for the grasping of the hand; with the bowing line as the boundary, the colors and crafts respectively for the upper part and lower part are a little bit different but mutually blended, contributing to the fullness and richness of the whole pattern. The "bowing" in its name alludes to the story that Tao Qian in the Jin Dynasty "didn't bow to the superior in order to get the scanty pay".`,
            },
            {
              itemTitle: 'Rice-patterned blue-and-white pot',
              itemSubtitle: 'Ming Dynasty',
              itemDescription: `Rice-patterned blue-and-white pot: the bird-and-flower pattern follows the style of the famous senior craftsman Wang Bu, is elegant, beautiful and free from vulgarity; the extreme detailed painting of the pattern contributes to its unity of form and spirit. The pot is round and full, and in the traditional shape of a beauty's shoulder!`,
            },
            {
              itemTitle:
                'Beast-eared mountain-and-water patterned tea canister',
              itemSubtitle: 'Ming Dynasty',
              itemDescription: `Beast-eared mountain-and-water patterned tea canister: its lid is perfectly fit for the canister body; the mouth edge echoes with the neck part; mountain-and-water patterns with exquisite designing and rich connotations are painted on the body; the beast ears on its two sides add a sense of grandeur to the ware.`,
            },
            {
              itemTitle: 'Deer-head Zun',
              itemSubtitle: 'Ming Dynasty',
              itemDescription:
                'Deer-head Zun is a traditional type of porcelain ware and features a round and harmonious shape; there are valuable ones in blue-and-white or famille rose; this type of ware can be called an important article in the decoration of a hall.',
            },
            {
              itemTitle: 'Ju He Tang',
              itemSubtitle: 'Ming Dynasty',
              itemDescription:
                'This ware is a work of Ju He Tang; among the numerous splendid flowers, there are two windows, in which mountain-and-water patterns are painted; the whole decoration is classical and elegant, and well arranged, while the whole colors used are bright but not vulgar.',
            },
            {
              itemTitle: 'Hsiao Fang Kiln',
              itemSubtitle: 'Ming Dynasty',
              itemDescription:
                'Hsiao Fang Kiln is a well-known porcelain making kiln in Taiwan; among the various antique-imitation porcelains produced by it, the most characteristic one should be those modelled after the Song Dynasty Ru Kiln porcelains. This kind of porcelains once flourishing in the history give full expression to the particular aesthetic appreciation of the royal family of the Northern Song Dynasty in its pursue of the color similar to "that where the clouds disperse after a rain stops and the sun comes out". Irregular ice cracks loom in the jade-like gentle glaze, presenting miraculous artistic effects, and thus making people loves it upon seeing and reluctant to put it down. ',
            },
          ],
        },
        {
          title: 'Ceramics 2',
          items: [
            {
              itemTitle: 'Eighteen-scholar patterned tea cup',
              itemSubtitle: 'Ming Dynasty',
              itemDescription:
                'Eighteen-scholar patterned tea cup: this pattern derives from the eighteen scholars in the Literature House set up by Emperor Taizong of Tang Dynasty; in it, each character is vividly painted in their unique miens and posture, and seems to be able to get a life. The interior and exterior mouth edges and circular foot are decorated with double lines, strengthening the overall echoing and coordinative effect.',
            },
            {
              itemTitle: 'Peony patterned stationery accessories',
              itemSubtitle: 'Tonga Sandalwood',
              itemDescription:
                'Peony patterned stationery accessories: the peony implies riches and honor, and is one of the traditional patterns most commonly seen in various art works; the stationery accessories in this set are decorated all over with blue-and-white patterns, and deliver an air of elegance and scholarliness; the peony is gorgeous but not vulgar, while the interlock branches enhance the visual effect of flexibility; the as-you-wish cloud pattern on the mouth of the brush pot (the largest one among this set of accessories) echoes to the lotus petal pattern on its foot.',
            },
            {
              itemTitle: 'One-hundred-child patterned stem cup',
              itemSubtitle: 'Ming Dynasty',
              itemDescription:
                'One-hundred-child patterned stem cup: among all types of traditional cups, the stem cup requires a relatively higher level of craftsmanship. The stem cup, also named as horse riding cup, derived its name from the high foot under the body. The cup has a slightly everted mouth and become gradually full at the bottom part; the high foot is under the bottom part, and usually shaped in the forms of cylinder, square and bamboo joint, etc. The stem cup got popular in the Yuan Dynasty, which might be related to the fact that the Mongolian people liked drinking. This ware can remind you of the grand battlefield in the past, while you use it to drink fragrant tea in a leisurely and comfortable life. This ware is characterized by the exquisite painting of one hundred children in different postures in a small area.',
            },
            {
              itemTitle: 'Blue-and-white interlock branch',
              itemSubtitle: 'Ming Dynasty',
              itemDescription:
                'Blue-and-white interlock branch lotus patterned incense burner in the shape of Yuhuchun bottle: Yuhuchun bottle, also named as Yuhushang bottle, is a typical shape in the styling of Chinese porcelains. It has been widely used for a long time, and derived its shape from the water filter bottle used in the temples of Tang Dynasty. Its fundamental shape and structure involves the everted mouth, thin neck, drooped abdomen, and circular foot. It is a kind of bottle with a contour line in the gently changed arc. Its uniqueness in molding lies in that: its thin neck slightly contracts in the middle part and then gradually expands into the round abdomen with mild curves; its circular foot is relatively large, either introverted or everted. This incense burner is remanufactured from the classical ware type, and decorated on the surface with interlock branch lotus pattern, indicating honesty and uprightness and thus highlighting the noble and unsullied moral character and temperament of scholars.',
            },
            {
              itemTitle: 'baby-playing patterned lidded bowl',
              itemSubtitle: 'Ming Dynasty',
              itemDescription: `In the decoration of this baby-playing patterned lidded bowl, the traditional children pattern assisted by mountain-rock-flower pattern is adopted; the picture with innocent and cute images of children is vivid and natural, and presents vigorous signs of life. The glaze is bright-colored but not vulgar, a full reflection of the craftsman's skills!`,
            },
            {
              itemTitle: 'numerous-blossom-pattern',
              itemSubtitle: 'Ming Dynasty',
              itemDescription:
                'The ingenuity of this ware lies in the application of numerous-blossom-pattern on part of its surface for highlighting, while the left part is in blank, expressing a very high-level aesthetic taste.',
            },
            {
              itemTitle:
                'red interlock branch lotus blue-and-white lidded bowl',
              itemSubtitle: 'Ming Dynasty',
              itemDescription: `Underglaze red interlock branch lotus blue-and-white lidded bowl: the lidded bowl is a kind of tea set with a lid in the upper part and a saucer in the lower part; it's also named as "Three-realm Bowl" or "Three-realm Cup", with the lid representing the heaven, the saucer representing the earth and the bowl representing the people, implying the harmonious coexistence of people with the heaven and the earth. The interlock branch lotus pattern with the lotus in underglaze red adopted by this ware highlights its theme and presents a visual effect of being well arranged. The edges of the lid, cup and saucer are all decorated with annular-line pattern, a kind of decoration simple and elegant; the edge of the cup is everted and thus can hold the water well to avoid your hand to be scalded during tea making; the abdomen part is slightly arced, which increases the roundness of the ware and provides more space for tea leaves to spread.`,
            },
            {
              itemTitle: 'Blue-and-white lotus-seed shaped mug',
              itemSubtitle: 'Ming Dynasty',
              itemDescription:
                'Blue-and-white lotus-seed shaped mug with underglaze red interlock branch lotus pattern: the lotus-seed shaped mug derives its name from its resemblance to lotus seed in shape; this ware possesses a bulging belly, round and lovely, and delicate and pretty paintings, as well as strong practicability.',
            },
            {
              itemTitle:
                'red pine-bamboo-plum patterned blue-and-white justice cup',
              itemSubtitle: 'Ming Dynasty',
              itemDescription:
                'Underglaze red pine-bamboo-plum patterned blue-and-white justice cup: the pine, bamboo and plum are reputed as the three companions of winter to indicate the noble and unsullied moral character of scholars; in this ware, underglaze red is adopted to highlight the theme; the underglaze red in this ware is deep in color development, which makes it a quality ware rarely seen.',
            },
            {
              itemTitle: 'Underglaze red blue-and-white teapot set',
              itemSubtitle: 'Ming Dynasty',
              itemDescription:
                'Underglaze red blue-and-white teapot set: it consists of one pot and four cups; the pot belongs to the traditional pear-shaped pot, and features natural and beautiful lines, slightly bulged abdomen, a spout symmetrical with the handle, and harmonious styling. The design of tea cups with contracted mouth and bulged abdomen echoes with that of the pot; both the circular foot and mouth edge are decorated with double lines, thus echoing to each other. ',
            },
            {
              itemTitle: 'red fish-algae patterned blue-and-white stem cup',
              itemSubtitle: 'Ming Dynasty',
              itemDescription:
                'Underglaze red fish-algae patterned blue-and-white stem cup: the fish-algae pattern is a kind of decoration commonly used in ancient potteries and porcelains; the word of 鱼藻 (fish-algae) originates in The Book of Songs, the pronunciation of "鱼" (fish) in Chinese is similar to that of "余" (abundance) in Chinese, thus this pattern symbolizes good fortune. The picture on this ware features fine composition, well-spaced layout, freehand brushwork technique and smooth brush wielding; the swimming fish, aquatic plants and lotuses integrate and complement with each other, allowing the whole picture to be full of natural interest and charm!',
            },
          ],
        },
      ],
    },
  },
  furniture: {
    overview: {
      title: 'The Origins of Chinese Furniture',
      description: `The various forms of Chinese furniture we see today date back to approximately 1000 BC, and their construction is most commonly based on frame and panel, yoke and rack, and often various forms of bamboo construction. Evolving independently from western styles and forms, typical furniture for the home developed along very similar functions and forms including tables, stools, chairs, cupboards, cabinets and beds.`,
    },

    section1: {
      title: 'The Chinese Aesthetic',
      description1A: `Today, what we consider the ‘Chinese Aesthetic’ is first seen between 1500–1000 BC in the artwork from that period that depicts elevated floors accompanied by low tables and seating. During this time period, Chinese furniture was already in an advanced stage of development and is shown with intricate engravings, adornments, and select painted pieces.`,
      description1B: `What we regard as more modern Chinese furniture derived its distinguishing characterises from this early stage of development, which was further catalyzed by limitations on trade that lasted to the Ming and Qing dynasties.`,
    },
    section2: {
      title: `Sunzen Furniture`,
      description2A: `SUNZEN began designing and creating their first pieces of redwood Chinese furniture in 2010. Focussed on creating pieces that echo the classic Qing style, we have also broadened our collection to include forms classic Chinese furniture and select pieces that integrated influences from some western styles.`,
      description2B: ``,
    },
    featured: {
      title: (
        <h1>
          The Sunzen
          <br />
          Furniture Collection
        </h1>
      ),
      description:
        'Featuring a select collection of stools and tables from various periods, the gallery’s collection of furniture reflects the traditional craftsmanship and authentic materials that this art form is renowned for.',
      items: [
        {
          title: 'Seating',
          items: [
            {
              itemTitle:
                'Dalbergia louvelii hard wood Qing-style furniture table with two chairs',
              itemSubtitle: 'Qing Style',
              itemDescription:
                'This product is a classic of Qing-style furniture, and features large size, rough materials, and smooth and powerful lines. The headrest, set in a reasonable angle, takes on the form of a scroll; the backrest is embossed with happiness pattern, an auspicious omen. The curling grass pattern embossed on the leg-connection plates of the chair and tea table, flower frame carved on the backrest and armrest, and the inward horseshoe design on the legs increase the generosity and elegance of the product.',
            },
            {
              itemTitle: 'Lobular rosewood Qing-style table with two chairs',
              itemSubtitle: 'Ming Dynasty',
              itemDescription:
                'In the layout of traditional house furniture, the furniture layout for the living room is the most demanding; in the living room, it often configured with large-sized and best-craft furniture. The set of furniture is completely made with lobular red sandalwood, and features dignified and elegant figures, and varied but not confused engraving decorations; the backrest is modeled after the style of bronze ware, and carved with the traditional Taotie pattern; on the two sides of the back plate, the turning dragon pattern functions to connect the back plate and armrest under fine craftsmanship; the timber is processed either squarely or roundly; on the seat plate, relievo is carved on a separate level in extreme exquisite crafts; the four feet connect with the supporting plate, highlighting the effect of grandeur; the table and chairs are harmonious and consistent, and constitute a perfectly integrated whole.',
            },
          ],
        },
        {
          title: 'Shelves',
          items: [
            {
              itemTitle: 'Lobular rosewood five legs incense table',
              itemSubtitle: 'Qing Style',
              itemDescription:
                'This article is completely made with the lobular red sandalwood; though it is in a small size, it is well made; the five legs take on the form of "S"; the bottom of the foot is hollowed out and carved into the pattern of a flower, with a ball shape connecting to the supporting plate; at the girdling part, the embossed line is started; the leg-connection plate is carved with the passion flower pattern, in graceful lines and exquisite crafts.',
            },
            {
              itemTitle: 'Zambia rosewood bookshelf',
              itemSubtitle: 'Ming Dynasty',
              itemDescription:
                'The African sandalwood, commonly known as red sandalwood, is a kind of hardwood produced from the Africa, and those from Zambia enjoy the best quality. This timber is quite similar to the Indian sandalwood, and elegant and dignified in luster, and fine and smooth in woodiness. From the appearance, this article can be divided into four parts; however, they are in an organic whole. The four parts are reasonably divided; especially in the main part, the scientific partition combines both aesthetic value and practicability; the board between decks is made under the hidden doorjamb craft, increasing its beauty; the indented girdling highlights its overall elegance and variation. Therefore, it is a good choice for home decoration. ',
            },
          ],
        },
      ],
    },
  },
  visit: {
    hero: {
      title: 'Visit Us',
      content:
        'We invite you to discover the rich tradition of Chinese art and culture and to view our complete collection of teas, ceramics, art and furniture.',
    },
    location: {
      title: 'Location',
      address: '420 Howe St, Vancouver, BC',
      openingHours: 'Opening Hours',
      hours: 'Monday – Friday: 10:30 AM – 6:30 PM',
      hours2: 'Saturday: 11:00 AM – 6:00 PM',
      contact: 'Contact',
      phone: 'Phone',
      email: 'Email',
      imageCaption: 'Location Map',
    },
  },
  about: {
    timeline: {
      title: 'Timeline',
      subtitle:
        'The history of Sunzen.  18 years of promoting Chinese art and culture.',
      points: {
        2002: 'SUNZEN founded in Qingdao, China',
        2003: `SUNZEN’s exclusive collection of paintings and calligraphy is formed.`,
        2010: 'SUNZEN begins to manufacture their own traditional Chinese rosewood furniture.',
        2018: 'SUNZEN opens their first international gallery in Vancouver, Canada.',
      },
    },
    hero: {
      title: 'About Sunzen',
      subTitle:
        'Since 2002, the SUNZEN has focussed on collecting and selling traditional Chinese furniture, paintings, and other forms of fine Chinese artistry.',
    },
    story: {
      title: 'The Sunzen Story',
      subTitle: (
        <>
          Promoting chinese
          <br />
          traditional art and culture
        </>
      ),
      content:
        'SUNZEN was founded on 2002 in coastal region of China to promote traditional culture and artwork around the world. Based on the old Chinese philosophy and value of “humble and honest for three generations”, SUNZEN’s goal is to create a permanent community focussed on the promotion and growth of traditional Chinese art.',
      imgLabel: 'Original Sunzen gallery in Qingdao, China',
    },
    fullTextBlock1:
      'Sunzen is vancouver’s preeminent gallery of fine chinese art.',
    fullTextBlock2:
      'We are excited to extend our passion for artistry to vancouver and introduce a new audience about the deep cultural heritage of chinese art, tea, furniture and ceramics and more.',
    community: {
      title: (
        <>
          Cultivating an
          <br />
          artistic community
        </>
      ),
      subTitle: (
        <>
          We welcome all who have
          <br /> a passion for artistry
        </>
      ),
      content:
        'From large display to small plant exhibitions, SUNZEN has created a collaborative international community where people to share their passion for art with collections attracting people from all over the world to visit the home of SUNZEN in China.',
    },
    collection: {
      title: 'The First Collection in Canada',
      subTitle:
        'The first international sunzen luxury art platform has come to vancouver',
      content: `To further promote great Chinese art and cultural artifact collecting, SUNZEN in Vancouver will be home to a unique collection of traditional Chinese furniture, calligraphy, paintings, Pu'er tea and authentic tea sets, as well as a future collection contemporary art.`,
      linkText: 'Visit The Gallery',
    },
  },
  home: {
    hero: {
      title: 'Life is art',
      subTitle:
        'The home of fine Chinese art and cultural collector pieces in Vancouver',
    },
    about: {
      title: 'About Us',
      subTitle: 'Now Open in Vancouver',
      content: `To promote great Chinese art and cultural artifacts, SUNZEN in Vancouver is focussed on introducing a new artistic chapter in the city with a unique collection of rosewood furniture, calligraphy, paintings, Pu'er tea and authentic tea sets.`,
      linkText: 'Learn More',
      imgLabel: 'New Vancouver gallery space',
    },
    collection: {
      title: 'The Collection',
      subTitle: 'Traditional Chinese art and cultural artifacts',
      content:
        'The collection of artworks in the gallery includes some of the finest examples of traditional Chinese artistry. From furniture created in the traditional Chinese style, exclusive Pu’er Tea, metallic and clay tea sets, ceramics, and chinaware from the Chun Feng Xiang Yu workshop in Jingdezhen, to paintings and calligraphy from renowned Chinese artists, SUNZEN’s features a broad range of styles and expressions.',
      items: {
        tea: {
          title: 'Tea',
          subTitle: 'Pu’er Tea, tea sets',
        },
        art: {
          title: 'Art',
          subTitle: 'Sculptures, paintings & calligraphy',
        },
        ceramics: {
          title: 'Ceramics',
          subTitle: 'FROM THE CHUN FENG XIANG YU AND YUAN HUA TANG WORKSHOP',
        },
        furniture: {
          title: 'Furniture',
          subTitle: 'Traditional Chinese and contemporary styles',
        },
      },
    },
    events: {
      title: 'Sunzen Events',
      subTitle: 'Exhibitions & Collaboration',
      content:
        'Along with an extensive collection of artwork and cultural pieces, SUNZEN Art Limited is also home to numerous exhibitions and events to foster the development of a community between artists, collectors and professionals.',
      viewEvents: 'View Events',
      upcoming: 'Upcoming Event',
      month: 'June',
      cardTopic:
        'Cocktail reception for the 8th Yishu Awards for Critical Writing on Contemporary Chinese Art',
    },
    fullTextBlock1:
      'A renowned collection of art and culture that naturally reflects the rich traditions of china.',
    fullTextBlock2: 'Discover a new world of traditions',
  },
  events: {
    hero: {
      title: 'Sunzen Events',
      content:
        'Along with an extensive collection of artwork and cultural pieces, SUNZEN Art Limited is also home to numerous exhibitions and events to foster the development of a community between artists, collectors and professionals.',
    },
    content: {
      upcoming: 'All Upcoming Events',
      description: 'Lorem Ipsum Dolor Sit Amet',
      events: [
        {
          day: '1',
          month: 'June',
          topic:
            'Cocktail reception for the 8th Yishu Awards for Critical Writing on Contemporary Chinese Art',
        },
      ],
    },
  },
  privacy: {
    title: 'Privacy Policy/Terms of Use',
    description:
      'SUNZEN Art Limited and each of our affiliates and partnerships is committed to maintaining the accuracy, confidentiality and security of your personal information. This Privacy Policy describes the personal information that SUNZEN collects from or about you, how we use that information, and to whom we disclose that information.',
    sections: [
      {
        title: 'Collecting Personal Information',
        description:
          'SUNZEN collects personal information to better serve your needs, whether purchasing products and services from us or obtaining service or warranty work. In order to provide you with our wide variety of services, products and promotions, we may collect the following information:',
        list: [
          'Contact and mailing information such as name, address, telephone numbers, fax numbers and email addresses.',
          'The plans, specifications and other particulars about the home that is being built for you.',
          'Municipal and legal descriptions for you home.',
          'Any other relevant information necessary to provide you with our products and services.',
          'We may need to collect other personal information depending on the circumstance, but we will ask you directly and ensure that you agree.',
        ],
      },
      {
        title: 'Personal Information Security',
        description:
          'Like you, SUNZEN values our personal information. Part of valuing your personal information is making sure that it is protected and kept confidential. We achieve this by:',
        list: [
          'Having our employees sign a confidentiality agreement.',
          'Having up to date computer security such as passwords, encryption and firewalls.',
          'Where third parties are hired to assist us with providing products and services to you for your home, we have them sign a privacy and confidentiality agreement to ensure that your personal information is protected by them.',
        ],
      },
      {
        title: 'Personal Information Updating',
        description:
          'SUNZEN wants to ensure that your personal information is always correct up to date. If your personal information changes or is incorrect, updates can be made by contacting your representative with the change and any other information you feel is necessary.',
        description2:
          'We will keep your information as long as is necessary to continue to meet your customer needs and any legal requirements we may have. However, once your personal information no longer needed, we will destroy it.',
      },
    ],
    personalData: {
      title: 'Personal Data',
      content: [
        'We only collect Personal Data from you, such as first and last name, phone number, e-mail, when you register to receive information. We collect data about your activities that does not personally or directly identify you when you visit our website, or the websites and online services where we display advertisements. This information may include the content you view, the date and time that you view this content, the products you purchase, or your location information associated with your IP address. We use the information we collect to serve you more relevant advertisements (referred to as "Retargeting"). We collect information about where you saw the ads we serve you and what ads you clicked on.',
        'As you browse this website, online ad networks we work with may place anonymous cookies on your computer, and use similar technologies, in order to understand your interests based on your (anonymous) online activities, and thus to tailor more relevant ads to you. If you do not wish to receive such tailored advertising, you can visit this page to opt out of most companies that engage in such advertising. ( This will not prevent you from seeing ads; the ads simply will not be delivered through these targeting methods. )',
        'We do not target ads to you based on sensitive personal data, such as information related to race or ethnic origin, political opinions, religious beliefs or other beliefs of a similar nature, trade union membership, physical or mental health or condition or sexual life. We may target ads based on data provided by Advertisers or partners alone or in combination with the data we collect ourselves. Any data used to serve targeted advertisements is de-identified and is not used to personally or directly identify you. In order to allow us to reach the best inventory online, we work with third party advertising companies (our "Advertising Partners") to help us recognize you and serve relevant advertisements to you when you visit a website or online service in their network. We also work with Advertising Partners who help us recognize you across different devices in order to show you relevant advertisements. Our Advertising Partners may collect information about your activities on our website, and other websites or online services in their networks. We also work with third party companies to assist us with website analytics such as evaluating the use and operation of our website so that we can continue to enhance the website and our services.',
        `We and our third-party partners use session cookies and persistent cookies to make it easier for you to navigate and enhance the experience of our site, and to monitor and evaluate our website's operation and use.`,
        'To modify or delete your personal information, or to opt out of future communications, please contact us at:',
      ],
      addressTitle: 'Sunzen Art Limited',
    },
  },
};
