import imgArtCharacter from 'src/assets/images/art/art-character.svg';

export default {
  overview: {
    image: imgArtCharacter,
    opacity: 1,
    height: 773,
  },
  section1: {
    video:
      'https://player.vimeo.com/external/319621191.hd.mp4?s=159fcbf648837ea5e7a87853b4086696ae20a999&profile_id=175',
  },
  section2: {
    character: {
      image: '',
      height: 136,
      width: 268,
    },
    video:
      'https://player.vimeo.com/external/319620525.hd.mp4?s=22f2570fa8fb83039902b5a871a59f3e4fe037b0&profile_id=174',
  },
  featured: [
    [
      {
        image: require('src/assets/images/art/products/painting/Gong-Qi.jpg'),
      },
      {
        image: require('src/assets/images/art/products/painting/Two_Chock_rest_under_a_plum.jpg'),
      },
      {
        image: require('src/assets/images/art/products/painting/Art_Painting_3.jpg'),
      },
      {
        image: require('src/assets/images/art/products/painting/Art_Painting_4.jpg'),
      },
      {
        image: require('src/assets/images/art/products/painting/Art_Painting_5.jpg'),
      },
      {
        image: require('src/assets/images/art/products/painting/Art_Painting_8.jpg'),
      },
    ],
    [
      {
        image: require('src/assets/images/art/products/wood/Art_Wood-carving_1.jpg'),
      },
      {
        image: require('src/assets/images/art/products/wood/Art_Wood-carving_2.jpg'),
      },
      {
        image: require('src/assets/images/art/products/wood/Art_Wood-carving_3.jpg'),
      },
      {
        image: require('src/assets/images/art/products/wood/Art_Wood-carving_4.jpg'),
      },
    ],
    [
      {
        image: require('src/assets/images/art/products/statue/Art_Statue_1.jpg'),
      },
    ],
  ],
};
