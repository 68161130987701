export default {
  colorPrimary: '#00d8ff',
  colorLightPink: '#d8d8d8',
  colorBlack: '#000000',
  colorBackgroundBlack: '#0c0c0c',
  colorWhite: '#FFFFFF',
  colorRed: '#c22033',
  colorNavy: '#1a1a1a',
  fontPrimary: 'baskerville-display-pt, serif',
  fontSecondary: 'Gotham',
  fontSecondaryLight: 'Gotham Book',
};
