import React, { Component } from 'react';

import Intl from 'src/components/Intl';

import ProductPage from 'src/components/ProductPage/ProductPage';

import art from 'src/data/ProductPage/art';

class Art extends Component {
  render() {
    return (
      <Intl>
        {(t, { locale }) => {
          return <ProductPage text={t.art} assets={art} name="art" />;
        }}
      </Intl>
    );
  }
}

export default Art;
