import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from 'src/styles/media';

import { vw } from 'src/styles/utils';

const BackgroundBlock = props => {
  return <Root {...props} />;
};

BackgroundBlock.propTypes = {
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  mobileBackgroundImage: PropTypes.string,
};

const Root = styled.div`
  background-color: ${props => props.backgroundColor};
  padding-top: ${vw(110)};
  padding-bottom: ${vw(75)};
  z-index: 0;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${props =>
      props.mobileBackgroundImage
        ? props.mobileBackgroundImage
        : props.backgroundImage});
    background-size: cover;
    background-position: center;
    z-index: -1;
    @media (min-width: ${media.xsup}) {
      background: url(${props => props.backgroundImage});
    }
  }
`;

export default BackgroundBlock;
