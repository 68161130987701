import React, { Component } from 'react';
import Reveal from 'react-reveal/Reveal';

class ScrollAnimation extends Component {
  render() {
    return (
      <Reveal effect={this.props.effect || 'fadeInUp'} delay={this.props.delay}>
        {this.props.children}
      </Reveal>
    );
  }
}

export default ScrollAnimation;
