import React, { Component } from 'react';
import styled from 'styled-components';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getProducts, getCategories } from 'src/redux/products';

import Intl from 'src/components/Intl';
import { vw } from 'src/styles/utils';

import Overview from './sections/Overview';
import Section1 from './sections/Section1';
import Section2 from './sections/Section2';
import FeaturedProducts from './sections/FeaturedProducts/FeaturedProducts';
import LessonCard from './sections/LessonCard';
import ExploreCollection from 'components/ExploreCollection/ExploreCollection';

class ProductPage extends Component {
  state = {
    activeIndex: 0,
    activeItemIndex: 0,
    isOpen: false,
    effect: null,
    lessonActiveIndex: 0,
    mobileActiveIndex: [],
    products: [],
  };

  componentDidMount() {
    if (this.props.products.length === 0) {
      this.props.getProducts();
    }
    if (this.props.categories.length === 0) {
      this.props.getCategories();
    }
    if (
      this.props.products.length !== 0 &&
      this.props.categories.length !== 0 &&
      this.state.products.length === 0
    ) {
      this.sortProducts(this.props.products, this.props.categories);
    }
  }

  componentDidUpdate = prevProps => {
    if (
      this.props.products &&
      this.props.products.length !== 0 &&
      this.props.categories.length !== 0 &&
      this.state.products.length === 0
    ) {
      this.sortProducts(this.props.products, this.props.categories);
    }
  };

  sortProducts = (products, categories) => {
    // This is just to sort the products & categories (which are coming from two different endpoints) into an easily readable array.
    let newProducts = [];
    let parentCategories = categories.filter(category => category.parent === 0);
    let subCategories = categories.filter(category => category.parent !== 0);
    // Mapping through the parent categories and subcategories and matching them up.
    parentCategories.map(category => {
      newProducts.push({ id: category.id, name: category.name, items: [] });
      return null;
    });
    subCategories.map(category => {
      const parentIndex = parentCategories.findIndex(
        item => item.id === category.parent
      );
      newProducts[parentIndex].items.push({
        title: category.name,
        items: [],
        id: category.id,
      });
      return null;
    });
    // Mapping through the products and matching them to their category
    // parent category in products is always the lesser of the two numbers
    products.map(product => {
      let parentIndex;
      let subIndex;
      if (product.categories[0].term_id < product.categories[1].term_id) {
        parentIndex = product.categories[0].term_id;
        subIndex = product.categories[1].term_id;
      } else {
        parentIndex = product.categories[1].term_id;
        subIndex = product.categories[0].term_id;
      }
      let parentObjectIndex = newProducts.findIndex(
        item => item.id === parentIndex
      );

      let subCategoryIndex = newProducts[parentObjectIndex].items.findIndex(
        item => item.id === subIndex
      );

      newProducts[parentObjectIndex].items[subCategoryIndex].items.push({
        title: product.post_title,
        description: product.acf.description,
        image: product.image,
        zh_title: product.acf.zh_title,
        zh_description: product.acf.zh_description,
      });

      return null;
    });

    const activeCategory = newProducts.filter(
      item => item.name.toLowerCase() === this.props.name
    );
    this.setState({ products: activeCategory[0].items });
  };

  handleSelection = index => {
    let activeIndex;
    let effect;
    if (index > this.state.activeIndex) {
      effect = 'fadeUp';
    } else effect = 'fadeDown';
    activeIndex = index;
    this.setState({ activeIndex, effect, activeItemIndex: 0 });
  };

  handleItemSelection = index => {
    let activeItemIndex;
    let effect;
    if (index > this.state.products[this.state.activeIndex].items.length - 1) {
      activeItemIndex = 0;
      effect = 'fadeUp';
    } else if (index < 0) {
      activeItemIndex =
        this.state.products[this.state.activeIndex].items.length - 1;
      effect = 'fadeUp';
    } else {
      activeItemIndex = index;
      effect = 'fadeDown';
    }

    this.setState({ activeItemIndex, effect });
  };

  handleToggleCard = () => {
    this.setState({ isOpen: !this.state.isOpen });
    document.getElementById('description').scrollTop = 0;
  };

  toggleActiveStep = (e, index) => {
    let stepItem = document.getElementById(index);
    let container = document.getElementById('container');
    if (index <= 3) {
      container.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      container.scrollTo({
        top: stepItem.offsetTop,
        behavior: 'smooth',
      });
    }
    this.setState({
      lessonActiveIndex: index,
    });
  };

  toggleMobileActiveStep = index => {
    let newArray = [];
    if (this.state.mobileActiveIndex.includes(index)) {
      newArray = this.state.mobileActiveIndex.filter(item => item !== index);
      this.setState({ mobileActiveIndex: newArray });
    } else {
      newArray = this.state.mobileActiveIndex;
      newArray.push(index);
      this.setState({ mobileActiveIndex: newArray });
    }
  };

  render() {
    const { text, assets, categories } = this.props;
    // console.log(this.state.products);
    return (
      <Intl>
        {(t, { locale }) => {
          return (
            <Root>
              <Overview text={text.overview} assets={assets.overview} />

              <Section1 text={text.section1} assets={assets.section1} />

              <Section2 text={text.section2} assets={assets.section2} />

              <FeaturedProducts
                text={text.featured}
                assets={assets.featured}
                data={this.state.products}
                categories={categories}
                activeIndex={this.state.activeIndex}
                activeItemIndex={this.state.activeItemIndex}
                handleItem={this.handleItemSelection}
                handleSelection={this.handleSelection}
                handleToggleCard={this.handleToggleCard}
                cardOpen={this.state.isOpen}
                effect={this.state.effect}
              />

              {text.lessons && (
                <LessonCard
                  text={text.lessons}
                  activeIndex={this.state.lessonActiveIndex}
                  toggleActiveStep={this.toggleActiveStep}
                  activeMobileIndex={this.state.mobileActiveIndex}
                  toggleMobileActiveStep={this.toggleMobileActiveStep}
                />
              )}

              <ExploreCollection productLayout />
            </Root>
          );
        }}
      </Intl>
    );
  }
}

const mapStateToProps = state => ({
  products: state.products.products,
  categories: state.products.categories,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getProducts,
      getCategories,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductPage);

const Root = styled.div`
  background: ${({ theme }) => theme.colorBackgroundBlack};
  padding-bottom: ${vw(30)};
`;
