import React from 'react';
import styled from 'styled-components';

import { vw } from 'src/styles/utils';

const Line = () => {
  return <Root />;
};

export default Line;

const Root = styled.div`
  width: ${vw(30)};
  height: ${vw(2)};
  background: ${({ theme }) => theme.colorRed};
`;
