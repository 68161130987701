import React from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

const Container = props => {
  return <Root>{props.children}</Root>;
};

Container.propTypes = {};

const Root = styled.div`
  @media (min-width: ${media.mdup}) {
    width: 97.5%;
    margin: 0 auto;
  }
`;

export default Container;
