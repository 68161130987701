import request from 'src/utils/request';
import appConfig from 'src/config/app.conf';

// Constants
export const PRODUCTS__GET = 'PRODUCTS::GET';
export const CATEGORIES__GET = 'CATEGORIES::GET';

// Actions
export const getProducts = () => dispatch => {
  request(
    `${window.location.protocol}//${appConfig.wordpressProductAPIUrl}/products`
  ).then(res => {
    dispatch({
      type: PRODUCTS__GET,
      products: res.data,
    });
  });
};

export const getCategories = () => dispatch => {
  request(
    `${window.location.protocol}//${
      appConfig.wordpressBasicAPI
    }/categories?&per_page=50`
  ).then(res => {
    dispatch({
      type: CATEGORIES__GET,
      categories: res.data,
    });
  });
};

// Initial State
const initialState = {
  products: [],
  categories: [],
};

// Reducer
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case PRODUCTS__GET:
      return {
        ...state,
        products: action.products,
      };
    case CATEGORIES__GET:
      return {
        ...state,
        categories: action.categories,
      };
    default:
      return state;
  }
};
