import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import media from 'src/styles/media';

import { vw, vwMobile, vwTablet } from 'src/styles/utils';

const LinkCard = props => {
  return (
    <Root to={props.linkTo} activeClassName="isActive">
      <img src={props.backgroundImage} alt="chinese" />
      <TextWrapper>
        <p>{props.label}</p>
        <span>{props.linkText}</span>
      </TextWrapper>
    </Root>
  );
};

LinkCard.propTypes = {
  linkTo: PropTypes.string,
  backgroundImage: PropTypes.string,
  label: PropTypes.string,
  linkText: PropTypes.string,
};

const Root = styled(NavLink)`
  /* padding-top: ${vw(30)};
  padding-bottom: ${vw(35)}; */
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  position: relative;
  margin-bottom: ${vwMobile(65)};
  @media (min-width: ${media.xsup}) {
    margin-bottom: ${vwTablet(65)};
  }
  @media (min-width: ${media.mdup}) {
    margin-bottom: 0;
  }
  &.isActive{
    display: none;
  }
  img {
    width: auto;
    height: ${vwMobile(104)};
    @media (min-width: ${media.xsup}) {
      height: ${vwTablet(104)};
    }
    @media (min-width: ${media.mdup}) {
      height: ${vw(104)};
    }
  }
  p {
    font-family: ${({ theme }) => theme.fontPrimary};
    font-size: ${vwMobile(20)};
    line-height: ${vwMobile(41)};
    letter-spacing: ${vwMobile(1.58)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(20)};
      line-height: ${vwTablet(41)};
      letter-spacing: ${vwTablet(1.58)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(20)};
      line-height: ${vw(41)};
      letter-spacing: ${vw(1.58)};
    }
  }
  span {
    font-family: ${({ theme }) => theme.fontSecondary};
    font-size: ${vwMobile(11)};
    font-weight: 500;
    letter-spacing: ${vwMobile(1.57)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(11)};
      letter-spacing: ${vwTablet(1.57)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(11)};
      letter-spacing: ${vw(1.57)};
    }
  }
`;

const TextWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 200%;
  color: white !important;
`;

export default LinkCard;
