import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Intl from 'src/components/Intl';
import media from 'src/styles/media';
import { vw, vwMobile, vwTablet } from 'src/styles/utils';

import BackgroundBlock from 'components/videoPage/BackgroundBlock';
import TwoColumnInnerContainer from 'components/videoPage/TwoColumnInnerContainer';
import ContentBlock from 'components/videoPage/ContentBlock';
import ImageBlock from 'components/videoPage/ImageBlock';

import imgScroll from 'src/assets/images/icons/scroll-gallery-down.svg';

const Story = props => {
  const renderPoints = t => {
    return [
      {
        year: 2001,
        event: ``,
      },
      {
        year: 2002,
        event: t.about.timeline.points[2002],
      },
      {
        year: 2003,
        event: t.about.timeline.points[2003],
      },
      {
        year: 2004,
        event: ``,
      },
      {
        year: 2005,
        event: ``,
      },
      {
        year: 2006,
        event: ``,
      },
      {
        year: 2007,
        event: ``,
      },
      {
        year: 2008,
        event: ``,
      },
      {
        year: 2009,
        event: ``,
      },
      {
        year: 2010,
        event: t.about.timeline.points[2010],
      },
      {
        year: 2011,
        event: ``,
      },
      {
        year: 2012,
        event: ``,
      },
      {
        year: 2013,
        event: ``,
      },
      {
        year: 2014,
        event: ``,
      },
      {
        year: 2015,
        event: ``,
      },
      {
        year: 2016,
        event: ``,
      },
      {
        year: 2017,
        event: ``,
      },
      {
        year: 2018,
        event: t.about.timeline.points[2018],
      },
      {
        year: 2019,
        event: ``,
      },
      {
        year: 2020,
        event: ``,
      },
      {
        year: 2021,
        event: ``,
      },
      {
        year: 2022,
        event: ``,
      },
    ];
  };

  return (
    <Intl>
      {(t, { locale }) => (
        <Root backgroundColor="white">
          <TwoColumnInnerContainer
            left={
              <ContentBlock
                label={t.about.story.title}
                sublabel={t.about.story.subTitle}
                content={t.about.story.content}
              />
            }
            right={
              <ImageBlock
                imageSrc={require('src/assets/images/about/story.jpg')}
                imageLabel={t.about.story.imgLabel}
              />
            }
          />
          <TimelineContainer>
            <Title>
              <h3>{t.about.timeline.title}</h3>
              <p>{t.about.timeline.subtitle}</p>

              <Scrolling>
                <ScrollLeft
                  src={imgScroll}
                  alt="Navigate Left"
                  onClick={() => props.handleClick('left')}
                  disabled={props.disabledLeft}
                />
                <ScrollRight
                  src={imgScroll}
                  alt="Navigate Right"
                  onClick={() => props.handleClick('right')}
                  disabled={props.disabledRight}
                />
              </Scrolling>
            </Title>
            <Wrapper ref={props.timelineRef} onScroll={props.handleScrolling}>
              <Timeline>
                {renderPoints(t).map((event, index) =>
                  event.event ? (
                    <Point key={index}>
                      <Text top={index % 2 === 0}>
                        <h4>{event.year}</h4>
                        <p>{event.event}</p>
                        <p>{index % 2 === 0}</p>
                      </Text>
                    </Point>
                  ) : (
                    <Line key={index} />
                  )
                )}
              </Timeline>
            </Wrapper>
          </TimelineContainer>
        </Root>
      )}
    </Intl>
  );
};

Story.propTypes = {
  handeClick: PropTypes.func,
  disabledRight: PropTypes.bool,
  disabledLeft: PropTypes.bool,
  handleScrolling: PropTypes.func,
  timelineRef: PropTypes.object,
};

const Root = styled(BackgroundBlock)``;

const TimelineContainer = styled.div`
  text-align: left;
  padding-left: ${vwMobile(25)};
  padding-right: ${vwMobile(25)};
  margin-top: ${vwMobile(25)};
  overflow: hidden;
  position: relative;
  @media (min-width: ${media.xsup}) {
    padding-left: ${vwTablet(25)};
    padding-right: ${vwTablet(25)};
    margin-top: ${vwTablet(25)};
  }
  @media (min-width: ${media.mdup}) {
    padding-left: ${vw(72)};
    padding-right: ${vw(123)};
    margin-top: ${vw(51)};
  }

  &::after {
    content: '';
    width: ${vwMobile(324)};
    height: ${vwMobile(353)};
    position: absolute;
    top: ${vwMobile(74)};
    right: ${vwMobile(25)};
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 75%,
      white 100%
    );
    pointer-events: none;
    @media (min-width: ${media.xsup}) {
      width: ${vwTablet(664)};
      height: ${vwTablet(353)};
      top: ${vwTablet(74)};
      right: ${vwTablet(20)};
    }
    @media (min-width: ${media.mdup}) {
      width: ${vw(1239)};
      height: ${vw(353)};
      top: ${vw(51)};
      right: ${vw(119)};
    }
  }

  h3 {
    font-family: ${({ theme }) => theme.fontPrimary};
    font-size: ${vwMobile(18)};
    font-weight: normal;
    line-height: 1.39;
    letter-spacing: ${vwMobile(1.8)};
    text-transform: uppercase;
    margin-bottom: ${vwMobile(3)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(18)};
      letter-spacing: ${vwTablet(1.8)};
      margin-bottom: ${vwTablet(3)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(18)};
      letter-spacing: ${vw(1.8)};
      margin-bottom: ${vw(3)};
    }
  }
  p {
    font-size: ${vwMobile(17)};
    font-weight: normal;
    line-height: 1.41;
    color: ${({ theme }) => theme.colorNavy};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(17)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(17)};
    }
  }
`;

const ScrollLeft = styled.img`
  transform: rotate(90deg);
  margin-right: ${vw(27)};
  cursor: pointer;
  height: ${vw(27)};
  width: auto;
  opacity: ${props => (props.disabled ? '0.45' : '1')};
`;

const ScrollRight = styled.img`
  transform: rotate(-90deg);
  cursor: pointer;
  height: ${vw(27)};
  width: auto;
  opacity: ${props => (props.disabled ? '0.45' : '1')};
`;

const Title = styled.div`
  position: relative;
  margin-bottom: ${vw(10)};
`;

const Scrolling = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: none;
  @media (min-width: ${media.mdup}) {
    display: block;
  }
`;

const Timeline = styled.div`
  width: auto;
  /* background: linear-gradient(to left, pink, blue);
  background-position: ${props => `-${props.left}%`}; */
  padding-top: ${vwMobile(160)};
  height: ${vwMobile(350)};
  align-items: center;
  white-space: nowrap;
  @media (min-width: ${media.xsup}) {
    padding-top: ${vwTablet(160)};
    height: ${vwTablet(350)};
  }
  @media (min-width: ${media.mdup}) {
    height: ${vw(350)};
    padding-top: ${vw(160)};
  }
`;

const Wrapper = styled.div`
  width: 100%;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    width: 0px; /* remove scrollbar space */
    background: transparent; /* optional: just make scrollbar invisible */
  }
`;

const Point = styled.div`
  height: ${vwMobile(10)};
  width: ${vwMobile(10)};
  background: ${({ theme }) => theme.colorRed};
  margin-right: ${vwMobile(100)};
  position: relative;
  display: inline-block;
  @media (min-width: ${media.xsup}) {
    height: ${vwTablet(10)};
    width: ${vwTablet(10)};
    margin-right: ${vwTablet(100)};
  }
  @media (min-width: ${media.mdup}) {
    height: ${vw(10)};
    width: ${vw(10)};
    margin-right: ${vw(100)};
  }
  h4 {
    font-family: ${({ theme }) => theme.fontSecondary};
    font-size: ${vwMobile(11)};
    font-weight: 500;
    letter-spacing: ${vwMobile(2)};
    color: ${({ theme }) => theme.colorRed};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(11)};
      letter-spacing: ${vwTablet(2)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(11)};
      letter-spacing: ${vw(2)};
    }
  }
  p {
    font-size: ${vwMobile(17)};
    font-weight: normal;
    line-height: 1.41;
    color: ${({ theme }) => theme.colorNavy};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(17)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(17)};
    }
  }
`;

const Line = styled.div`
  height: ${vwMobile(13.4)};
  width: ${vwMobile(1)};
  background: #0c0c0c;
  margin-right: ${vwMobile(100)};
  display: inline-block;
  @media (min-width: ${media.xsup}) {
    height: ${vwTablet(13.4)};
    width: ${vwTablet(1)};
    margin-right: ${vwTablet(100)};
  }
  @media (min-width: ${media.xsup}) {
    height: ${vw(13.4)};
    width: ${vw(1)};
    margin-right: ${vw(100)};
  }
`;

const Text = styled.div`
  position: absolute;
  width: ${vwMobile(200)};
  top: ${props => props.top && vwMobile(-130)};
  padding-left: ${vwMobile(20)};
  border-left: ${({ theme }) => `3px dotted ${theme.colorRed}`};
  padding-top: ${props => !props.top && vwMobile(80)};
  padding-bottom: ${props => props.top && vwMobile(70.6)};
  left: ${vwMobile(3)};
  @media (min-width: ${media.xsup}) {
    width: ${vwTablet(200)};
    padding-left: ${vwTablet(20)};
    padding-top: ${props => !props.top && vwTablet(80)};
    padding-bottom: ${props => props.top && vwTablet(70.6)};
    left: ${vwTablet(3)};
    top: ${props => props.top && vwTablet(-130)};
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(200)};
    padding-left: ${vw(20)};
    padding-top: ${props => !props.top && vw(80)};
    padding-bottom: ${props => props.top && vw(70.6)};
    left: ${vw(3)};
    top: ${props => props.top && vw(-130)};
  }
  p {
    white-space: normal;
  }
`;
export default Story;
