import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Intl from 'src/components/Intl';
import media from 'src/styles/media';
import { vw, vwTablet, vwMobile } from 'src/styles/utils';

import ScrollAnimation from 'src/components/ScrollAnimation';

import imgOverlay from 'src/assets/images/product-page/video-inline-overlay.svg';

const Section2 = ({ text, assets }) => {
  return (
    <Intl>
      {(t, { locale }) => {
        return (
          <Root>
            <Video>
              <Overlay overlay={imgOverlay} />
              <video playsInline muted autoPlay loop height="105%" width="105%">
                <source src={assets.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </Video>

            <Text>
              <Title
                character={assets.character.image}
                height={assets.character.height}
                width={assets.character.width}
              >
                <ScrollAnimation>
                  <h1>{text.title}</h1>
                </ScrollAnimation>
              </Title>
              <Body>
                <ScrollAnimation delay={100}>
                  <p>{text.description2A}</p>
                </ScrollAnimation>

                <ScrollAnimation delay={300}>
                  <p>{text.description2B}</p>
                </ScrollAnimation>
              </Body>
            </Text>
          </Root>
        );
      }}
    </Intl>
  );
};

Section2.propTypes = {
  text: PropTypes.object,
  assets: PropTypes.object,
};

export default Section2;

const Root = styled.div`
  color: ${({ theme }) => theme.colorWhite};
  margin-bottom: ${vw(205)};
  position: relative;
`;

const Title = styled.div`
  position: relative;
  @media (min-width: ${media.xsup}) {
    width: ${vw(619)};
  }

  &::before {
    content: '';
    position: absolute;
    top: ${vwMobile(12)};
    left: ${vwMobile(-25)};
    width: ${vwMobile(10)};
    height: ${vwMobile(10)};
    background: ${({ theme }) => theme.colorRed};
    margin-left: ${vwMobile(20)};
    @media (min-width: ${media.xsup}) {
      top: ${vwTablet(12)};
      left: ${vwTablet(-25)};
      width: ${vwTablet(10)};
      height: ${vwTablet(10)};
      margin-left: 0;
    }
    @media (min-width: ${media.mdup}) {
      top: ${vw(12)};
      left: ${vw(-25)};
      width: ${vw(10)};
      height: ${vw(10)};
    }
  }

  &::after {
    content: '';
    background: ${props => `url(${props.character})`};
    background-size: contain;
    background-repeat: no-repeat;

    opacity: 1;
    height: ${props => `${vw(props.height)}`};
    width: ${props => `${vw(props.width)}`};
    position: absolute;
    left: 0;
    transform: translate(-35%, -50%);
    top: 0;
  }
  h1 {
    font-family: ${({ theme }) => theme.fontPrimary};
    font-size: ${vwMobile(23)};
    font-weight: normal;
    letter-spacing: ${vwMobile(1.64)};
    text-transform: uppercase;
    margin-bottom: ${vwMobile(30)};
    margin-left: ${vwMobile(20)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(28)};
      letter-spacing: ${vwTablet(5)};
      margin-bottom: ${vwTablet(30)};
      margin-left: 0;
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(28)};
      letter-spacing: ${vw(5)};
      margin-bottom: ${vw(30)};
    }
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${media.mdup}) {
    flex-direction: row;
  }
  p {
    font-size: ${vwMobile(17)};
    font-weight: normal;
    line-height: 1.41;
    &:first-of-type {
      margin-bottom: ${vwMobile(20)};
      margin-right: ${vw(67)};
    }
    @media (min-width: ${media.xsup}) {
      width: ${vwTablet(400)};
      font-size: ${vwTablet(17)};
      &:first-of-type {
        margin-bottom: ${vwTablet(20)};
        margin-right: ${vwTablet(67)};
      }
    }
    @media (min-width: ${media.mdup}) {
      width: ${vw(300)};
      font-size: ${vw(17)};
      &:first-of-type {
        margin-right: ${vw(67)};
      }
    }
  }
`;

const Text = styled.div`
  z-index: 99;
  position: relative;
  margin: 0 ${vwMobile(25)};
  padding-top: ${vwMobile(200)};
  @media (min-width: ${media.xsup}) {
    padding: 0;
    margin-left: ${vw(638)};
  }
`;

const Video = styled.div`
  position: absolute;
  height: ${vwMobile(200)};
  width: ${vwMobile(325)};
  overflow: hidden;
  top: 0;
  left: ${vwMobile(25)};
  @media (min-width: ${media.xsup}) {
    width: ${vw(707)};
    height: ${vw(398)};
    left: 0;
  }
  video {
    width: 100%;
  }
`;

const Overlay = styled.div`
  background-image: ${props => `url(${props.overlay})`};
  background-size: cover;
  height: ${vwMobile(202)};
  width: ${vwMobile(325)};
  position: absolute;
  top: 0;
  left: 0;
  @media (min-width: ${media.xsup}) {
    width: ${vw(709)};
    height: ${vw(402)};
    left: 0;
  }
`;
