import React from 'react';
import styled, { keyframes } from 'styled-components';

import media from 'src/styles/media';
import { vw, vwMobile, vwTablet } from 'src/styles/utils';

const Hero = props => {
  return (
    <Root {...props}>
      <ContentWrapper>
        <h1>{props.title}</h1>
        <p>{props.content}</p>
      </ContentWrapper>
    </Root>
  );
};

const animatedDrawDown = keyframes`
  0% {
    max-height: 0;
    opacity: 0;
  }
  50% {
    max-height: 61px;
    opacity:1;
  }
  100%{
    opacity:1;
    max-height: 61px;
  }
`;

const Root = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    bottom: 23px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    width: ${vw(2)};
    min-width: 2px;
    height: 61px;
    background-color: white;
    animation: ${animatedDrawDown} 2.2s ease infinite;
    animation-direction: reverse;
    opacity: 0;
  }
`;

const ContentWrapper = styled.div`
  font-family: ${({ theme }) => theme.fontPrimary};
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  width: 86.67%;
  @media (min-width: ${media.mdup}) {
    width: 42.8%;
  }
  h1 {
    font-size: ${vwMobile(30)};
    line-height: ${vwMobile(41)};
    letter-spacing: ${vwMobile(4.29)};
    margin-bottom: ${vwMobile(11)};
    text-align: center;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(30)};
      line-height: ${vwTablet(41)};
      letter-spacing: ${vwTablet(4.29)};
      margin-bottom: ${vwTablet(11)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(28)};
      line-height: ${vw(41)};
      letter-spacing: ${vw(4)};
      margin-bottom: ${vw(39)};
    }
  }
  p {
    font-size: ${vwMobile(16)};
    line-height: ${vwMobile(28)};
    letter-spacing: ${vwMobile(1)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(16)};
      line-height: ${vwTablet(28)};
      letter-spacing: ${vwTablet(5)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(16)};
      line-height: ${vw(28)};
      letter-spacing: ${vw(5)};
    }
  }
`;

export default Hero;
