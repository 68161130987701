import React, { Component } from 'react';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';

import media from 'src/styles/media';
import { vw, vwMobile, vwTablet } from 'src/styles/utils';

import mapStyles from 'src/config/map/mapStyles';

class Map extends Component {
  static defaultProps = {
    center: {
      lat: 49.2856318,
      lng: -123.1151803,
    },
    zoom: 15,
  };

  render() {
    return (
      <Root>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyCENS_1z3MdVjSz80hzSzt4ZsEf13UwOB0' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          options={{ styles: mapStyles, disableDefaultUI: true }}
        >
          <Logo lat={49.285636} lng={-123.115177} />
        </GoogleMapReact>
      </Root>
    );
  }
}

export default Map;

const Logo = styled.div`
  width: ${vwMobile(48)};
  height: ${vwMobile(58)};
  background-image: url(${require('src/assets/images/icons/map-icon.svg')});
  background-size: 100%;
  transform: translate(-50%, -100%);
  @media (min-width: ${media.xsup}) {
    width: ${vwTablet(48)};
    height: ${vwTablet(58)};
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(48)};
    height: ${vw(58)};
  }
`;

const Root = styled.div`
  margin-top: ${vwMobile(20)};
  height: ${vwMobile(250)};
  width: '100%';
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
    0 1px 1px 0 rgba(0, 0, 0, 0.14);
  @media (min-width: ${media.xsup}) {
    height: ${vwTablet(366)};
    margin-top: ${vwTablet(20)};
  }
  @media (min-width: ${media.mdup}) {
    height: ${vw(366)};
    margin-top: 0;
  }
`;
