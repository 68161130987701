import imgArt from 'src/assets/images/menu/art.svg';

import imgCeramics from 'src/assets/images/menu/ceramics.svg';

import imgFurniture from 'src/assets/images/menu/furniture.svg';

import imgTea from 'src/assets/images/menu/tea.svg';

export default {
  gallery: ['/collection', '/about', '/events', '/visit-us'],
  collection: [
    { link: '/tea', image: imgTea },
    { link: '/art', image: imgArt },
    { link: '/ceramics', image: imgCeramics },
    { link: '/furniture', image: imgFurniture },
  ],
  bottom: ['/privacy', '/privacy'],
};
