import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

class VideoPageBase extends PureComponent {
  constructor(props) {
    super(props);
    this.$mainVideo = React.createRef();
  }

  render() {
    return (
      <Root>
        <video loop muted playsInline autoPlay ref={this.$mainVideo}>
          {this.props.browser.lessThan.desktop ? (
            // for mobile
            <source
              src="https://player.vimeo.com/external/322863700.sd.mp4?s=5552b8c1fe7816ea1abdbdeceda1399565b110b7&profile_id=164"
              type="video/mp4"
            />
          ) : (
            <source
              src="https://player.vimeo.com/external/322863700.hd.mp4?s=61a4b887a9ceaf6b54240404b2231c0f4410b546&profile_id=175"
              type="video/mp4"
            />
          )}
        </video>
        {this.props.children}
      </Root>
    );
  }
}

const Root = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${({ theme }) => theme.colorBackgroundBlack};
  z-index: -100;
  width: 100%;
  height: 100vh;
  video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    min-width: 100%;
    height: auto;
    min-height: 100%;
    transform: translate(-50%, -50%) translateZ(0);
  }
`;

const mapStateToProps = state => ({
  browser: state.browser,
});

export default connect(
  mapStateToProps,
  {}
)(VideoPageBase);
