import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from 'src/styles/media';

import LinkCard from './elements/LinkCard';
import Intl from 'src/components/Intl';

import { vw, vwMobile, vwTablet } from 'src/styles/utils';

const ExploreCollection = props => {
  return (
    <Intl>
      {(t, { locale }) => {
        return (
          <Root productLayout={props.productLayout} {...props}>
            {props.productLayout ? (
              <h2>{t.product.discover}</h2>
            ) : (
              <h2>{t.product.title}</h2>
            )}
            <LinkWrapper>
              <LinkCard
                backgroundImage={require('src/assets/images/explore-collection/chinese-tea.png')}
                label={t.product.menu[0]}
                linkTo={locale === 'en' ? '/tea' : '/zh/tea'}
                linkText={t.product.learnMore}
              />
              <LinkCard
                backgroundImage={require('src/assets/images/explore-collection/chinese-art.png')}
                label={t.product.menu[1]}
                linkTo={locale === 'en' ? '/art' : '/zh/art'}
                linkText={t.product.learnMore}
              />
              <LinkCard
                backgroundImage={require('src/assets/images/explore-collection/chinese-ceramics.png')}
                label={t.product.menu[2]}
                linkTo={locale === 'en' ? '/ceramics' : '/zh/ceramics'}
                linkText={t.product.learnMore}
              />
              <LinkCard
                backgroundImage={require('src/assets/images/explore-collection/chinese-furniture.png')}
                label={t.product.menu[3]}
                linkTo={locale === 'en' ? '/furniture' : '/zh/furniture'}
                linkText={t.product.learnMore}
              />
            </LinkWrapper>
          </Root>
        );
      }}
    </Intl>
  );
};

ExploreCollection.propTypes = {
  productLayout: PropTypes.bool,
};

const Root = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  padding-top: ${vwMobile(87)};
  margin-left: ${props => props.productLayout && vw(18)};
  margin-right: ${props => props.productLayout && vw(18)};
  margin-bottom: ${props => props.productLayout && 0};
  @media (min-width: ${media.xsup}) {
    padding-top: ${vwTablet(87)};
  }
  @media (min-width: ${media.mdup}) {
    padding-top: ${vw(76)};
    padding-bottom: ${vw(202)};
    margin: ${vw(30)} 0;
    background-size: cover;
    background: ${props =>
      props.noImage
        ? 'rgba(0, 0, 0, 0.7)'
        : `url(${require('src/assets/images/explore-collection/bg.jpg')})`};
  }
  h2 {
    margin: ${props => props.productLayout && '0 auto'};
    font-family: ${({ theme }) => theme.fontPrimary};
    font-size: ${props => (props.fullPage ? vwMobile(28) : vwMobile(16))};
    line-height: ${vwMobile(28)};
    letter-spacing: ${vwMobile(2)};
    margin-bottom: ${vwMobile(40)};
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    @media (min-width: ${media.xsup}) {
      width: ${props => props.productLayout && vwTablet(277)};
      font-size: ${props => (props.fullPage ? vwTablet(28) : vwTablet(16))};
      line-height: ${vwTablet(28)};
      letter-spacing: ${vwTablet(2)};
      margin-bottom: ${vwTablet(40)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${props => (props.fullPage ? vw(28) : vw(16))};
      line-height: ${vw(28)};
      letter-spacing: ${vw(5)};
      margin: 0 auto ${vw(77)};
      width: ${props => {
        if (props.productLayout) {
          return vw(277);
        } else if (props.fullPage) {
          return 'none';
        } else {
          return vw(200);
        }
      }};
    }
  }
`;

const LinkWrapper = styled.div`
  width: 71.43%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @media (min-width: ${media.mdup}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export default ExploreCollection;
