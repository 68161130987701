import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from 'src/styles/media';

import EventCard from './EventCard';

import { vw, vwMobile, vwTablet } from 'src/styles/utils';

const EventImageCard = ({
  imageSrc,
  day,
  month,
  topic,
  linkTo,
  linkText,
  noLink,
  time,
}) => {
  return (
    <Root>
      <img src={imageSrc} alt={topic} />
      <EventCard
        day={day}
        month={month}
        topic={topic}
        linkTo={linkTo}
        linkText={linkText}
        noLink={noLink}
        time={time}
      />
    </Root>
  );
};

EventImageCard.propTypes = {
  imageSrc: PropTypes.string,
  day: PropTypes.string,
  month: PropTypes.string,
  topic: PropTypes.string,
  linkTo: PropTypes.string,
  linkText: PropTypes.string,
  noLink: PropTypes.bool,
  time: PropTypes.string,
};

const Root = styled.div`
  width: 86.67%;
  margin: 0 auto;
  display: block;
  @media (min-width: ${media.xsup}) {
    width: 93.4%;
  }
  @media (min-width: ${media.mdup}) {
    width: auto;
  }
  img {
    width: 100%;
    height: auto;
    max-height: ${vwMobile(350)};
    object-fit: cover;
    object-position: top;
    margin-bottom: ${vwMobile(-5)};
    @media (min-width: ${media.xsup}) {
      max-height: ${vwTablet(550)};
    }
    @media (min-width: ${media.mdup}) {
      margin-bottom: ${vw(23)};
      max-height: ${vw(350)};
    }
  }
`;

export default EventImageCard;
