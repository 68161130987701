import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Intl from 'src/components/Intl';
import media from 'src/styles/media';
import { vw, vwTablet, vwMobile } from 'src/styles/utils';

import imgLogo from 'src/assets/images/product-page/sunzen-logo.svg';
import imgMenu from 'src/assets/images/product-page/menu-button.svg';
import imgClose from 'src/assets/images/product-page/close-button.svg';

const Header = ({ handleMenu, menuOpen }) => {
  return (
    <Intl>
      {(t, { locale }) => {
        return (
          <Root>
            <SunzenLogo
              to={locale === 'en' ? '/' : '/zh'}
              ishidden={(window.location.pathname === '/').toString()}
            >
              {' '}
              <Logo src={imgLogo} alt="Sunzen Logo" isHidden={menuOpen} />
            </SunzenLogo>

            <Menu menuOpen={menuOpen} onClick={() => handleMenu(!menuOpen)}>
              <p>{menuOpen ? 'Close' : 'Menu'}</p>
              <img src={menuOpen ? imgClose : imgMenu} alt="Menu Icon" />
            </Menu>

            <SwitchLang>
              <Link to={locale === 'en' ? '/zh' : '/'}>{t.switchLang}</Link>
            </SwitchLang>
          </Root>
        );
      }}
    </Intl>
  );
};

export default Header;

const Root = styled.div`
  padding-right: ${vwMobile(25)};
  padding-top: ${vwMobile(20)};
  padding-left: ${vwMobile(25)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  @media (min-width: ${media.xsup}) {
    padding-top: ${vwTablet(30)};
    padding-right: ${vwTablet(25)};
    padding-left: ${vwTablet(25)};
  }
  @media (min-width: ${media.mdup}) {
    padding-top: ${vw(30)};
    padding-right: ${vw(91)};
    padding-left: ${vw(77)};
  }
`;

const SunzenLogo = styled(Link)`
  opacity: ${props => (props.ishidden === 'true' ? '0' : '1')};
  cursor: ${props => (props.ishidden === 'true' ? 'auto' : 'pointer')};
  pointer-events: ${props => (props.ishidden === 'true' ? 'none' : 'all')};
`;

const Logo = styled.img`
  width: ${vwMobile(38)};
  opacity: ${props => (props.isHidden ? '0' : '1')};
  transition: opacity 0.2s;
  transition-delay: ${props => (props.isHidden ? '0s' : '1s')};
  @media (min-width: ${media.xsup}) {
    width: ${vw(96)};
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(96)};
  }
`;

const Menu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  img {
    width: ${vwMobile(38)};
    @media (min-width: ${media.xsup}) {
      width: ${vwTablet(38)};
    }
    @media (min-width: ${media.mdup}) {
      width: ${vw(38)};
    }
  }
  p {
    font-family: ${({ theme }) => theme.fontSecondary};
    font-size: ${vwMobile(14)};
    font-weight: 500;
    letter-spacing: ${vwMobile(0)};
    color: ${props =>
      props.menuOpen
        ? props.theme.colorBackgroundBlack
        : props.theme.colorWhite};
    text-transform: uppercase;
    margin-right: ${vwMobile(11)};
    display: none;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(14)};
      letter-spacing: ${vwTablet(2)};
      margin-right: ${vwTablet(11)};
      display: block;
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(14)};
      letter-spacing: ${vw(2)};
      margin-right: ${vw(11)};
    }
  }
`;

const SwitchLang = styled.div`
  position: absolute;
  right: ${vwMobile(80)};
  top: 50%;
  top: ${`calc(50% + ${vwMobile(10)})`};
  transform: translateY(-50%);
  @media (min-width: ${media.xsup}) {
    top: ${`calc(50% + ${vwTablet(13)})`};
    right: ${vwTablet(150)};
  }
  @media (min-width: ${media.mdup}) {
    top: ${`calc(50% + ${vw(15)})`};
    right: ${vw(250)};
  }
  a {
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.fontSecondary};
    font-size: ${vwMobile(14)};
    letter-spacing: ${vwMobile(0)};
    line-height: 1;
    color: white;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(14)};
      letter-spacing: ${vwTablet(2)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(14)};
      letter-spacing: ${vw(2)};
    }
  }
`;
