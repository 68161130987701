import React from 'react';
import styled from 'styled-components';
import media from 'src/styles/media';

import Intl from 'src/components/Intl';
import BackgroundBlock from 'components/videoPage/BackgroundBlock';
import Map from './Map';

import { vw, vwMobile, vwTablet } from 'src/styles/utils';

const LocationCard = () => {
  return (
    <Intl>
      {(t, { locale }) => {
        return (
          <Root
            backgroundColor="white"
            backgroundImage={require('src/assets/images/home/bg-white.png')}
          >
            <Column>
              <Container>
                <ContentBlockWrapper>
                  <h1>{t.visit.location.title}</h1>
                  <h2>{t.visit.location.address}</h2>

                  <h3>{t.visit.location.openingHours}</h3>
                  <p>{t.visit.location.hours}</p>
                  <p className="hours">{t.visit.location.hours2}</p>
                  <h3>{t.visit.location.contact}</h3>
                  <p>
                    {t.visit.location.phone}:{' '}
                    <a href="tel:6022656677">602 265 6677</a>
                  </p>

                  <p>
                    {t.visit.location.email}:{' '}
                    <a
                      href="mailto:info@sunzen.ca"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      info@sunzen.ca
                    </a>
                  </p>
                </ContentBlockWrapper>
              </Container>
            </Column>

            <Column>
              {/* <ImageBlock
                imageSrc={require('src/assets/images/about/story.jpg')}
                imageLabel={t.visit.location.imageCaption}
              /> */}

              <Map />
              <MapLabel>{t.visit.location.imageCaption}</MapLabel>
            </Column>
          </Root>
        );
      }}
    </Intl>
  );
};

export default LocationCard;

const Root = styled(BackgroundBlock)`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: ${vwMobile(30)} ${vwMobile(25)};
  @media (min-width: ${media.xsup}) {
    padding: ${vwTablet(30)} ${vwTablet(25)};
  }
  @media (min-width: ${media.mdup}) {
    flex-direction: row;
    padding-top: ${vw(113)};
    padding-bottom: ${vw(99)};
    padding-right: ${vw(98)};
  }
`;

const Column = styled.div`
  @media (min-width: ${media.mdup}) {
    width: 50%;
  }
  img {
    width: 100%;
    height: 100%;
  }
`;

const ContentBlockWrapper = styled.div`
  color: ${({ theme }) => theme.colorNavy};
  h1 {
    font-family: ${({ theme }) => theme.fontPrimary};
    font-size: ${vwMobile(23)};
    letter-spacing: ${vwMobile(1.64)};
    font-weight: normal;
    text-transform: uppercase;
    position: relative;
    margin-bottom: ${vwMobile(20)};
    margin-top: ${vw(24)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(23)};
      letter-spacing: ${vwTablet(1.64)};
      margin-bottom: ${vwTablet(20)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(28)};
      letter-spacing: ${vw(4)};
      margin-bottom: ${vw(40)};
    }
    &::before {
      content: '';
      position: absolute;
      transform: translate3d(0, -50%, 0);
      top: 50%;
      left: ${vwMobile(-15)};
      width: ${vwMobile(7)};
      height: ${vwMobile(7)};
      background-color: ${({ theme }) => theme.colorRed};
      @media (min-width: ${media.xsup}) {
        width: ${vw(10)};
        height: ${vw(10)};
        left: ${vw(-20)};
      }
    }
  }
  h2 {
    font-family: ${({ theme }) => theme.fontSecondaryLight};
    font-size: ${vwMobile(16)};
    font-weight: normal;
    line-height: 1.56;
    letter-spacing: ${vwMobile(2)};
    text-transform: uppercase;
    margin-bottom: ${vwMobile(20)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(16)};
      letter-spacing: ${vwTablet(2)};
      margin-bottom: ${vwTablet(20)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(16)};
      letter-spacing: ${vw(2)};
      margin-bottom: ${vw(35.5)};
    }
  }

  h3 {
    font-family: ${({ theme }) => theme.fontPrimary};
    font-size: ${vwMobile(18)};
    font-weight: normal;
    line-height: 1.39;
    letter-spacing: ${vwMobile(1.8)};
    margin-bottom: ${vwMobile(7.5)};
    text-transform: uppercase;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(18)};
      letter-spacing: ${vwTablet(1.8)};
      margin-bottom: ${vwTablet(7.5)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(18)};
      letter-spacing: ${vw(1.8)};
      margin-bottom: ${vw(7.5)};
    }
  }

  p {
    font-family: ${({ theme }) => theme.fontPrimary};
    font-size: ${vwMobile(17)};
    font-weight: normal;
    line-height: 1.41;
    margin-bottom: ${vwMobile(5)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(17)};
      margin-bottom: ${vwTablet(5)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(17)};
      margin-bottom: ${vw(5)};
      &.hours {
        margin-bottom: ${vwMobile(20)};
        @media (min-width: ${media.xsup}) {
          margin-bottom: ${vwTablet(20)};
        }
        @media (min-width: ${media.mdup}) {
          margin-bottom: ${vw(35.5)};
        }
      }
    }
  }

  a {
    color: ${({ theme }) => theme.colorNavy};
  }
`;

const Container = styled.div`
  margin: 0 auto;
  color: ${({ theme }) => theme.colorNavy};
  text-align: left;
  @media (min-width: ${media.mdup}) {
    width: 57.14%;
  }
`;

const MapLabel = styled.p`
  font-style: italic;
  letter-spacing: ${vw(0.5)};
  margin-top: ${vw(15)};
`;
