import React from 'react';
import styled from 'styled-components';

import media from 'src/styles/media';
import { vw, vwTablet } from 'src/styles/utils';
import Intl from 'src/components/Intl';

import TextContainer from './elements/TextContainer';
import ProductCard from './elements/ProductCard';

const FeaturedProducts = ({
  text,
  activeIndex,
  activeItemIndex,
  assets,
  handleItem,
  handleSelection,
  handleToggleCard,
  cardOpen,
  effect,
  data,
}) => {
  return (
    <Intl>
      {(t, { locale }) => {
        return (
          <Root>
            <TextContainer text={text} categories={data} />
            <ProductCard
              cardOpen={cardOpen}
              activeIndex={activeIndex}
              activeItemIndex={activeItemIndex}
              assets={assets}
              text={text}
              handleItem={handleItem}
              handleSelection={handleSelection}
              handleToggleCard={handleToggleCard}
              effect={effect}
              products={data}
            />
          </Root>
        );
      }}
    </Intl>
  );
};

export default FeaturedProducts;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${vw(30)};
  overflow: hidden;
  @media (min-width: ${media.xsup}) {
    margin-bottom: ${vwTablet(30)};
  }
  @media (min-width: ${media.mdup}) {
    margin-left: ${vw(18)};
    margin-right: ${vw(18)};
    flex-direction: row;
    margin-bottom: ${vw(30)};
  }
`;
