import React, { Component } from 'react';

import Intl from 'src/components/Intl';

import ProductPage from 'src/components/ProductPage/ProductPage';

import furniture from 'src/data/ProductPage/furniture';

class Furniture extends Component {
  render() {
    return (
      <Intl>
        {(t, { locale }) => {
          return (
            <ProductPage
              text={t.furniture}
              assets={furniture}
              name="furniture"
            />
          );
        }}
      </Intl>
    );
  }
}

export default Furniture;
