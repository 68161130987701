import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import media from 'src/styles/media';
import { vw, vwTablet, vwMobile } from 'src/styles/utils';
import Intl from 'src/components/Intl';

const FeaturedProducts = ({ text, categories }) => {
  return (
    <Intl>
      {(t, { locale }) => {
        return (
          <Root>
            {text.title}

            <ItemsList>
              {categories.length !== 0 &&
                categories.map((item, index) => (
                  <h3 key={index}>{item.title}</h3>
                ))}
            </ItemsList>

            <p>{text.description}</p>
            <h2>{t.product.featured.discover}</h2>
            <Link to="/visit-us">
              <span>{t.product.featured.visit}</span>
            </Link>

            <Background
              backgroundImage={require('src/assets/images/product-page/background.png')}
            />
          </Root>
        );
      }}
    </Intl>
  );
};

export default FeaturedProducts;

const Root = styled.div`
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.07);
  background-color: ${({ theme }) => theme.colorWhite};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: ${vwMobile(54)} ${vwMobile(25)};
  width: 100%;
  z-index: 10;
  position: relative;
  @media (min-width: ${media.xsup}) {
    padding: ${vwTablet(54)} ${vwTablet(25)};
  }
  @media (min-width: ${media.mdup}) {
    width: ${vw(702)};
    height: ${vw(649)};
    padding-left: ${vw(132)};
    padding-top: ${vw(136)};
    padding-bottom: ${vw(139)};
  }

  h1 {
    text-transform: uppercase;
    font-size: ${vwMobile(23)};
    font-family: ${({ theme }) => theme.fontPrimary};
    letter-spacing: ${vwMobile(1.64)};
    font-weight: normal;
    color: ${({ theme }) => theme.colorNavy};
    margin-bottom: ${vwMobile(13)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(28)};
      letter-spacing: ${vwTablet(4)};
      margin-bottom: ${vwTablet(13)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(28)};
      letter-spacing: ${vw(4)};
      margin-bottom: ${vw(13)};
    }
  }
  p {
    margin-top: ${vwMobile(10)};
    margin-bottom: ${vwMobile(30)};
    font-size: ${vwMobile(17)};
    font-family: ${({ theme }) => theme.fontPrimary};
    font-weight: normal;
    line-height: 1.41;
    color: ${({ theme }) => theme.colorNavy};
    @media (min-width: ${media.xsup}) {
      margin-top: 0;
      margin-bottom: ${vwTablet(30)};
      font-size: ${vwTablet(17)};
    }
    @media (min-width: ${media.mdup}) {
      margin-bottom: ${vw(30)};
      width: ${vw(462)};
      font-size: ${vw(17)};
    }
  }
  h2 {
    font-family: ${({ theme }) => theme.fontSecondary};
    font-size: ${vwMobile(14)};
    font-weight: normal;
    line-height: 1.56;
    letter-spacing: ${vwMobile(2)};
    color: ${({ theme }) => theme.colorNavy};
    text-transform: uppercase;
    margin-bottom: ${vwMobile(30)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(16)};
      letter-spacing: ${vwTablet(2)};
      width: ${vwTablet(350)};
      margin-bottom: ${vwTablet(30)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(16)};
      letter-spacing: ${vw(2)};
      width: ${vw(350)};
      margin-bottom: ${vw(30)};
    }
  }
  span {
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.fontSecondary};
    font-size: ${vwMobile(11)};
    font-weight: 500;
    letter-spacing: ${vwMobile(1.6)};
    color: ${({ theme }) => theme.colorNavy};
    border-bottom: 1px solid ${({ theme }) => theme.colorNavy};
    padding-bottom: ${vwMobile(6)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(11)};
      letter-spacing: ${vwTablet(1.6)};
      padding-bottom: ${vwTablet(6)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(11)};
      letter-spacing: ${vw(1.6)};
      padding-bottom: ${vw(6)};
    }
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: ${props => `url(${props.backgroundImage})`};
  background-size: cover;
  background-position: right;
  opacity: 0.24;
  pointer-events: none;
`;

const ItemsList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (min-width: ${media.xsup}) {
    flex-wrap: nowrap;
  }
  h3 {
    font-family: ${({ theme }) => theme.fontSecondary};
    font-size: ${vwMobile(11)};
    font-weight: normal;
    letter-spacing: ${vwMobile(2)};
    line-height: 1;
    color: ${({ theme }) => theme.colorNavy};
    text-transform: uppercase;
    display: inline;
    position: relative;
    margin-right: ${vwMobile(21)};
    margin-bottom: ${vwMobile(10)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(11)};
      letter-spacing: ${vwTablet(2)};
      margin-right: ${vwTablet(21)};
      margin-bottom: ${vwTablet(40)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(11)};
      letter-spacing: ${vw(2)};
      margin-right: ${vw(21)};
      margin-bottom: ${vw(40)};
    }
    &::after {
      content: '';
      width: ${vwMobile(5)};
      height: ${vwMobile(5)};
      background: ${({ theme }) => theme.colorRed};
      position: absolute;
      top: ${vwMobile(3)};
      right: ${vwMobile(-12)};
      @media (min-width: ${media.xsup}) {
        width: ${vwTablet(5)};
        height: ${vwTablet(5)};
        top: ${vwTablet(3)};
        right: ${vwTablet(-12)};
      }
      @media (min-width: ${media.mdup}) {
        width: ${vw(5)};
        height: ${vw(5)};
        top: ${vw(3)};
        right: ${vw(-12)};
      }
    }
    &:last-child::after {
      background: none;
    }
  }
`;
