import React from 'react';
import styled from 'styled-components';

import Intl from 'src/components/Intl';
import Hero from 'components/videoPage/Hero';
import Container from 'components/Container';
import ExploreCollection from 'components/ExploreCollection/ExploreCollection';
import LocationCard from './sections/LocationCard';

const VisitUs = props => {
  return (
    <Intl>
      {(t, { locale }) => {
        return (
          <Root>
            <Hero title={t.visit.hero.title} content={t.visit.hero.content} />

            <Container>
              <LocationCard />
              <ExploreCollection />
            </Container>
          </Root>
        );
      }}
    </Intl>
  );
};

const Root = styled.main``;

export default VisitUs;
