import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Intl from 'src/components/Intl';
import media from 'src/styles/media';
import { vw, vwTablet, vwMobile } from 'src/styles/utils';

import menu from 'src/data/menu/menu';

const Gallery = ({ handleMenu, menuOpen }) => {
  return (
    <Intl>
      {(t, { locale }) => {
        return (
          <Root menuOpen={menuOpen}>
            <Link
              to={locale === 'en' ? '/' : '/zh'}
              className="home"
              onClick={() => handleMenu(false)}
            >
              {t.menu.gallery.title}
            </Link>

            {t.menu.gallery.links.map((link, index) => (
              <Link
                to={
                  locale === 'en'
                    ? menu.gallery[index]
                    : `/zh${menu.gallery[index]}`
                }
                key={index}
                onClick={() => handleMenu(false)}
              >
                {link}
              </Link>
            ))}
          </Root>
        );
      }}
    </Intl>
  );
};

export default Gallery;

const Root = styled.div`
  padding-top: ${vw(141)};
  padding-bottom: ${vw(64)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colorWhite};
  z-index: 100;
  position: relative;
  background: white;
  width: 100%;
  opacity: ${props => (props.menuOpen ? 1 : 0)};
  transform: ${props =>
    props.menuOpen ? 'translate3d(0,0,0)' : 'translate3d(0, 3rem, 0)'};
  /* transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275); */
  transition: all 0.5s;
  transition-delay: ${props => (props.menuOpen ? '0.3s' : '0.3s')};
  a.home {
    font-family: ${({ theme }) => theme.fontPrimary};
    font-size: ${vwMobile(18)};
    font-weight: normal;
    line-height: 1.39;
    letter-spacing: ${vwMobile(1.8)};
    opacity: 1;
    color: ${({ theme }) => theme.colorNavy};
    margin-bottom: ${vwMobile(22)};
    @media (min-width: ${media.xsup}) {
      margin-bottom: ${vwTablet(22)};
      font-size: ${vwTablet(18)};
      letter-spacing: ${vwTablet(1.8)};
    }
    @media (min-width: ${media.mdup}) {
      margin-bottom: ${vw(22)};
      font-size: ${vw(18)};
      letter-spacing: ${vw(1.8)};
    }
  }
  a {
    opacity: 0.4;
    margin-bottom: ${vw(27)};
    @media (min-width: ${media.xsup}) and (max-height: 450px) {
      margin-bottom: 5px;
    }
  }
`;
