import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import media from 'src/styles/media';

import { vw, vwMobile, vwTablet } from 'src/styles/utils';

const ContentBlock = ({
  label,
  sublabel,
  content,
  linkText,
  linkTo,
  color,
}) => {
  return (
    <Root color={color}>
      <h1>{label}</h1>
      <h2>{sublabel}</h2>
      <p>{content}</p>
      {linkTo && <Link to={linkTo}>{linkText}</Link>}
    </Root>
  );
};

ContentBlock.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  sublabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  content: PropTypes.node,
  linkText: PropTypes.string,
  linkTo: PropTypes.string,
  color: PropTypes.string,
};

const Root = styled.div`
  color: ${props => props.color};
  padding: 0 ${vwMobile(25)} ${vwMobile(54)};
  @media (min-width: ${media.xsup}) {
    padding: 0 ${vwTablet(25)} ${vwTablet(54)};
  }
  @media (min-width: ${media.mdup}) {
    padding: 0;
  }
  h1 {
    font-family: ${({ theme }) => theme.fontPrimary};
    font-size: ${vwMobile(23)};
    letter-spacing: ${vwMobile(1.64)};
    margin-bottom: ${vwMobile(20)};
    text-transform: uppercase;
    position: relative;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(23)};
      letter-spacing: ${vwTablet(1.64)};
      margin-bottom: ${vwTablet(20)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(28)};
      letter-spacing: ${vw(4)};
      margin-bottom: ${vw(40)};
    }
    &::before {
      content: '';
      position: absolute;
      transform: translate3d(0, -50%, 0);
      top: 50%;
      left: ${vwMobile(-15)};
      width: ${vwMobile(7)};
      height: ${vwMobile(7)};
      background-color: ${({ theme }) => theme.colorRed};
      @media (min-width: ${media.xsup}) {
        width: ${vw(10)};
        height: ${vw(10)};
        left: ${vw(-20)};
      }
    }
  }
  h2 {
    font-family: ${({ theme }) => theme.fontSecondaryLight};
    font-size: ${vwMobile(16)};
    line-height: ${vwMobile(25)};
    letter-spacing: ${vwMobile(2)};
    margin-bottom: ${vwMobile(10)};
    text-transform: uppercase;
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(16)};
      line-height: ${vwTablet(25)};
      letter-spacing: ${vwTablet(2)};
      margin-bottom: ${vwTablet(20)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(16)};
      line-height: ${vw(25)};
      letter-spacing: ${vw(2)};
      margin-bottom: ${vw(20)};
    }
  }
  p {
    font-family: ${({ theme }) => theme.fontPrimary};
    font-size: ${vwMobile(17)};
    line-height: ${vwMobile(22)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(17)};
      line-height: ${vwTablet(24)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(17)};
      line-height: ${vw(24)};
    }
  }
  a {
    font-family: ${({ theme }) => theme.fontSecondary};
    font-size: ${vwMobile(11)};
    font-weight: 500;
    letter-spacing: ${vwMobile(1.5)};
    text-transform: uppercase;
    color: ${props => props.color};
    display: block;
    margin-top: ${vwMobile(54)};
    @media (min-width: ${media.xsup}) {
      font-size: ${vwTablet(11)};
      letter-spacing: ${vwTablet(1.5)};
      margin-top: ${vwTablet(40)};
    }
    @media (min-width: ${media.mdup}) {
      font-size: ${vw(11)};
      letter-spacing: ${vw(1.5)};
      margin-top: ${vw(40)};
    }
  }
`;

export default ContentBlock;
