import React, { Component } from 'react';
import styled from 'styled-components';

class NotFound extends Component {
  render() {
    return (
      <Root>
        <h1>404!</h1>
      </Root>
    );
  }
}

export default NotFound;

const Root = styled.div`
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;
