import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from 'src/styles/media';
import { vwMobile, vwTablet } from 'src/styles/utils';

import BackgroundBlock from 'components/videoPage/BackgroundBlock';
import ContentBlock from 'components/videoPage/ContentBlock';

const Community = ({ title, subTitle, content }) => {
  return (
    <Root
      backgroundColor="black"
      backgroundImage={require('src/assets/images/home/bg-black.png')}
      mobileBackgroundImage={require('src/assets/images/home/marble-bg-black-mobile.png')}
    >
      <Row>
        <Wrapper>
          <ColumnThree>
            <img
              src={require('src/assets/images/about/community-left.jpg')}
              alt="community left"
            />
          </ColumnThree>
          <ColumnThree>
            <img
              src={require('src/assets/images/about/community-middle.jpg')}
              alt="community middle"
            />
          </ColumnThree>
        </Wrapper>
        <ColumnFive>
          <ContentBlock
            label={title}
            sublabel={subTitle}
            content={content}
            color="white"
          />
        </ColumnFive>
      </Row>
    </Root>
  );
};

Community.propTypes = {
  title: PropTypes.object,
  subTitle: PropTypes.object,
  content: PropTypes.string,
};

const Root = styled(BackgroundBlock)``;

const Row = styled.div`
  /* width: 85.71%; */
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (min-width: ${media.mdup}) {
    flex-wrap: nowrap;
    width: 85.71%;
  }
  img {
    width: 100%;
    height: auto;
  }
`;

const Wrapper = styled.div`
  padding: 0 ${vwMobile(25)};
  display: flex;
  justify-content: space-between;
  margin-bottom: ${vwMobile(25)};
  @media (min-width: ${media.xsup}) {
    padding: 0 ${vwTablet(25)};
    margin-bottom: ${vwTablet(25)};
  }
  @media (min-width: ${media.mdup}) {
    padding: 0;
    margin-bottom: 0;
    width: 45.8%;
  }
`;

const ColumnThree = styled.div`
  width: 45%;
  @media (min-width: ${media.mdup}) {
    width: 45%;
  }
`;

const ColumnFive = styled.div`
  text-align: left;
  @media (min-width: ${media.mdup}) {
    width: 39.57%;
    margin-left: 8.33%;
  }
`;

export default Community;
