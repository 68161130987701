import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { hot } from 'react-hot-loader';

import { prerenderStyles } from 'src/plugins/prerender';
import routes from 'src/routes';

import Header from 'components/Header';
import VideoBase from 'components/videoPage/VideoBase';
import Footer from 'components/Footer';
import Menu from 'components/Menu/Menu';

class App extends Component {
  state = {
    menuOpen: false,
  };
  componentDidMount() {
    prerenderStyles();
    new window.GambitSmoothScroll({
      amount: 150,
      speed: 1800,
    });
  }

  generateRoutes() {
    return routes.map((route, index) => {
      console.log("route", route)
      const { path, component } = route;
      const Component = require(`src/${component}`).default;
      return (
        <Route
          exact
          path={path}
          component={Component}
          key={index}
          handleMenu={this.handleMenu}
        />
      );
    });
  }

  handleMenu = bool => {
    this.setState({ menuOpen: bool });
  };

  render() {
    return (
      <Route
        render={({ location }) => (
          <AppWrapper menuOpen={this.state.menuOpen}>
            <Header
              handleMenu={this.handleMenu}
              menuOpen={this.state.menuOpen}
            />
            <Menu menuOpen={this.state.menuOpen} handleMenu={this.handleMenu} />
            <VideoBase />
            <TransitionGroup>
              <CSSTransition key={location.key} timeout={500} classNames="fade">
                <Switch location={location}>{this.generateRoutes()}</Switch>
              </CSSTransition>
            </TransitionGroup>
            <Footer />
          </AppWrapper>
        )}
      />
    );
  }
}

const AppWrapper = styled.div`
  position: relative;
  position: ${props => props.menuOpen && 'fixed'};
  max-width: 100vw;
  overflow-x: hidden;
  .fade-enter {
    opacity: 0;
  }
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: all 0.5s;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit.fade-exit-active {
    opacity: 0;
    transition: all 0.5s;
  }
`;

export default hot(module)(App);
